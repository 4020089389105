/**
 * NOTE: there are no members here because that is driven by groups.
 * See arc-api.ts
 */
export type ArcCohort = {
  id: string;
  title: string;
  subtitle: string;

  /** Needs to be defined for the Event Cell to work */
  backgroundImageURL: string;

  /** When does this cohort start showing up in the header + prompt? */
  startShowDate: Date;
  /** When does this cohort stop showing up in the header + prompt? */
  endShowDate: Date;

  /** When does this cohort begin?
   *
   * Affects which `weeks[i]` opens by default.
   * Typically we set this to Sunday 0:00 GMT of the first week of arc, so the curriculum week ticks over on Sunday */
  startDate: Date;
  /** Last date of class for this Arc cohort.
   *
   * Affects display of arc cohort hub after end date and graduates attendees to Arc Alumni group.
   * Typically set to later in the day (e.g. 23:59) so we don't do post-arc behavior on the last day. */
  endDate: Date;

  weeks: ArcWeek[];
  communityResources: ArcWeekArticle[];
  builders: ArcBuilder[];
  buildersBlurb?: string;
};

export type ArcWeek = {
  cohort: string;
  number: number;
  topic: string;
  summary: string;
  articles: ArcWeekArticle[];
  instructors: ArcInstructor[];

  airtableId: string;
};

export type ArcWeekArticle = {
  storyIdOrLink: string;
  name: string;
  shortDescription?: string;
  featureType?: string;
  featureImage?: string;
  featureBackgroundColor?: string;
  cta?: string;

  airtableId: string;
  weekIds: string[];
  visibleToDomain?: string;
};

export type ArcInstructor = {
  name: string;
  bio?: string;
  profileId?: string;
  website?: string;
  image?: string;
  twitter?: string;
  linkedin?: string;
  instagram?: string;
  youtube?: string;

  airtableId: string;
  weekIds: string[];
};

export type ArcBuilder = {
  name: string;
  bio?: string;
  profileId?: string;
  website?: string;
  image?: string;
  twitter?: string;
  linkedin?: string;
  instagram?: string;
  youtube?: string;

  airtableId: string;
};

/**
 * Data in the listing file
 */
export type ArcCohortInListingFile = Pick<
  ArcCohort,
  | "id"
  | "title"
  | "startShowDate"
  | "endShowDate"
  | "startDate"
  | "endDate"
  | "backgroundImageURL"
> & {
  memberIds: string[];
};

const ARC_DATA_VERSION = 3;
export const ARC_HOSTING_BASE = `resource/data/v${ARC_DATA_VERSION}`;
