import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class BuilderRequest {
  id: string;
  founderId: string;
  builderName: string;
  body: string;
  createdAt: StorageTimestamp;
  builderLinkedin?: string;
  builderId?: string;
  topic?: string;
  smsNoteId?: string;
  type?: "builder" | "agency";

  constructor(
    id: string,
    founderId: string,
    builderName: string,
    body: string,
    createdAt: StorageTimestamp,
    builderLinkedin?: string,
    builderId?: string,
    topic?: string,
    smsNoteId?: string,
    type?: "builder" | "agency",
  ) {
    this.id = id;
    this.founderId = founderId;
    this.builderName = builderName;
    this.body = body;
    this.createdAt = createdAt;
    this.builderLinkedin = builderLinkedin;
    this.builderId = builderId;
    this.topic = topic;
    this.smsNoteId = smsNoteId;
    this.type = type;
  }
}

export const builderRequestDataConverter: FirestoreDataConverter<BuilderRequest> =
  {
    toFirestoreModel: (model: BuilderRequest) => {
      return {
        id: model.id,
        founder_id: model.founderId,
        builder_name: model.builderName,
        body: model.body,
        created_at: model.createdAt,
        builder_linkedin: model.builderLinkedin,
        builder_id: model.builderId,
        topic: model.topic,
        sms_note_id: model.smsNoteId,
        type: model.type,
      };
    },
    fromFirestoreModel: (data): BuilderRequest => {
      return new BuilderRequest(
        data.id,
        data.founder_id,
        data.builder_name,
        data.body,
        data.created_at,
        data.builder_linkedin,
        data.builder_id,
        data.topic,
        data.sms_note_id,
        data.type,
      );
    },
  };
