import { Event } from "./event";
import { LibraryEntry } from "./library-entry";
import { Post } from "./post";
import { User } from "./user";

export type EmailDigestUserTemplateValues = {
  deeplink: string;
  id: string;
  profileImageURL: string;
  name: string;
  about: string;
  joined: string;
};

export type EmailDigestPostTemplateValues = {
  deeplink: string;
  id: string;
  createdBy: Omit<EmailDigestUserTemplateValues, "deeplink" | "joined" | "id">;
  title: string;
  body: string;
  see_more: string;
};

export type EmailDigestResourceTemplateValues = {
  deeplink: string;
  id: string;
  title: string;
  body: string;
  short_description?: string;
};

export type EmailDigestEventTemplateValues = {
  deeplink: string;
  id: string;
  title: string;
  body: string;
};

export type EmailDigestGroupTemplateValues = {
  deeplink: string;
  id: string;
  coverImgURL: string;
  name: string;
  numMembers: string;
  created: string;
};

/**
 * Values to send to Postmark. When scheduling, these are stored in firebase
 * verbatim so the email is sent in a standard format.
 */
export type EmailDigestTemplateValues = {
  subject: string;
  preview?: string;

  formattedHeading: string; // HTML
  heading: string; // as entered in input, so we can load the data back
  lede: string;

  posts?: EmailDigestPostTemplateValues[];

  poll: undefined; // Not supported

  events?: EmailDigestEventTemplateValues[];

  resourcesHeading?: string;
  resources?: EmailDigestResourceTemplateValues[];
  newResources?: EmailDigestResourceTemplateValues[];

  users?: EmailDigestUserTemplateValues[];

  groups?: EmailDigestGroupTemplateValues[];

  date: string;
  year: string;
};
/**
 * V2 =========================================================================
 */

/**
 * Values to send to Postmark. When scheduling, these are stored in firebase
 * verbatim so the email is sent in a standard format.
 */
export type V2BaseTemplateValues = {
  subject: string;
  /** Text in the inbox, after the subject https://documentation.mjml.io/#mj-preview */
  preview?: string;
  /** Hides links to Library/Events/Messages (you cannot have hideMenu=false with hideNav=true) */
  hideMenu?: boolean;
  /** Hides entire top navbar with logo */
  hideNav?: boolean;
};

export type V2EmailDigestTemplateValues = V2BaseTemplateValues & {
  digestHeadline: {
    title: string;
    subtitle: string;
  };
  tableOfContents?: EmailDigestTableOfContentsEntry[];
  sections: EmailDigestSection[];
};

export type EmailDigestTableOfContentsEntry = {
  text: string; /// displayed
  anchorName: string; /// a.name
};

export type EmailDigestSection = {
  header: string;
  primaryCTA?: {
    text: string;
    url: string;
  };

  /** Used by admin tool & backend for validation, not used for template rendering */
  contentsEntityType?: EmailDigestEntityType;
} & (
  | {
      contentTypeCustom: true;
      contents: EmailContentTypeArticle[];
    }
  | {
      contentTypeArticle: true;
      contents: EmailContentTypeArticle[];
    }
  | {
      contentTypePost: true;
      contents: EmailContentTypePost[];
    }
  | {
      contentTypeProfile: true;
      contents: EmailContentTypeProfile[];
    }
);

/**
 * Entity types possible in the email digest.
 *
 * Backend needs to validate & filter out data the recipient does not have access to.
 * So we keep the entity type and the id of the linked entity.
 * The contents of a given section are always homogeonous (all posts, all events, etc.)
 * The frontend template doesn't need to know this info, but may use it.
 * ```
 *   event        = EmailContentTypeArticle
 *   libraryEntry = EmailContentTypeArticle
 *   post         = EmailContentTypePost
 *   custom       = EmailContentTypeArticle
 *   user         = EmailContentTypeProfile
 * ```
 */
export enum EmailDigestEntityType {
  libraryEntry = "Library Entry",
  event = "Event",
  post = "Post",
  custom = "Custom",
  user = "User",
}

export type EmailContentTypeProfile = {
  profileImageURL: string;
  name: string;
  about: string;
  url: string;

  /** ID of the underlying entity. Used by cloud function and Admin tool, not used for template rendering */
  id: string;
  /** Only defined in the admin tool, not used for template rendering nor cloud function */
  entity?: User;
};

type EmailContentTypeArticleBase = {
  /** This will be escaped.
   *
   * Set body or htmlBody but not both – or else you may get double text */
  body: string;
  /** This will NOT be escaped
   *
   * Set body or htmlBody but not both – or else you may get double text
   */
  htmlBody?: string;
  url: string;
  /** ID of the underlying entity. Used by cloud function and Admin tool, not used for template rendering */
  id: string;
};

export type EmailContentTypeArticle = EmailContentTypeArticleBase & {
  title: string;
  image?: string;

  /** Only defined in the admin tool, not used for template rendering nor cloud function */
  tocAnchorName?: string;

  /** Only defined in the admin tool, not used for template rendering nor cloud function */
  entity?: LibraryEntry | Event;
};

export type EmailContentTypePost = EmailContentTypeArticleBase & {
  title: string;
  image?: string;
  createdBy: EmailContentTypeProfile;
  /** Only defined in the admin tool, not used for template rendering nor cloud function */
  entity?: Post;
};

export type EmailContentTypeMessage = EmailContentTypeArticleBase & {
  createdBy: EmailContentTypeProfile;
  activity?: string; // Used for group messages e.g. "Sanchali, Yong, and 3 others"
};

export type EmailContentTypePostActivity = EmailContentTypeArticleBase & {
  createdBy: EmailContentTypeProfile;
  activity: string; // e.g. "Jaleh Rezaei mentioned you in a comment:"
};

export type EmailContentTypeMemo = EmailContentTypeArticleBase & {
  title: string;
  createdBy: EmailContentTypeProfile;
  activity: string; // e.g. "Ben Rubin submitted a new memo"
  filled?: boolean;
  notice?: string;
};

export type UnreadActivityTemplateValues = V2BaseTemplateValues & {
  digestHeadline: false;
  sections: UnreadActivitySection[];
};

export type UnreadActivitySection = {
  header: string;
  subHeader: string;
  primaryCTA?: {
    text: string;
    url: string;
  };
  postScript?: {
    text: string;
  };
} & (
  | {
      contentTypeMessage: true;
      contents: EmailContentTypeMessage[];
    }
  | {
      contentTypePostActivity: true;
      contents: EmailContentTypePostActivity[];
    }
  | {
      contentTypeMemo: true;
      contents: EmailContentTypeMemo[];
    }
);
