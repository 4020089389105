import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

// Will store as a new collection, readonly
// https://airtable.com/appOC1frR7JSQW3jp/tblOdiXozPjgsAN55/viwLvjPtW3y7LpTEO?blocks=hide
/** 
  `/services_menu/0`
  // future could have audience, or some other means to have different menus

  { 
     service: [
       
     ] 
  }
*/

export const ALL_FUNCTIONS_TAG = "All Functions";
export class ServiceMenu {
  id: string;

  /** Ordered steps for the founder journey */
  founderJourneyPhase: ServiceMenuFounderJourneyPhase[];

  service: ServiceMenuItem[];

  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  constructor(
    id: string,
    founderJourneyPhase: ServiceMenuFounderJourneyPhase[],
    service: ServiceMenuItem[],
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
  ) {
    this.id = id;
    this.founderJourneyPhase = founderJourneyPhase;
    this.service = service;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export class ServiceMenuFounderJourneyPhase {
  id: string;
  name: string;
  backgroundImageUrl?: string;
  constructor(id: string, name: string, backgroundImageUrl?: string) {
    this.id = id;
    this.name = name;
    this.backgroundImageUrl = backgroundImageUrl;
  }
}

export class ServiceMenuItem {
  id: string;

  name: string;
  oneLiner?: string;
  imageUrl?: string;

  // If we want to message the poc, pull from airtable at runtime. Avoids exposing emails in the client.
  // pointOfContactEmail?: string;

  founderJourneyPhase: string[];

  serviceType?: string;
  serviceTypeIcon?: string;
  // // Unused?
  // companyStage: string[];
  // // Unused?
  // sector: string[];
  companyFunction?: string[];
  companyArr?: string[];

  ctaText?: string;

  /** If there's valid data in ctaDMUserId and ctaDMDraft, this field will be the Ampersand URL for newChat, based on those fields  */
  ctaUrl?: string;
  ctaDMUserId?: string;
  ctaDMDraft?: string;

  smsTag?: string[];

  edgeStoryTemplate?: string;
  smsNoteTemplate?: string;

  startShowDate?: StorageTimestamp;
  endShowDate?: StorageTimestamp;

  createdAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;

  constructor(
    id: string,
    name: string,
    founderJourneyPhase: string[],
    createdAt: StorageTimestamp,
    oneLiner?: string,
    imageUrl?: string,
    serviceType?: string,
    serviceTypeIcon?: string,
    companyFunction?: [],
    companyArr?: [],
    ctaText?: string,
    ctaUrl?: string,
    ctaDMUserId?: string,
    ctaDMDraft?: string,
    smsTag?: string[],
    edgeStoryTemplate?: string,
    smsNoteTemplate?: string,
    startShowDate?: StorageTimestamp,
    endShowDate?: StorageTimestamp,
    updatedAt?: StorageTimestamp,
  ) {
    this.id = id;
    this.name = name;
    this.oneLiner = oneLiner;
    this.imageUrl = imageUrl;
    this.founderJourneyPhase = founderJourneyPhase;
    this.serviceType = serviceType;
    this.serviceTypeIcon = serviceTypeIcon;
    this.companyFunction = companyFunction;
    this.companyArr = companyArr;
    this.ctaText = ctaText;
    this.ctaUrl = ctaUrl;
    this.ctaDMUserId = ctaDMUserId;
    this.ctaDMDraft = ctaDMDraft;
    this.smsTag = smsTag;
    this.edgeStoryTemplate = edgeStoryTemplate;
    this.smsNoteTemplate = smsNoteTemplate;
    this.startShowDate = startShowDate;
    this.endShowDate = endShowDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

const SEQUOIA_SERVICE_DATA_VERSION = 1;
export const SEQUOIA_SERVICE_HOSTING_BASE = `resource/data/v${SEQUOIA_SERVICE_DATA_VERSION}/sequoia-service`;

export const serviceMenuConverter: FirestoreDataConverter<ServiceMenu> = {
  toFirestoreModel: function (serviceMenu: ServiceMenu) {
    return {
      id: serviceMenu.id,
      founder_journey_phase: serviceMenu.founderJourneyPhase.map((phase) =>
        founderJourneyPhaseConverter.toFirestoreModel(phase),
      ),
      service: serviceMenu.service.map((item) =>
        serviceMenuItemConverter.toFirestoreModel(item),
      ),
      created_at: serviceMenu.createdAt,
      updated_at: serviceMenu.updatedAt,
    };
  },
  fromFirestoreModel: function (data): ServiceMenu {
    return new ServiceMenu(
      data.id,
      data.founder_journey_phase.map(
        founderJourneyPhaseConverter.fromFirestoreModel,
      ),
      data.service.map(serviceMenuItemConverter.fromFirestoreModel),
      data.created_at,
      data.updated_at,
    );
  },
};

export const founderJourneyPhaseConverter: FirestoreDataConverter<ServiceMenuFounderJourneyPhase> =
  {
    toFirestoreModel: function (phase: ServiceMenuFounderJourneyPhase) {
      return {
        id: phase.id,
        name: phase.name,
        backgroundImageUrl: phase.backgroundImageUrl,
      };
    },
    fromFirestoreModel: function (data): ServiceMenuFounderJourneyPhase {
      return new ServiceMenuFounderJourneyPhase(
        data.id,
        data.name,
        data.backgroundImageUrl,
      );
    },
  };

export const serviceMenuItemConverter: FirestoreDataConverter<ServiceMenuItem> =
  {
    toFirestoreModel: function (serviceMenuItem: ServiceMenuItem) {
      return {
        id: serviceMenuItem.id,
        name: serviceMenuItem.name,
        oneliner: serviceMenuItem.oneLiner,
        image: serviceMenuItem.imageUrl,
        founder_journey_phase: serviceMenuItem.founderJourneyPhase,
        service_type: serviceMenuItem.serviceType,
        service_type_icon: serviceMenuItem.serviceTypeIcon,
        company_function: serviceMenuItem.companyFunction,
        company_arr: serviceMenuItem.companyArr,
        cta_text: serviceMenuItem.ctaText,
        cta_url: serviceMenuItem.ctaUrl,
        cta_dm_user_id: serviceMenuItem.ctaDMUserId,
        cta_dm_draft: serviceMenuItem.ctaDMDraft,
        sms_tag: serviceMenuItem.smsTag,
        edge_story_template: serviceMenuItem.edgeStoryTemplate,
        sms_note_template: serviceMenuItem.smsNoteTemplate,
        start_show_date: serviceMenuItem.startShowDate,
        end_show_date: serviceMenuItem.endShowDate,
        created_at: serviceMenuItem.createdAt,
        updated_at: serviceMenuItem.updatedAt,
      };
    },
    fromFirestoreModel: function (data): ServiceMenuItem {
      return new ServiceMenuItem(
        data.id,
        data.name,
        data.founder_journey_phase || [],
        data.created_at,
        data.oneliner,
        data.image,
        data.service_type,
        data.service_type_icon,
        data.company_function,
        data.company_arr,
        data.cta_text,
        data.cta_url,
        data.cta_dm_user_id,
        data.cta_dm_draft,
        data.sms_tag,
        data.edge_story_template,
        data.sms_note_template,
        data.start_show_date,
        data.end_show_date,
        data.updated_at,
      );
    },
  };
