import {
  EmailDigestTemplateValues,
  V2EmailDigestTemplateValues,
} from "./email-digest-template-values";
import { StorageTimestamp } from "./base/data-type";
import { User } from "./user";
import { asEnum } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum EmailDigestStatus {
  pending = "pending",
  scheduled = "scheduled",
  sending = "sending",
  sent = "sent",
  cancelled = "cancelled",
  unknown = "unknown",
}

export class EmailDigest {
  id: string;
  createdById: string;
  title: string;
  templateId: string;
  sendTo: string;
  emailData: EmailDigestTemplateValues | V2EmailDigestTemplateValues;
  createdAt: StorageTimestamp;
  sendAt: Date;
  updatedAt?: StorageTimestamp;
  sentAt?: StorageTimestamp;
  status: EmailDigestStatus;
  taskId?: string;

  // populated data
  createdBy?: User;

  constructor(
    id: string,
    createdById: string,
    title: string,
    templateId: string,
    sendTo: string,
    emailData: EmailDigestTemplateValues | V2EmailDigestTemplateValues,
    createdAt: StorageTimestamp,
    sendAt: Date,
    updatedAt?: StorageTimestamp,
    sentAt?: StorageTimestamp,
    status: EmailDigestStatus = EmailDigestStatus.unknown,
    taskId?: string,
  ) {
    this.id = id;
    this.createdById = createdById;
    this.title = title;
    this.templateId = templateId;
    this.sendTo = sendTo;
    this.emailData = emailData;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.sendAt = sendAt;
    this.sentAt = sentAt;
    this.status = status;
    this.taskId = taskId;
  }
}

export const emailDigestConverter: FirestoreDataConverter<EmailDigest> = {
  toFirestoreModel: function (emailDigest: EmailDigest) {
    return {
      id: emailDigest.id,
      created_by_id: emailDigest.createdById,
      title: emailDigest.title,
      template_id: emailDigest.templateId,
      send_to: emailDigest.sendTo,
      email_data: emailDigest.emailData,
      created_at: emailDigest.createdAt,
      updated_at: emailDigest.updatedAt,
      send_at: emailDigest.sendAt,
      sent_at: emailDigest.sentAt,
      status: emailDigest.status,
      task_id: emailDigest.taskId,
    };
  },
  fromFirestoreModel: function (data): EmailDigest {
    return new EmailDigest(
      data.id,
      data.created_by_id,
      data.title,
      data.template_id,
      data.send_to || "null_group",
      data.email_data,
      data.created_at,
      data.send_at,
      data.updated_at,
      data.sent_at,
      asEnum(EmailDigestStatus, data.status, EmailDigestStatus.unknown),
      data.task_id,
    );
  },
};

export function isV1Values(
  v: EmailDigestTemplateValues | V2EmailDigestTemplateValues | undefined,
): v is EmailDigestTemplateValues {
  if (!v) {
    return false;
  }
  if ((v as V2EmailDigestTemplateValues).sections) {
    return false;
  }
  return true;
}
