import { StorageTimestamp } from "./base/data-type";
import { User } from "./user";
import { asEnum } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export type GroupMemberUser = {
  role: "moderator" | "normal";
  user: User;
  joinedAt?: Date;
};

export enum GroupPrivacy {
  public = "public", // Sequoia can see this
  opened = "opened", // Sequoia can NOT see this. Used for user-created groups
  searchable = "searchable", // Listed in searches. Can see title + meta but cannot see members. Labeled as "private" in UI.
  secret = "secret", // Unlisted from searches. Cannot title nor meta, even if linked to. Unused (Oct 28, 2021)
  unknown = "unknown",
}

export class Group {
  id: string;
  name: string;
  numMembers: number;
  numPosts: number;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  privacy: GroupPrivacy;
  detail?: string;
  coverImageURL?: string;
  lastPostedAt?: StorageTimestamp;
  hashtag?: Array<string>;

  // populated data
  members?: Record<string, GroupMemberUser>;

  constructor(
    id: string,
    name: string,
    numMembers: number,
    numPosts: number,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    privacy: GroupPrivacy,
    detail?: string,
    coverImageURL?: string,
    lastPostedAt?: StorageTimestamp,
    hashtag?: Array<string>,
  ) {
    this.id = id;
    this.name = name;
    this.numMembers = numMembers;
    this.numPosts = numPosts;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.privacy = privacy;
    this.detail = detail;
    this.coverImageURL = coverImageURL;
    this.lastPostedAt = lastPostedAt;
    this.hashtag = hashtag;
  }
}

export const groupConverter: FirestoreDataConverter<Group> = {
  toFirestoreModel: function (group: Group) {
    return {
      id: group.id,
      name: group.name,
      num_members: group.numMembers,
      num_posts: group.numPosts,
      created_at: group.createdAt,
      updated_at: group.updatedAt,
      privacy: group.privacy,
      detail: group.detail,
      cover_image_url: group.coverImageURL,
      last_posted_at: group.lastPostedAt,
      hashtag: group.hashtag,
    };
  },
  fromFirestoreModel: function (data): Group {
    return new Group(
      data.id,
      data.name,
      data.num_members,
      data.num_posts,
      data.created_at,
      data.updated_at,
      asEnum(GroupPrivacy, data.privacy, GroupPrivacy.unknown),
      data.detail,
      data.cover_image_url,
      data.last_posted_at,
      data.hashtag,
    );
  },
};
