import { Message, messageDataConverter } from "./message";
import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum ChatType {
  direct = "direct",
  group = "group",
  unknown = "unknown",
}

export enum ChatMemberType {
  creator = "creator",
  member = "member",
  unknown = "unknown",
}

export class ChatMemberInfo {
  id: string;
  joinedAt?: StorageTimestamp;
  status?: string;
  statusUpdatedAt?: StorageTimestamp;
  type?: ChatMemberType;
  lastReadAt?: StorageTimestamp;
  firstUnreadMessageId?: string;
  unreadCount?: number;
  lastEmailMessageId?: string;
  updatedAt?: StorageTimestamp;

  constructor(
    id: string,
    joinedAt?: StorageTimestamp,
    status?: string,
    statusUpdatedAt?: StorageTimestamp,
    type?: ChatMemberType,
    lastReadAt?: StorageTimestamp,
    firstUnreadMessageId?: string,
    unreadCount?: number,
    lastEmailMessageId?: string,
    updatedAt?: StorageTimestamp,
  ) {
    this.id = id;
    this.joinedAt = joinedAt;
    this.status = status;
    this.statusUpdatedAt = statusUpdatedAt;
    this.type = type;
    this.lastReadAt = lastReadAt;
    this.firstUnreadMessageId = firstUnreadMessageId;
    this.unreadCount = unreadCount;
    this.lastEmailMessageId = lastEmailMessageId;
    this.updatedAt = updatedAt;
  }
}

export class Chat {
  id: string;
  chatType: ChatType;
  title: string;
  defaultTitle: string;
  members: Record<string, ChatMemberInfo>;
  createdAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;
  lastMessageId?: string;
  lastMessage?: Message;
  lastMessageCreatorName?: string;
  lastMessageAt?: StorageTimestamp;
  customGroup?: boolean;

  constructor(
    id: string,
    chatType: ChatType,
    title: string,
    defaultTitle: string,
    members: Record<string, ChatMemberInfo>,
    createdAt: StorageTimestamp,
    updatedAt?: StorageTimestamp,
    lastMessageId?: string,
    lastMessage?: Message,
    lastMessageCreatorName?: string,
    lastMessageAt?: StorageTimestamp,
    customGroup?: boolean,
  ) {
    this.id = id;
    this.chatType = chatType;
    this.title = title;
    this.defaultTitle = defaultTitle;
    this.members = members;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.lastMessageId = lastMessageId;
    this.lastMessage = lastMessage;
    this.lastMessageCreatorName = lastMessageCreatorName;
    this.lastMessageAt = lastMessageAt;
    this.customGroup = customGroup;
  }
}

export const chatDataConverter: FirestoreDataConverter<Chat> = {
  toFirestoreModel: function (chat: Chat) {
    const members: Record<string, Record<string, any>> = {};
    Object.keys(chat.members).forEach((uid) => {
      members[uid] = chatMemberDataConverter.toFirestoreModel(
        chat.members[uid],
      );
    });
    const lastMessage = chat.lastMessage
      ? messageDataConverter.toFirestoreModel(chat.lastMessage)
      : undefined;
    return {
      id: chat.id,
      chat_type: chat.chatType,
      title: chat.title,
      default_title: chat.defaultTitle,
      member: members,
      created_at: chat.createdAt,
      updated_at: chat.updatedAt,
      last_message_id: chat.lastMessageId,
      last_message: lastMessage,
      last_message_creator_name: chat.lastMessageCreatorName,
      last_message_at: chat.lastMessageAt,
      custom_group: chat.customGroup,
    };
  },
  fromFirestoreModel: function (data): Chat {
    const members: Record<string, ChatMemberInfo> = {};
    Object.keys(data.member).forEach((uid) => {
      members[uid] = chatMemberDataConverter.fromFirestoreModel(
        data.member[uid],
      );
    });
    const lastMessage = data.last_message
      ? messageDataConverter.fromFirestoreModel(data.last_message)
      : undefined;
    return new Chat(
      data.id,
      ChatType[
        getEnumKeyByEnumValue(ChatType, data.chat_type) ?? ChatType.unknown
      ],
      data.title,
      data.default_title ?? "",
      members,
      data.created_at,
      data.updated_at,
      data.last_message_id,
      lastMessage,
      data.last_message_creator_name,
      data.last_message_at,
      data.custom_group,
    );
  },
};

const chatMemberDataConverter: FirestoreDataConverter<ChatMemberInfo> = {
  toFirestoreModel: function (info: ChatMemberInfo) {
    return {
      id: info.id,
      joined_at: info.joinedAt,
      status: info.status,
      status_updated_at: info.statusUpdatedAt,
      type: info.type,
      last_read_at: info.lastReadAt,
      first_unread_message_id: info.firstUnreadMessageId,
      unread_count: info.unreadCount,
      last_email_message_id: info.lastEmailMessageId,
      updated_at: info.updatedAt,
    };
  },
  fromFirestoreModel: function (data): ChatMemberInfo {
    return new ChatMemberInfo(
      data.id,
      data.joined_at,
      data.status,
      data.status_updated_at,
      ChatMemberType[
        getEnumKeyByEnumValue(ChatMemberType, data.type) ??
          ChatMemberType.unknown
      ],
      data.last_read_at,
      data.first_unread_message_id,
      data.unread_count,
      data.last_email_message_id,
      data.updated_at,
    );
  },
};
