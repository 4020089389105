/**
 * Bridge code between AmpersandWebViewController.swift (iOS) and the JS running in its webview.
 */
import { exists } from "@sequoiacap/shared/utils/exists";
import { pickBy } from "lodash";
import { useEffect } from "react";
import Router, { useRouter } from "next/router";

type FunctionCallback = (returnValue?: unknown, error?: Error) => void;

/** Posting from js to AWVC */
function messageHandlers() {
  const _messageHandlers = isFromAmpersandWebVC()
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window?.webkit as any)?.messageHandlers
    : undefined;
  return {
    closeBrowserListener: (message: Record<string, unknown> = {}) => {
      if (!isFromAmpersandWebVC()) {
        return undefined;
      }
      _messageHandlers?.closeBrowserListener?.postMessage?.(
        pickBy(message, exists),
      );
    },

    getIDTokenListener: (message: unknown) => {
      if (!isFromAmpersandWebVC()) {
        return undefined;
      }
      _messageHandlers?.getIDTokenListener?.postMessage?.(message);
    },

    performOptionalDelayedRouterPush: (message: unknown) => {
      if (!isFromAmpersandWebVC()) {
        return undefined;
      }
      _messageHandlers?.performOptionalDelayedRouterPush?.postMessage?.(
        message,
      );
    },

    fullPageLoading: (message: boolean) => {
      if (!isFromAmpersandWebVC()) {
        return undefined;
      }
      _messageHandlers?.fullPageLoading?.postMessage?.(message);
    },

    /** Notify AWVC of route change to a _awvcNativePaths */
    onRouteToNative: (message: {
      url: string;
      options: {
        shallow: boolean;
      };
    }) => {
      if (!isFromAmpersandWebVC()) {
        return undefined;
      }
      const handler = _messageHandlers?.onRouteToNative;
      if (handler) {
        handler?.postMessage(message);
        return true;
      }
    },
  };
}

function randomId() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
}
/**
 * Paths that result in `true` will notify the AWVC when a route change occurs.
 *
 * Uses _awvcNativePaths, which is set by the AWVC based on deeplinks its aware of.
 */
function isNativePath(path: string): boolean {
  try {
    const url = new URL(path, window.location.toString());
    const pathname = url.pathname;
    const firstPartOfPath = pathname.split(/\//)[1];

    // In various iOS versions, AWVC doesn't handle these properly, so don't tell AWVC about them. Pretend they are to be handled by the native app. Typically, this will mean pushing a new VC on top.
    // NOTE: Library links and links within the native app (e.g. prompt boxes) will still work.
    // NOTE: In a pinch, you can add a path here to avoid AWVC pushing a view controller. But most navigations should be handled with router.{push,replace}(... {shallow: true})
    if (
      pathname.startsWith("/library/sequoia-anywhere") ||
      pathname.startsWith("/library/hiring-benchmarks") ||
      pathname.startsWith("/resources/sequoia-anywhere") ||
      pathname.startsWith("/resources/hiring-benchmarks") ||
      pathname.startsWith("/services") ||
      // Route redirects, keep in sync with `firebase.json`
      pathname.startsWith("/realtalk") ||
      pathname.startsWith("/people") ||
      pathname.startsWith("/profileEdit") ||
      pathname.startsWith("/chatList") ||
      pathname.startsWith("/builders/connect") ||
      pathname.startsWith("/groupCreate") ||
      pathname.startsWith("/extendedsupport") ||
      pathname.startsWith("/network/connect/builders") ||
      pathname.startsWith("/network/connect/agencies-and-freelancers") ||
      pathname.startsWith("/network/founder") ||
      pathname.startsWith("/network/scout") ||
      pathname.startsWith("/network/leader") ||
      pathname.startsWith("/network/sequoia") ||
      pathname.startsWith("/arc/office-hours") ||
      pathname.startsWith("/arc/active-office-hours") ||
      pathname.startsWith("/arc/alumni-office-hours")
    ) {
      return false;
    }
    const result = window._awvcNativePaths?.includes(firstPartOfPath) ?? false;
    return result;
  } catch (e) {
    console.error(e);
    return false;
  }
}

const callbackRegistries: Record<string, FunctionCallback> = {};

// isFromAWVC
export function isFromAmpersandWebVC(): boolean {
  return (
    typeof window !== "undefined" &&
    !!window.webkit &&
    !!window._ampersandWebView
  );
}

type RouterChangeListener = (
  url: string,
  options?: { shallow?: boolean },
) => void;

export function useSetupAmpersandWebVCBridge(): void {
  const router = useRouter();
  const currentPath = router.asPath;

  useEffect(() => {
    window._awvcRouterPush ||= (path: string) => {
      void Router.push(path);
    };
    window._awvcRouterReplace ||= (path: string) => {
      void Router.replace(path);
    };
    let routeChangeStart: RouterChangeListener | undefined;
    if (isFromAmpersandWebVC()) {
      document
        .querySelector("html")
        ?.classList.add(...["awvc", window._ampersandUserAgent].filter(exists));

      // Tell the AWVC that a route change is happening, so it can handle deep links, pushing nav controllers, etc.
      // We sometimes don't want to notify AWVC, so it would stay on the same page.
      // 1.23+ AWVC handles options.shallow, so we can continue notifying AWVC in those cases too.
      routeChangeStart = (url, options) => {
        if (currentPath === url || !isNativePath(url) || options?.shallow) {
          return;
        }
        // Tell the AWVC that a route change is happening
        if (
          !messageHandlers().onRouteToNative({
            url,
            options: { shallow: options?.shallow ?? false },
          })
        ) {
          return;
        }

        // Route change was handled by AWVC (however it chose to do so), so abort the route change

        Router.events.emit("routeChangeError");
        void Router.replace(currentPath, currentPath, {
          shallow: true,
        });
        // eslint-disable-next-line no-throw-literal
        throw "Abort route change. Please ignore this error.";
      };

      window._webkitCallback = function (
        callbackToken: string,
        data: {
          result?: unknown;
          error?: Error;
        },
      ) {
        const callback = callbackRegistries[callbackToken];
        delete callbackRegistries[callbackToken];
        if (callback) {
          callback(data["result"], data["error"]);
        }
        return true;
      };
      Router.events.on("routeChangeStart", routeChangeStart);
    }
    return () => {
      if (isFromAmpersandWebVC() && routeChangeStart) {
        Router.events.off("routeChangeStart", routeChangeStart);
      }
    };
  }, [currentPath]);
}

export async function getIDTokenFromAmpersandWebVC(
  userId: string,
): Promise<string> {
  let token = randomId();
  while (callbackRegistries[token]) {
    token = randomId();
  }
  console.log("getIDTokenFromIOS", userId, token);
  return new Promise((resolve, reject) => {
    callbackRegistries[token] = (returnValue: unknown, error?: Error) => {
      if (error) {
        reject(error);
        return;
      }
      if (typeof returnValue !== "string") {
        reject(new Error("Invalid return value"));
        return;
      }
      resolve(returnValue);
    };
    messageHandlers().getIDTokenListener({
      callback_token: token,
      user_id: userId,
    });
  });
}

export function closeAmpersandWebVC(message: {
  type?:
    | "message_investor"
    | "submitted_memo"
    | "updated_memo"
    | "memo_approved"
    | "memo_approve_cancelled";
  redirect?: string;
}): void {
  messageHandlers().closeBrowserListener(message);
}

export function messageAmpersandWebVCLoading(loading: boolean) {
  if (isFromAmpersandWebVC()) {
    messageHandlers().fullPageLoading(loading);
  }
}

export function messageAmpersandWebVCPerformOptionalDelayedRouterPush() {
  if (isFromAmpersandWebVC()) {
    messageHandlers().performOptionalDelayedRouterPush("push");
  }
}
