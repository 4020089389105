import { Button, Text, useToastEffect } from "@sequoiacap/client-ui";
import { CloseIcon } from "@sequoiacap/client-ui/icons";

import { useNotificationPermissionToast } from "./useNotificationPermissionToast";
import styles from "./NotificationPermissionToast.module.scss";

export function NotificationPermissionToast() {
  const vm = useNotificationPermissionToast();
  const { hide } = useToastEffect(
    {
      content: vm.showNotificationPermissionToast && (
        <Text
          as="div"
          variant="body2Sans"
          className={styles.notificationContainer}
        >
          {vm.message}{" "}
          <Text
            as="button"
            underline
            onClick={vm.enableNotificationPermission}
            className={styles.enableNotification}
          >
            Enable push notifications.
          </Text>
          .
        </Text>
      ),
      actionsSlot: (
        <Button
          variant="plain"
          className={styles.enableNotificationClose}
          startIcon={<CloseIcon />}
          onClick={() => {
            vm.muteNotificationToast();
            hide();
          }}
          size="sm"
        />
      ),
      timeout: 0,
    },
    [
      vm.showNotificationPermissionToast,
      vm.enableNotificationPermission,
      vm.message,
    ],
  );
  return <></>;
}
