import {
  CloudFunctionName,
  FIREBASE_WRITE_TIMEOUT,
  ReadDocumentOptions,
  callCloudFunction,
  usePaginate,
  useReadDocument,
  useReadQuery,
} from "./firebase/FirebaseAPI";
import {
  GoogleAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  getRedirectResult,
  linkWithRedirect,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  unlink,
} from "firebase/auth";
import {
  PaginatedResult,
  ReadDocumentReturnType,
  ReadQueryReturnType,
} from "./firebase/types";
import { ResizedImageSize } from "@sequoiacap/shared/utils/resizeImageStoragePath";
import {
  ServerPropValue,
  ServerTimestamp,
  User,
  defaultFirestoreDataConverter,
  defaultServerPropValue,
  serverPropDataConverter,
  userConverter,
} from "@sequoiacap/shared/models";
import {
  TrackErrorEvent,
  TrackEvent,
  track,
  trackError,
} from "~/utils/analytics";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { deleteField, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { exists } from "@sequoiacap/shared/utils/exists";
import { fuego, mutateDocument } from "./swr-firebase";
import { generatePostId } from "./post-api";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "~/utils/localStorage";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { useState } from "react";
import { useStorageImage } from "./swr-firebase/hooks/use-swr-storage";
import getConfig from "next/config";
import getFirebaseDataConverter from "./firebase/firebase-data-converter";

const { publicRuntimeConfig } = getConfig();

export function useAPIGetUser(
  userId: string | null | undefined,
  options: ReadDocumentOptions<User> = {},
): ReadDocumentReturnType<User> {
  return useReadDocument(
    userId ? `user/${userId}` : null,
    userConverter,
    options,
  );
}

export function useAPIGetUserByEmail(
  email: string | null | undefined,
): ReadDocumentReturnType<User> {
  const isReady = email !== null && email !== undefined;
  const {
    data: users,
    error,
    loading,
  } = useReadQuery(
    isReady ? "user" : null,
    userConverter,
    undefined,
    undefined,
    [[`public_contact.email`, "==", email]],
  );
  return {
    data: users && users[0],
    error,
    loading,
  };
}

export function useAPIGetServerProp(
  userId: string | null | undefined,
): ReadDocumentReturnType<ServerPropValue> {
  const { data, loading } = useReadDocument(
    userId ? `server_prop/${userId}` : null,
    serverPropDataConverter,
    {
      listen: true,
    },
  );
  if (!userId) {
    return { loading: false };
  }
  if (loading) {
    return { loading };
  }
  return {
    data: {
      ...defaultServerPropValue,
      // override default serviceWorker setting in dev
      serviceWorkerV2:
        publicRuntimeConfig.APP_ENV === "dev"
          ? true
          : defaultServerPropValue.serviceWorkerV2,
      ...data?.value,
    },
    loading: false,
  };
}

export function useAPIGetUsers(limit: number): PaginatedResult<User> {
  return usePaginate("user", userConverter, limit, ["created_at", "asc"]);
}

export function useAPIGetAllUsers(): ReadQueryReturnType<User> {
  return useReadQuery("user", userConverter);
}

export function useAPIGetTopContributors(): ReadDocumentReturnType<User[]> {
  const [result, setResult] = useState<User[] | undefined>();
  const { data, loading, error } = useReadDocument(
    "top_contributor/0",
    defaultFirestoreDataConverter,
    {},
  );
  const userIds: string[] | undefined = data && data.users;

  if (error) {
    console.log("useAPIGetTopContributors/error", error);
  }

  useDeepCompareEffectNoCheck(() => {
    if (userIds !== undefined) {
      void (async function readEachUser() {
        const users = await Promise.all(
          userIds.map(async (id: string) => {
            const user = mutateDocument(`user/${id}`, userConverter);
            return user.catch(() => {
              console.log("useAPIGetTopContributors/error getting user", id);
              return undefined;
            });
          }),
        );
        setResult(users.filter(exists));
      })();
    }
  }, [userIds]);

  return {
    data: result,
    loading: loading && !result,
    error,
  };
}

export function useAPIGetUserProfileImageUrl(
  userId: string | null | undefined,
  size: ResizedImageSize = ResizedImageSize.Original,
): {
  url?: string;
  loading: boolean;
  error?: Error;
} {
  const originalPath = userId ? `user/${userId}/profilePicture.jpg` : undefined;
  const { src, loading, error } = useStorageImage(originalPath, size);
  return {
    url: src,
    loading,
    error,
  };
}

export function useAPIGetUsersByIds(
  userIds: string[] | undefined,
): ReadQueryReturnType<User> {
  const [users, setUsers] = useState<User[] | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useDeepCompareEffectNoCheck(() => {
    if (!userIds) {
      setUsers(undefined);
      setLoading(false);
      return;
    }
    if (userIds.length === 0) {
      setUsers([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    getUsersByIds(userIds)
      .then((result) => {
        setUsers(Object.values(result));
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [userIds]);

  return {
    loading,
    data: users,
    error,
  };
}

// would skip the user if user doc is not in db
export async function getUsersByIds(
  userIds: string[],
): Promise<Record<string, User>> {
  const users = await Promise.all(
    userIds.map(async (id: string) => {
      return getUser(id);
    }),
  );
  const map: Record<string, User> = {};
  users.forEach((user) => {
    if (user) map[user.id] = user;
  });
  return map;
}

async function getUser(id: string): Promise<User | undefined> {
  try {
    return await mutateDocument(`user/${id}`, userConverter);
  } catch (err) {
    console.log("getUser/error getting user", id, err);
    throw err;
  }
}

export async function updateUserProfile(
  loggedInUserId: string,
  changes: Partial<User>,
): Promise<void> {
  const path = `user/${loggedInUserId}`;
  changes.updatedAt = ServerTimestamp.create();
  if (changes.publicContact) {
    Object.keys(changes.publicContact).forEach((key) => {
      if (changes.publicContact && changes.publicContact[key] === undefined) {
        changes.publicContact[key] = deleteField();
      }
    });
  }

  try {
    await setDoc(
      doc(fuego.db, path).withConverter(
        getFirebaseDataConverter(userConverter),
      ),
      changes,
      { merge: true },
    );
    await mutateDocument(path, userConverter, true);
    track(TrackEvent.profileEdited, {
      userId: loggedInUserId,
      event: "Profile Edited",
    });
  } catch (err) {
    console.log("updateUserProfile/Error updating user profile error", err);
  }
}

export async function savePushToken(
  userId: string,
  token: string,
): Promise<void> {
  const path = `device/${token}`;
  const deviceId = getDeviceId();

  // get deviceId
  try {
    await asyncCallWithTimeout(
      setDoc(doc(fuego.db, path), {
        token,
        user_id: userId,
        updated_at: serverTimestamp(),
        version: "web",
        device_id: deviceId,
      }),
      FIREBASE_WRITE_TIMEOUT,
      `savePushToken(${userId})`,
    );
  } catch (err) {
    console.error("savePushToken/error", err);
    throw err;
  }
}

const DEVICE_ID_LOCAL_STORAGE_KEY = "device_id";
function getDeviceId(): string {
  let deviceId: string | undefined = getLocalStorageValue(
    DEVICE_ID_LOCAL_STORAGE_KEY,
    undefined,
  );
  if (!deviceId) {
    deviceId = generatePostId();
    setLocalStorageValue(DEVICE_ID_LOCAL_STORAGE_KEY, deviceId);
  }
  return deviceId;
}

export async function sendEmailLink(email: string): Promise<void> {
  return callCloudFunction(CloudFunctionName.sendEmailLink, { email: email });
}

export async function sendPasswordReset(email: string): Promise<void> {
  return sendPasswordResetEmail(getAuth(), email);
}

export async function loginWithPassword(
  email: string,
  password: string,
): Promise<void> {
  console.log(`loginWithPassword email=${email}`);
  await signInWithEmailAndPassword(getAuth(), email, password);
}

export async function loginWithGoogle(): Promise<void> {
  try {
    await signInWithRedirect(getAuth(), new GoogleAuthProvider());
  } catch (err) {
    console.error("loginWithGoogle", err);
    throw err;
  }
}

export async function loginWithMicrosoft(): Promise<void> {
  try {
    await signInWithRedirect(getAuth(), new OAuthProvider("microsoft.com"));
  } catch (err) {
    console.error("loginWithGoogle", err);
    throw err;
  }
}

export async function linkWithMicrosoft(): Promise<void> {
  const current = getAuth().currentUser;
  if (!current) {
    throw new Error("No current user");
  }
  try {
    await linkWithRedirect(current, new OAuthProvider("microsoft.com"));
  } catch (err) {
    trackError(TrackErrorEvent.linkWithMicrosoft, err);
    console.error("linkWithMicrosoft", err);
    throw err;
  }
}

export async function linkWithGoogle(): Promise<void> {
  const current = getAuth().currentUser;
  if (!current) {
    throw new Error("No current user");
  }
  try {
    await linkWithRedirect(current, new GoogleAuthProvider());
  } catch (err) {
    // trackError(TrackErrorEvent.linkWithGoogle, err);
    console.error("linkWithGoogle", err);
    throw err;
  }
}

export async function unlinkWithMicrosoft(): Promise<void> {
  const current = getAuth().currentUser;
  if (!current) {
    throw new Error("No current user");
  }
  try {
    await unlink(current, "microsoft.com");
  } catch (err) {
    console.error("unlinkWithMicrosoft", err);
    throw err;
  }
}

export async function unlinkWithGoogle(): Promise<void> {
  const current = getAuth().currentUser;
  if (!current) {
    throw new Error("No current user");
  }
  try {
    await unlink(current, "google.com");
  } catch (err) {
    console.error("unlinkWithGoogle", err);
    throw err;
  }
}

export async function getAuthRedirectErrorResult(): Promise<
  string | undefined
> {
  try {
    await getRedirectResult(getAuth());
  } catch (err) {
    trackError(TrackErrorEvent.oauthLogin, err);
    console.error("getAuthRedirectErrorResult", err);
    return "Failed to login";
  }
  return undefined;
}

export async function isMicrosoftLinked(): Promise<boolean> {
  const email = getAuth().currentUser?.email;
  if (!email) {
    return false;
  }
  const providers = await fetchSignInMethodsForEmail(getAuth(), email);
  return providers.includes("microsoft.com");
}
