import { Document } from "../swr-firebase";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { createFetchRequester } from "@algolia/requester-fetch";
import { getStoredSWRValueByPath } from "../firebase/network-cache-db";
import { parseJSON } from "@sequoiacap/shared/utils/superjson";
import { useAuth } from "~/components/auth/useAuth";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { useSearchToken } from "../search-api";
import algoliasearch, { SearchClient } from "algoliasearch";

type AlgoliaContextType = {
  prefix: string;
  client: SearchClient | null;
  refreshToken: () => void;
};

const initialState: AlgoliaContextType = {
  prefix: "",
  client: null,
  refreshToken: () => {
    // noop
  },
};

export const AlgoliaContext = createContext<AlgoliaContextType>(initialState);

function useAlgolia(): AlgoliaContextType {
  return useContext(AlgoliaContext);
}

export default useAlgolia;
let resolver: (value: AlgoliaContextType | null) => void;
let globalStatePromise: Promise<AlgoliaContextType | null> = new Promise(
  (resolve) => {
    resolver = resolve;
  },
);

export const AlgoliaProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { authUser } = useAuth();
  const [userId, setUserId] = useState<string | null>(authUser?.id ?? null);
  const { token, refreshToken } = useSearchToken(userId);
  const [state, setState] = useState(initialState);

  useDeepCompareEffectNoCheck(() => {
    if (token) {
      const client = algoliasearch(token.app_id, token.search_key, {
        requester: createFetchRequester(),
      });
      setState({ prefix: token.prefix, client, refreshToken });
      resolver({ prefix: token.prefix, client, refreshToken });
      globalStatePromise = Promise.resolve({
        prefix: token.prefix,
        client,
        refreshToken,
      });
    } else {
      setState(initialState);
    }
  }, [token]);

  useEffect(() => {
    // user logged out
    if (userId && !authUser?.id) {
      resolver(null);
      globalStatePromise = new Promise((resolve) => {
        resolver = resolve;
      });
      setUserId(null);
    } else if (authUser?.id) {
      setUserId(authUser.id);
    }
  }, [userId, authUser?.id]);

  console.log("AlgoliaProvider", authUser?.id, state.prefix);

  return (
    <AlgoliaContext.Provider value={state}>{children}</AlgoliaContext.Provider>
  );
};

export async function getAlgoliaDocument<T>(
  indexName: string,
  objectId: string,
  path: string,
): Promise<(Document<T> & { s: string }) | null> {
  const { client, prefix } = (await globalStatePromise) || {};
  if (!client) {
    throw new Error("Algolia client not initialized");
  }
  const object = await client
    .initIndex(`${prefix}${indexName}`)
    .getObject(objectId, { cacheable: false });
  const rawData = (object as { raw_data?: string }).raw_data;
  if (!rawData) {
    return null;
  }
  const data = parseJSON<T>(rawData);
  if (!data) {
    return null;
  }
  return processAlgoliaDocument(objectId, path, data);
}

export async function processAlgoliaDocument<T>(
  objectId: string,
  path: string,
  data: T,
) {
  const cachedDoc = await getStoredSWRValueByPath<Document<T>>(path);
  const fetchedUpdatedAt = (data as { updatedAt?: Date }).updatedAt;
  const cachedUpdatedAt = (cachedDoc?.data as { updatedAt?: Date })?.updatedAt;
  if (
    cachedDoc &&
    cachedUpdatedAt &&
    fetchedUpdatedAt &&
    cachedUpdatedAt.getTime() > fetchedUpdatedAt.getTime()
  ) {
    console.log(
      `processAlgoliaDocument algolia data too old path=${path} cachedAt=${cachedUpdatedAt.getTime()} fetchedAt=${fetchedUpdatedAt.getTime()}`,
    );
    return {
      ...cachedDoc,
      s: "c",
    };
  }

  return {
    id: objectId,
    data,
    path,
    snapshot: undefined,
    s: "al",
  };
}
