import {
  FUNCTION_RESULT,
  SWR_CACHE,
  createNetworkCacheDb,
  getWorkerValue,
  setWorkerValue,
} from "~/worker-db";
import { Key } from "swr";
import { LRUCache } from "lru-cache";
import { TrackErrorEvent, trackError } from "~/utils/analytics";
import { USE_INFINITE_QUERY } from "./FirebaseAPI";
import { isEqual, omit } from "lodash";
import { parseJSON, stringifyJSON } from "@sequoiacap/shared/utils/superjson";
import { sleep } from "@sequoiacap/shared/utils";
import { useCallback, useState } from "react";
import useAsyncEffect from "@sequoiacap/client-shared/hooks/useAsyncEffect";

export async function getStoredFunctionValue<T>(
  key: string[],
): Promise<T | undefined> {
  const db = await createNetworkCacheDb();
  const value = await db.get(FUNCTION_RESULT, key);
  return value?.result ? JSON.parse(value.result) : undefined;
}

export async function storeFunctionValue<T>(
  key: string[],
  value: T,
): Promise<void> {
  const db = await createNetworkCacheDb();
  await db.put(FUNCTION_RESULT, {
    functionName: key[0],
    params: key[1],
    result: JSON.stringify(value),
    timestamp: Date.now(),
  });
}

function parseSWRKey(
  key: Key,
): { stringKey: string; isInfiniteQuery: boolean } | undefined {
  if (!key) {
    return undefined;
  }
  let keyHash = key;
  let isInfiniteQuery = false;
  if (typeof key === "function") {
    try {
      keyHash = key();
      isInfiniteQuery = key().includes(USE_INFINITE_QUERY);
    } catch (err) {
      return undefined;
    }
  }
  if (keyHash) {
    return {
      stringKey: JSON.stringify(keyHash)
        .replace(/(\\"seconds\\":)\d+/g, "{ts}")
        .replace(/(\\"nanoseconds\\":)\d+/g, "{ns}"),
      isInfiniteQuery,
    };
  }
}

type CacheValue = { parsed: unknown; raw: string };

const IN_FLIGHT_REQUESTS = new Map<string, Promise<CacheValue | undefined>>();
const fetchMethod = async (stringKey: string) => {
  try {
    const db = await createNetworkCacheDb();
    const value = await db.get(SWR_CACHE, stringKey);
    const result = value?.result ? parseJSON<unknown>(value.result) : undefined;
    if (result && value) {
      return { parsed: result, raw: value.result };
    }
  } catch (error) {
    trackError(TrackErrorEvent.NetworkCacheDbError, error);
  }
  return undefined;
};

const memCache = new LRUCache<string, CacheValue>({
  max: 1000,
});

export async function getStoredSWRValueByPath<T>(key: Key) {
  const { stringKey = "" } = parseSWRKey(key) ?? {};
  const result = await getStoredSWRValue<T>(stringKey);
  const docResult = result as
    | { id?: string; path?: string; data?: T }
    | undefined;
  if (docResult?.id && docResult?.path && docResult?.data) {
    return {
      id: docResult.id,
      path: docResult.path,
      data: docResult.data,
      s: "dc",
    } as T;
  }
  return result;
}

export async function storeSWRValueByPath<T>(
  key: Key,
  value: T,
): Promise<void> {
  const { stringKey = "" } = parseSWRKey(key) ?? {};
  return storeSWRValue<T>(stringKey, value);
}

async function getStoredSWRValue<T>(stringKey: string): Promise<T | undefined> {
  if (!stringKey) {
    return undefined;
  }
  let result = memCache.get(stringKey);
  if (result) {
    return result.parsed as T;
  }
  if (IN_FLIGHT_REQUESTS.has(stringKey)) {
    const cacheValue = await IN_FLIGHT_REQUESTS.get(stringKey);
    return cacheValue?.parsed as T;
  }
  const promise = fetchMethod(stringKey);
  IN_FLIGHT_REQUESTS.set(stringKey, promise);
  result = await promise;
  if (result) {
    memCache.set(stringKey, result);
  }
  IN_FLIGHT_REQUESTS.delete(stringKey);
  return result?.parsed as T;
}

function isEqualExcludesKeys(
  a: unknown | undefined,
  b: unknown | undefined,
  keys: string[],
): boolean {
  if (typeof a !== "object" || typeof b !== "object") {
    return isEqual(a, b);
  }
  return isEqual(omit(a, keys), omit(b, keys));
}

async function storeSWRValue<T>(stringKey: string, value: T): Promise<void> {
  if (!stringKey) {
    return undefined;
  }
  const cachedValue = memCache.get(stringKey);
  if (isEqualExcludesKeys(cachedValue?.parsed, value, ["s", "snapshot"])) {
    return;
  }
  let json;
  if (value instanceof Array) {
    json = stringifyJSON(
      value.map((item) => {
        if (
          typeof item === "object" &&
          (item as { snapshot?: { fromCache?: boolean } }).snapshot
        ) {
          item.snapshot.fromCache = true;
        }
        return item;
      }),
      {
        minify: false,
        compressed: false,
      },
    );
  } else if (typeof value === "object") {
    json = stringifyJSON(omit(value, ["s", "snapshot"]), {
      minify: false,
      compressed: false,
    });
  } else {
    json = stringifyJSON(value, {
      minify: false,
      compressed: false,
    });
  }
  if (cachedValue?.raw === json) {
    return;
  }
  memCache.set(stringKey, { parsed: value, raw: json });
  try {
    const db = await createNetworkCacheDb();
    await db.put(SWR_CACHE, {
      key: stringKey,
      result: json,
      timestamp: Date.now(),
    });
  } catch (error) {
    trackError(TrackErrorEvent.NetworkCacheDbError, error);
  }
}

async function deleteSWRValue(stringKey: string): Promise<void> {
  if (!stringKey) {
    return;
  }
  memCache.delete(stringKey);
  try {
    const db = await createNetworkCacheDb();
    await db.delete(SWR_CACHE, stringKey);
  } catch (error) {
    trackError(TrackErrorEvent.NetworkCacheDbError, error);
  }
}

export function useGetStoredSWRValue<T>(
  key: Key,
  delayInfinityStoredResult: number,
): {
  data: T | undefined;
  updateCache: (value: T | undefined) => void;
} {
  const [storedValue, setStoredValue] = useState<
    { k: string; v: T } | undefined
  >(undefined);
  const [value, setValue] = useState<{ k: string; v: T } | undefined>(
    undefined,
  );
  const { stringKey = "", isInfiniteQuery = false } = parseSWRKey(key) ?? {};
  useAsyncEffect(
    async (isMounted) => {
      if (stringKey) {
        // Invalidate cache immediately if key changes
        setValue(undefined);
        const x = await getStoredSWRValue<T>(stringKey);
        if (isMounted()) {
          if (x) {
            setStoredValue({ k: stringKey, v: x });
          } else {
            setStoredValue(undefined);
          }
        }
      }
    },
    [stringKey],
  );

  // delay showing cached data for infinite queries
  useAsyncEffect(
    async (isMounted) => {
      if (!storedValue) {
        return;
      }
      if (isInfiniteQuery && delayInfinityStoredResult > 0) {
        await sleep(delayInfinityStoredResult);
      }
      if (isMounted()) {
        if (storedValue.k === stringKey) {
          setValue(storedValue);
        }
      }
    },
    [stringKey, storedValue, isInfiniteQuery],
  );
  const updateCache = useCallback(
    (newValue: T | undefined) => {
      if (stringKey) {
        if (newValue === undefined) {
          void deleteSWRValue(stringKey);
          setStoredValue(undefined);
        } else {
          void storeSWRValue<T>(stringKey, newValue);
          setStoredValue((oldValue) => {
            const newV = { k: stringKey, v: newValue };
            if (isEqual(oldValue, newV)) {
              return oldValue;
            }
            return newV;
          });
        }
      }
    },
    [stringKey],
  );
  if (value?.k === stringKey) {
    return {
      data: value.v,
      updateCache,
    };
  }
  return {
    data: undefined,
    updateCache,
  };
}

/**
 * Update items in an array in cache
 * @param key key to look for in cache
 * @param callback  callback to update item, remove item if callback returns undefined, won't update if callback returns same object
 */
export async function updateArrayItemInCache(
  key: Key,
  callback: (current: object) => object | undefined,
) {
  const { stringKey = "" } = parseSWRKey(key) ?? {};
  if (!stringKey) {
    return;
  }

  const storedValue = await getStoredSWRValue(stringKey);
  if (!(storedValue instanceof Array)) {
    return;
  }

  let changed = false;
  let i = storedValue.length - 1;
  while (i >= 0) {
    const value = storedValue[i];
    if (value && typeof value === "object") {
      const newValue = callback(value);
      if (newValue !== value) {
        changed = true;
        if (newValue === undefined) {
          storedValue.splice(i, 1);
        } else {
          storedValue[i] = newValue;
        }
      }
    }
    i--;
  }
  if (changed) {
    await storeSWRValue(stringKey, storedValue);
  }
}

export function useGetWorkerValue(key?: string[]): {
  data: string | undefined;
  updateValue: (value: string) => Promise<void>;
} {
  const [data, setData] = useState<string | undefined>(undefined);
  const updateValue = useCallback(
    async (value: string) => {
      if (!key) {
        return;
      }
      try {
        await setWorkerValue(key, value);
        setData(value);
      } catch (err) {
        console.error("Failed to set worker value", err);
      }
    },
    [key],
  );
  useAsyncEffect(
    async (isMounted) => {
      if (!key) {
        return;
      }
      const value = await getWorkerValue(key);
      if (isMounted()) {
        setData(value);
      }
    },
    [key],
  );
  return { data, updateValue };
}
