import { ListenerReturnType } from "../hooks/use-swr-collection";
import { sleep } from "@sequoiacap/shared/utils";

// Reference counting to snapshot listeners
export function registerSubscription(
  key: string,
  id: number,
  subscriberFn?: ListenerReturnType<unknown>["unsubscribe"],
): void {
  if (subscriberFn) {
    if (snapShotSubscription[key]) {
      snapShotSubscription[key].updateUnsubscribeFn(subscriberFn);
    } else {
      snapShotSubscription[key] = new SubscriptionObject(key, subscriberFn);
    }
  }
  snapShotSubscription[key].register(id);
}

export function unregisterSubscription(key: string, id: number): void {
  if (snapShotSubscription[key]) {
    snapShotSubscription[key].unregister(id);
  }
}

export function hasSubcription(key: string): boolean {
  return !!snapShotSubscription[key];
}

const snapShotSubscription: Record<string, SubscriptionObject> = {};
class SubscriptionObject {
  key: string;
  unsubscribeFn: ListenerReturnType<unknown>["unsubscribe"];
  subscribers: Set<number> = new Set();
  unsubscribed = false;

  constructor(
    key: string,
    unSubscribeFn: ListenerReturnType<unknown>["unsubscribe"],
  ) {
    this.key = key;
    this.unsubscribeFn = unSubscribeFn;
  }

  register(subscriberId: number) {
    if (this.unsubscribed) {
      throw new Error(
        `[SWR] SubscriptionObject.register: Cannot register to a subscription that has been unsubscribed.`,
      );
    }
    this.subscribers.add(subscriberId);
    // console.log(
    //   "[SWR] SubscriptionObject.register subscribers:",
    //   this.subscribers,
    //   this.key
    // );
  }

  updateUnsubscribeFn(
    subscriberFn: ListenerReturnType<unknown>["unsubscribe"],
  ) {
    if (this.unsubscribeFn !== subscriberFn) {
      this.unsubscribeFn();
      this.unsubscribeFn = subscriberFn;
    }
  }

  unregister(subscriberId: number) {
    this.subscribers.delete(subscriberId);
    // console.log(
    //   "[SWR] SubscriptionObject.unregsiter subscribers:",
    //   this.subscribers,
    //   this.key
    // );
    if (this.subscribers.size === 0) {
      // wait a second before unsubscribing. it saves a lot of calls to firebase
      // especially when components are unmounted and mounted again
      sleep(1000)
        .then(() => {
          if (this.subscribers.size === 0) {
            // console.log("[SWR] SubscriptionObject unsubscribe", this.key);
            this.unsubscribeFn();
            this.unsubscribed = true;
            delete snapShotSubscription[this.key];
          } else {
            // console.log(
            //   "[SWR] SubscriptionObject unsubscribe, skipped",
            //   this.key
            // );
          }
        })
        .catch(console.error);
    }
  }
}
