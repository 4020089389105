import { HubActivity, HubSpeaker } from "./exp-hub";

export type BaseCampCohort = {
  id: string;
  title: string;

  /** Needs to be defined for the Event Cell to work */
  backgroundImageURL: string;

  /** Keyed by airtableId */
  tentsById: Record<string, BaseCampTent>;
  activitiesById: Record<string, HubActivity>;
  activities?: HubActivity[];
  speakersById: Record<string, HubSpeaker>;
  speakers?: HubSpeaker[];

  /** We can remove this later and save schedules as a separate record once in firestore */
  schedulesById: Record<string, UserBaseCampSchedule>;
  userIdToScheduleId: Record<string, string>;

  infos: BaseCampInfo[];

  /** When does this cohort start showing up in the header + prompt? */
  startShowDate: Date;
  /** When does this cohort stop showing up in the header + prompt? */
  endShowDate: Date;

  /** When does this cohort start */
  startDate: Date;
  /** When does this cohort end */
  endDate: Date;

  /** When do we start showing personalized schedules */
  showPersonalizedAfter: Date;
};

export type BaseCampInfo = {
  name: string;
  description?: string;
  // https://airtable.com/appU0jbKC1uXq4ll6/tblWPcOTKD4OMxjFH/viwAaa1RcM58TgcNv/fldc7Fl8CQytFBy9w
  ui:
    | "wifi"
    | "getting-around"
    | "faq"
    | "what-to-pack"
    | "getting-to-basecamp";
  image?: string;
  metadata?: string;
  airtableId: string;
};

export type BaseCampTent = {
  number: string;
  area: string;
  description?: string;
  memberIds: string[];
  airtableId: string;
};

/**
 * Schedule for an individual Base Camp participant
 */
export type UserBaseCampSchedule = {
  userId: string;
  tentId: string;
  tent?: BaseCampTent;
  activityIds: string[];
  activities?: HubActivity[];
  airtableId: string;

  /** Starting 2024, folks in a cohort are subdivided into sub-cohorts with some activities grouped by sub-cohort. */
  subCohort: string | undefined;
  subCohortChatId: string | undefined;
};

/**
 * Data in the listing file
 */
export type BaseCampCohortInListingFile = Pick<
  BaseCampCohort,
  | "id"
  | "title"
  | "startShowDate"
  | "endShowDate"
  | "startDate"
  | "endDate"
  | "backgroundImageURL"
> & {
  memberIds: string[];
};

const BASE_CAMP_DATA_VERSION = 3;
export const BASE_CAMP_HOSTING_BASE = `resource/data/v${BASE_CAMP_DATA_VERSION}`;
