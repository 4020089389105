import { StorageTimestamp } from "./base/data-type";
import { TopicHierarchy, topicHierarchyConverter } from "./topic-hierarchy";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export const HIDE_REG_URL = "​"; // Zero width space;

export enum EventType {
  regular = "regular",
  officeHours = "officeHours",
  unknown = "unknown",
}

export enum HostType {
  SequoiaInvestor = "Sequoia Investor",
  SequoiaOperator = "Sequoia Operator",
  Builder = "Builder",
}

export enum TopicType {
  AI = "AI",
  Blockchain = "Blockchain",
}

// Also known as Function (but function is a keyword so we use expertise instead)
export enum ExpertiseType {
  GTM = "GTM",
  Product = "Product",
  Engineering = "Engineering",
  Design = "Design",
  Talent = "Talent",
}

export const readableEventName = {
  [EventType.regular]: "Meetups",
  [EventType.officeHours]: "Office Hours",
} as Record<EventType, string>;

export type EventFeedMinimumData = {
  summary: string;
  description?: string;
  startAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;
  imageUrl?: string;
};

export class Event {
  id: string;
  eventType: EventType;
  /** Title of the event */
  summary: string;
  /** Signed up via Ampersand OR accepted a manual invite from GCal (e.g. Experiences team added this person to the GCal event and the user accepted the invite).
   *
   * See also `signedUpThroughAmpersandIds`.
   * Details: https://app.asana.com/0/1184397562448889/1205289011233484
   */
  attendeeIds: string[];
  createdBy: {
    name?: string;
    email: string;
  };
  eventUrl: string;
  startAt: StorageTimestamp;
  endAt?: StorageTimestamp;
  location?: string;
  /** Event details, in raw HTML */
  description?: string;
  createdById?: string;
  insertedById?: string;
  createdAt?: StorageTimestamp;
  updatedAt?: StorageTimestamp;
  imageId?: string;
  imageUrl?: string;
  userListId?: string;
  /** Undefined if directly reg through GCal. Set to HIDE_REG_URL if hiding the button. */
  registrationLink?: string;
  /** DEPRECATED. Typically, we do not want to reflect the exact state of the GCal guest list or "accepted" list since we want the UI to reflect immediately.
   * https://app.asana.com/0/1184397562448889/1205289011233484
   */
  numOfYess: number;
  topics?: string[];
  hostType?: string;
  maxAttendees?: number;
  /** DEPRECATED. Use signedUpThroughAmpersandIds.length instead */
  currentNumOfAttendees?: number;
  expertises?: string[];
  hideAttendeesList?: boolean;
  /**
   * Signed up through Ampersand UI. May not have accepted the invite from GCal yet. May be removed if user declines the GCal invite. Used to enforce maxAttendee.
   *
   * See also `attendeeIds`.
   * Details: https://app.asana.com/0/1184397562448889/1205289011233484
   */
  signedUpThroughAmpersandIds?: string[];

  /**
   * List of of attendeeIds that has signed up through Ampersand UI but has not confirmed that they are added to the GCal event.
   */
  unconfirmedAttendeeIds?: string[];

  // Populated elsewhere
  isSignedUp?: boolean;

  topicHierarchy?: TopicHierarchy;

  constructor(
    id: string,
    eventType: EventType,
    summary: string,
    attendeeIds: string[],
    createdBy: {
      name?: string;
      email: string;
    },
    eventUrl: string,
    startAt: StorageTimestamp,
    endAt?: StorageTimestamp,
    location?: string | null,
    description?: string | null,
    createdById?: string,
    insertedById?: string,
    createdAt?: StorageTimestamp,
    updatedAt?: StorageTimestamp,
    imageId?: string,
    imageUrl?: string,
    userListId?: string,
    registrationLink?: string,
    numberOfYess?: number,
    topics?: string[],
    hostType?: string,
    maxAttendees?: number,
    currentNumOfAttendees?: number,
    expertises?: string[],
    hideAttendeesList?: boolean,
    signedUpThroughAmpersandIds?: string[],
    unconfirmedAttendeeIds?: string[],
    topicHierarchy?: TopicHierarchy,
  ) {
    this.id = id;
    this.eventType = eventType;
    this.summary = summary;
    this.attendeeIds = attendeeIds;
    this.createdBy = createdBy;
    this.eventUrl = eventUrl;
    this.startAt = startAt;
    this.endAt = endAt;
    this.location = location ?? undefined;
    this.description = description ?? undefined;
    this.createdById = createdById;
    this.insertedById = insertedById;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.imageId = imageId;
    this.imageUrl = imageUrl;
    this.userListId = userListId;
    this.registrationLink = registrationLink;
    this.numOfYess = numberOfYess ?? 0;
    this.topics = topics;
    this.hostType = hostType;
    this.maxAttendees = maxAttendees;
    this.currentNumOfAttendees = currentNumOfAttendees;
    this.expertises = expertises;
    this.hideAttendeesList = hideAttendeesList;
    this.signedUpThroughAmpersandIds = signedUpThroughAmpersandIds;
    this.unconfirmedAttendeeIds = unconfirmedAttendeeIds;
    this.topicHierarchy = topicHierarchy;
  }
}

export const eventConverter: FirestoreDataConverter<Event> = {
  toFirestoreModel: function (event: Event) {
    return {
      id: event.id,
      event_type: event.eventType,
      summary: event.summary,
      attendee_ids: event.attendeeIds,
      created_by: event.createdBy,
      event_url: event.eventUrl,
      start_at: event.startAt,
      end_at: event.endAt,
      location: event.location,
      description: event.description,
      created_by_id: event.createdById,
      inserted_by_id: event.insertedById,
      created_at: event.createdAt,
      updated_at: event.updatedAt,
      image_id: event.imageId,
      image_url: event.imageUrl,
      user_list_id: event.userListId,
      registration_link: event.registrationLink,
      num_of_yes: event.numOfYess,
      topic: event.topics,
      host_type: event.hostType,
      max_attendee: event.maxAttendees,
      current_num_of_attendee: event.currentNumOfAttendees,
      expertise: event.expertises,
      hide_attendees_list: event.hideAttendeesList,
      signed_up_through_ampersand_ids: event.signedUpThroughAmpersandIds,
      unconfirmed_attendee_ids: event.unconfirmedAttendeeIds,
      topic_hierarchy:
        event.topicHierarchy &&
        topicHierarchyConverter.toFirestoreModel(event.topicHierarchy),
    };
  },
  fromFirestoreModel: function (data): Event {
    return new Event(
      data.id,
      EventType[
        getEnumKeyByEnumValue(EventType, data.event_type) ?? EventType.regular
      ],
      data.summary,
      data.attendee_ids || [],
      data.created_by,
      data.event_url,
      data.start_at,
      data.end_at,
      data.location,
      data.description,
      data.created_by_id,
      data.inserted_by_id,
      data.created_at,
      data.updated_at,
      data.image_id,
      data.image_url,
      data.user_list_id,
      data.registration_link,
      data.num_of_yes,
      data.topic,
      data.host_type,
      data.max_attendee,
      data.current_num_of_attendee,
      data.expertise,
      data.hide_attendees_list,
      data.signed_up_through_ampersand_ids,
      data.unconfirmed_attendee_ids,
      data.topic_hierarchy &&
        topicHierarchyConverter.fromFirestoreModel(data.topic_hierarchy),
    );
  },
};
