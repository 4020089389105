import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum BookmarkItemType {
  library = "library",
  arc = "arc",
  scoutCamp = "scoutCamp",
  baseCamp = "baseCamp",
  unknown = "unknown",
}

export class BookmarkItem {
  readonly id: string;
  readonly itemType: BookmarkItemType;
  readonly createdAt: StorageTimestamp;
  readonly path: string;

  constructor(
    id: string,
    itemType: BookmarkItemType,
    createdAt: StorageTimestamp,
    path: string,
  ) {
    this.id = id;
    this.itemType = itemType;
    this.createdAt = createdAt;
    this.path = path;
  }
}

export class Bookmark {
  readonly id: string;
  items: Record<string, BookmarkItem>;
  readonly createdAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;

  constructor(
    id: string,
    items: Record<string, BookmarkItem>,
    createdAt: StorageTimestamp,
    updatedAt?: StorageTimestamp,
  ) {
    this.id = id;
    this.items = items;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export function convertBookmarkItemsToFirebaseModel(bookmarkItems?: {
  [key: string]: BookmarkItem;
}): Record<string, Record<string, string>> {
  const firebaseModel: Record<string, Record<string, string>> = {};
  if (bookmarkItems) {
    Object.keys(bookmarkItems).forEach((key) => {
      firebaseModel[key] = bookmarkItemDataConverter.toFirestoreModel(
        bookmarkItems[key],
      );
    });
  }
  return firebaseModel;
}

export function convertBookmarkItemsFromFirebaseModel(firebaseModel?: {
  [key: string]: any;
}): { [key: string]: BookmarkItem } {
  const bookmarkItems: { [key: string]: BookmarkItem } = {};
  if (firebaseModel) {
    Object.keys(firebaseModel).forEach((key) => {
      const value = bookmarkItemDataConverter.fromFirestoreModel(
        firebaseModel[key],
      );
      bookmarkItems[key] = value;
    });
  }
  return bookmarkItems;
}

export function keyForBookmarkItem(bookmarkItem: BookmarkItem): string {
  return keyForBookmarkItemIdAndItemType(
    bookmarkItem.id,
    bookmarkItem.itemType,
  );
}

export function keyForBookmarkItemIdAndItemType(
  id: string,
  itemType: BookmarkItemType,
): string {
  return `${itemType}-${id}`;
}

export function pathForBookmarkItem(
  id: string,
  itemType: BookmarkItemType,
): string {
  switch (itemType) {
    case BookmarkItemType.library:
      return `resources/${id}`;
    case BookmarkItemType.arc:
      return `arc/${id}`;
    case BookmarkItemType.scoutCamp:
      return `scout-camp/${id}`;
    case BookmarkItemType.baseCamp:
      return `base-camp/${id}`;
    case BookmarkItemType.unknown:
      return "";
  }
}

export const bookmarkDataConverter: FirestoreDataConverter<Bookmark> = {
  toFirestoreModel: function (bookmark: Bookmark) {
    return {
      id: bookmark.id,
      items: convertBookmarkItemsToFirebaseModel(bookmark.items),
      created_at: bookmark.createdAt,
      updated_at: bookmark.updatedAt,
    };
  },
  fromFirestoreModel: function (data): Bookmark {
    return new Bookmark(
      data.id,
      convertBookmarkItemsFromFirebaseModel(data.items),
      data.created_at,
      data.updated_at,
    );
  },
};

const bookmarkItemDataConverter: FirestoreDataConverter<BookmarkItem> = {
  toFirestoreModel: function (bookmarkItem: BookmarkItem) {
    return {
      id: bookmarkItem.id,
      item_type: bookmarkItem.itemType,
      created_at: bookmarkItem.createdAt,
      path: bookmarkItem.path,
    };
  },
  fromFirestoreModel: function (data): BookmarkItem {
    return new BookmarkItem(
      data.id,
      BookmarkItemType[
        getEnumKeyByEnumValue(BookmarkItemType, data.item_type) ??
          BookmarkItemType.unknown
      ],
      data.created_at,
      data.path,
    );
  },
};
