import { Modal, Text } from "@sequoiacap/client-ui";
import { TrackEvent, track } from "~/utils/analytics";
import { isFromAmpersandWebVC } from "~/network/ampersand-web-vc-bridge";
import {
  parseBuildNumber,
  useAPIListenAppVersion,
} from "~/network/app-version-api";
import { useAuth } from "../auth/useAuth";
import { useOncePerSession } from "~/hooks/useOncePerSession";
import { useRouter } from "next/router";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export function CheckMinWebVersion() {
  const { authUser } = useAuth();
  const { data: appVersion, loading } = useAPIListenAppVersion(!authUser);
  const currentBuild = parseBuildNumber(publicRuntimeConfig.BUILD_NUMBER);
  const requiredBuild = appVersion?.min_web_version
    ? parseBuildNumber(appVersion.min_web_version)
    : undefined;

  // Once per session, show the user the force update modal.
  const forceRefreshCount = useOncePerSession("fr");
  const trackForceRefreshSkip = useOncePerSession("tfrs");

  const router = useRouter();

  if (isFromAmpersandWebVC()) {
    return null;
  }

  if (loading) {
    return null;
  }
  if (
    currentBuild.hash === requiredBuild?.hash ||
    isNaN(requiredBuild?.date.getTime() ?? NaN) ||
    currentBuild.date.getTime() >= requiredBuild!.date.getTime()
  ) {
    // Current build is up to date
    // console.log(
    //   "qqq current build matches",
    //   currentBuild.hash,
    //   requiredBuild?.hash,
    //   currentBuild.date.getTime(),
    //   requiredBuild?.date.getTime(),
    // );
    forceRefreshCount.clear();
    return null;
  }

  // const lastForceRefresh = window.sessionStorage?.getItem("lfr");
  if (forceRefreshCount.doneEnough) {
    // Already tried refreshing. Don't show modal.
    // console.log("qqq already tried refreshing");
    if (!trackForceRefreshSkip.doneEnough) {
      track(TrackEvent.forceRefreshSkipped, {
        currentBuild: currentBuild.buildNumber,
        requiredBuild: requiredBuild?.buildNumber,
      });
      trackForceRefreshSkip.inc();
    }
    return null;
  }

  track(TrackEvent.forceRefreshShown, {
    currentBuild: currentBuild.buildNumber,
    requiredBuild: requiredBuild?.buildNumber,
  });

  return (
    <Modal
      open
      onClose={() => {
        //noop
      }}
      hideCloseButton
    >
      ⚠️ Out of date, please{" "}
      <Text
        as="a"
        href="#"
        underline
        onClick={() => {
          forceRefreshCount.inc();
          track(TrackEvent.forceRefreshClicked, {
            currentBuild: currentBuild.buildNumber,
            requiredBuild: requiredBuild?.buildNumber,
          });
          router.reload();
        }}
      >
        refresh
      </Text>
    </Modal>
  );
}
