import FirestoreDataConverter from "./base/data-converter";

export class RateLimit {
  uid: string;
  hits: Record<string, Date[]>;

  constructor(uid: string, hits: Record<string, Date[]>) {
    this.uid = uid;
    this.hits = hits;
  }
}

export const rateLimitDataConverter: FirestoreDataConverter<RateLimit> = {
  toFirestoreModel: function (rateLmit: RateLimit) {
    return {
      uid: rateLmit.uid,
      hits: rateLmit.hits,
    };
  },
  fromFirestoreModel: function (data): RateLimit {
    return new RateLimit(data.uid, data.hits);
  },
};
