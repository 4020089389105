import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class LinkedInSlugLookup {
  id: string;
  userId: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  conflictUserIds: string[];

  constructor(
    id: string,
    userId: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    conflictUserIds: string[],
  ) {
    this.id = id;
    this.userId = userId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.conflictUserIds = conflictUserIds;
  }
}

export const linkedInSlugLookupConverter: FirestoreDataConverter<LinkedInSlugLookup> =
  {
    toFirestoreModel: function (linkedInSlugLookup: LinkedInSlugLookup) {
      return {
        id: linkedInSlugLookup.id,
        user_id: linkedInSlugLookup.userId,
        created_at: linkedInSlugLookup.createdAt,
        updated_at: linkedInSlugLookup.updatedAt,
        conflict_user_id: linkedInSlugLookup.conflictUserIds,
      };
    },
    fromFirestoreModel: function (data: any) {
      return new LinkedInSlugLookup(
        data.id,
        data.user_id,
        data.created_at,
        data.updated_at,
        data.conflict_user_id,
      );
    },
  };
