import { FieldPath, PartialWithFieldValue } from "firebase/firestore";

export default interface FirestoreDataConverter<T> {
  /**
   * Called by the Firestore SDK to convert a custom model object of type T
   * into a plain Javascript object (suitable for writing directly to the
   * Firestore database). To use `set()` with `merge` and `mergeFields`,
   * `toFirestore()` must be defined with `Partial<T>`.
   */
  toFirestoreModel(
    modelObject: PartialWithFieldValue<T>,
    options?: {
      merge?: boolean;
      mergeFields?: Array<string | FieldPath>;
    },
  ): { [field: string]: any };

  /**
   * Called by the Firestore SDK to convert Firestore data into an object of
   * type T. You can access your data by calling: `snapshot.data(options)`.
   *
   * @param snapshot A QueryDocumentSnapshot containing your data and metadata.
   * @param options The SnapshotOptions from the initial call to `data()`.
   */
  fromFirestoreModel(data: { [field: string]: any }): T;
}

export let defaultFirestoreDataConverter: FirestoreDataConverter<{
  id: string;
  [key: string]: any;
}> = {
  toFirestoreModel: function (record: Record<string, any>) {
    return record;
  },
  fromFirestoreModel: function (data): { id: string; [key: string]: any } {
    return {
      id: data.id,
      ...data,
    };
  },
};
