import {
  isWritableClaim,
  landingPageClaim,
  useAuth,
} from "~/components/auth/useAuth";

export interface LightUserInfoContextType {
  userId?: string;
  displayName?: string;
  email?: string;
  isWritableUser?: boolean;
  isAdmin?: boolean;
  landingPage?: string;
}

export default function useLightUserInfo(): LightUserInfoContextType {
  const { authUser } = useAuth();
  if (!authUser) return {};

  const { id, displayName, email, claims } = authUser;
  const isWritableUser = isWritableClaim(claims);
  const landingPage = landingPageClaim(claims);

  return {
    userId: id ?? undefined,
    displayName: displayName ?? undefined,
    email: email ?? undefined,
    isWritableUser,
    landingPage,
    isAdmin: !!claims?.admin,
  };
}
