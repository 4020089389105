export default interface Entity<T> {
  readonly id: string;
  deleted?: boolean;
  shouldUpdateCallback?: (newEntity: T) => boolean;
  updateCallback?: (newEntity: T) => void;
}

export class DeletedEntityError extends Error {
  id: string;
  collection: string;
  constructor(message: string, id: string, collection: string) {
    super(message);
    this.id = id;
    this.collection = collection;
  }
}
