/* eslint-disable @typescript-eslint/no-explicit-any */
import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum AIMessageType {
  user = "user",
  ai = "ai",
  unknown = "unknown",
}

export enum LLM {
  openai = "openai",
  azure = "azure",
  google = "google",
}

export enum ThreadStatus {
  ready = "ready",
  waiting = "waiting",
  answering = "answering",
  unknown = "unknown",
}

export class AIMessage {
  id: string;
  type: AIMessageType;
  message: string;
  createdAt: StorageTimestamp;

  constructor(
    id: string,
    type: AIMessageType,
    message: string,
    createdAt: StorageTimestamp,
  ) {
    this.id = id;
    this.type = type;
    this.message = message;
    this.createdAt = createdAt;
  }
}

export class AskAmpersand {
  readonly id: string;
  readonly createdAt: StorageTimestamp;
  readonly createdById: string;
  messages: Record<string, AIMessage>;
  llm: LLM;
  updatedAt?: StorageTimestamp;
  status?: ThreadStatus;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    createdById: string,
    messages: Record<string, AIMessage>,
    llm: LLM,
    updatedAt?: StorageTimestamp,
    status?: ThreadStatus,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.createdById = createdById;
    this.messages = messages;
    this.llm = llm;
    this.updatedAt = updatedAt;
    this.status = status;
  }
}

export function convertAIMessagesToFirebaseModel(
  messages?: Record<string, AIMessage>,
): Record<string, Record<string, string>> {
  const result: Record<string, Record<string, string>> = {};
  if (messages) {
    Object.values(messages).forEach((m) => {
      result[m.id] = aiMessageDataConverter.toFirestoreModel(m);
    });
  }
  return result;
}

export function convertAIMessagesFromFirebaseModel(
  messages?: any,
): Record<string, AIMessage> {
  const result: Record<string, AIMessage> = {};
  if (messages) {
    Object.values(messages).forEach((a: any) => {
      result[a.id] = aiMessageDataConverter.fromFirestoreModel(a);
    });
  }
  return result;
}

export const aiMessageDataConverter: FirestoreDataConverter<AIMessage> = {
  toFirestoreModel: function (message: AIMessage) {
    return {
      id: message.id,
      type: message.type,
      message: message.message,
      created_at: message.createdAt,
    };
  },
  fromFirestoreModel: function (data): AIMessage {
    return new AIMessage(
      data.id,
      AIMessageType[
        getEnumKeyByEnumValue(AIMessageType, data.type) ?? AIMessageType.unknown
      ],
      data.message,
      data.created_at,
    );
  },
};

export const askAmpersandDataConverter: FirestoreDataConverter<AskAmpersand> = {
  toFirestoreModel: function (askAmpersand: AskAmpersand) {
    return {
      id: askAmpersand.id,
      created_at: askAmpersand.createdAt,
      created_by_id: askAmpersand.createdById,
      message: convertAIMessagesToFirebaseModel(askAmpersand.messages),
      llm: askAmpersand.llm,
      updated_at: askAmpersand.updatedAt,
      status: askAmpersand.status,
    };
  },
  fromFirestoreModel: function (data): AskAmpersand {
    return new AskAmpersand(
      data.id,
      data.created_at,
      data.created_by_id,
      convertAIMessagesFromFirebaseModel(data.message),
      data.llm ?? LLM.openai,
      data.updated_at,
      ThreadStatus[
        getEnumKeyByEnumValue(ThreadStatus, data.status) ?? ThreadStatus.unknown
      ],
    );
  },
};
