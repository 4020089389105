import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class Device {
  userId: string;
  token: string;
  updatedAt?: StorageTimestamp;
  version?: string;
  deviceId?: string;

  constructor(
    userId: string,
    token: string,
    updatedAt?: StorageTimestamp,
    version?: string,
    deviceId?: string,
  ) {
    this.userId = userId;
    this.token = token;
    this.updatedAt = updatedAt;
    this.version = version;
    this.deviceId = deviceId;
  }
}

export const deviceDataConverter: FirestoreDataConverter<Device> = {
  toFirestoreModel: function (device: Device) {
    return {
      user_id: device.userId,
      token: device.token,
      updated_at: device.updatedAt,
      version: device.version,
      device_id: device.deviceId,
    };
  },

  fromFirestoreModel: function (data): Device {
    return new Device(
      data.user_id,
      data.token,
      data.updated_at,
      data.version,
      data.device_id,
    );
  },
};
