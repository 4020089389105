import { useEffect, useRef } from "react";

export default function useMessageListener<T>(
  origin: string | undefined,
  source: string | undefined,
  cb: (data: T) => void,
) {
  const cbRef = useRef(cb);
  useEffect(() => {
    if (!origin) return;
    if (!source) return;

    const handleMessage = (event: MessageEvent) => {
      if (event.origin === origin && event.data.source === source) {
        cbRef.current(event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [origin, source]);
}
