export function getBrowserDetails() {
  if (typeof window === "undefined") {
    return {
      isAndroid: false,
      isWebkit: false,
      isChrome: false,
      isIE: false,
      isFirefox: false,
      isSafari: false,
      isOpera: false,
    };
  }
  // Get the user-agent string
  const ua = navigator.userAgent;

  let chromeAgent = ua.indexOf("Chrome") > -1;
  const firefoxAgent = ua.indexOf("Firefox") > -1;
  let safariAgent = ua.indexOf("Safari") > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  const operaAgent = ua.indexOf("OP") > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;

  const androidAgent = /Android/i.test(ua);
  const webkitAgent = !chromeAgent && /WebKit/i.test(ua);

  return {
    isWebkit: webkitAgent,
    isAndroid: androidAgent,
    isChrome: chromeAgent,
    isFirefox: firefoxAgent,
    isSafari: safariAgent,
    isOpera: operaAgent,
  };
}

export const {
  isWebkit,
  isAndroid,
  isChrome,
  isIE,
  isFirefox,
  isSafari,
  isOpera,
} = getBrowserDetails();
