import { setWorkerValue } from "../worker-db";

export type NotificationPayloadData = {
  type?: string;
  link?: string;
};

export const handleNotificationPayload = (payload: NotificationPayloadData) => {
  if (payload.type === "chat_message") {
    const url = new URL(payload.link ?? "", self.location.href);
    const chatId = url.searchParams.get("chat");
    if (chatId) {
      console.log(
        "handleNotificationPayload match chat has new message",
        chatId,
      );
      void setWorkerValue(["chat", chatId, "hasNewMessage"], "1");
    }
  }
};
