import { TrackEvent, track } from "~/utils/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { handleNotificationPayload } from "~/utils/notification";
import { savePushToken } from "./user-api";
import { sleep } from "@sequoiacap/shared/utils";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Router from "next/router";
import getConfig from "next/config";
import useLightUserInfo from "./useLightUserInfo";
import useMemoCompare from "~/hooks/useMemoCompare";
import useServiceWorker from "../hooks/useServiceWorker";

const { publicRuntimeConfig } = getConfig();
const { NOTIFICATION_KEY } = publicRuntimeConfig;

export interface FCMContextType {
  token?: string;
  registration?: ServiceWorkerRegistration;
  notificationStatus?: NotificationPermission;
  notifications?: object[];
  enableNotifications: () => Promise<void>;
}

const FCMContext = createContext<FCMContextType | null>(null);

export default function useFCM(): FCMContextType {
  const context = useContext(FCMContext);
  if (!context) {
    throw new Error("useFCM must be used within a FCMProvider");
  }
  return context;
}

export const FCMProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { userId, isWritableUser } = useLightUserInfo();
  const [token, setToken] = useState<string | undefined>();
  const { registration } = useServiceWorker();
  const [notificationStatus, setNotificationStatus] = useState<
    NotificationPermission | undefined
  >(() => {
    if ("Notification" in window) {
      return Notification.permission;
    }
    return undefined;
  });

  const uploadPushToken = useCallback(async () => {
    if (NOTIFICATION_KEY && notificationStatus === "granted") {
      const messaging = getMessaging();
      const fcmToken = await getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: NOTIFICATION_KEY,
      });
      console.log(
        `[fcm] uploadPushToken userId=${userId}, fcmToken=${fcmToken}`,
      );

      if (userId && fcmToken) {
        await savePushToken(userId, fcmToken);
        setToken(fcmToken);
      }
    }
  }, [notificationStatus, registration, userId]);

  const inIframe = !!window.frameElement;
  useEffect(() => {
    if (inIframe) {
      return;
    }
    if ("serviceWorker" in navigator) {
      console.log("[fcm] Add message event listner");
      const messageEventHandler = async (event: MessageEvent) => {
        console.log("[fcm] Received message event", event);
        if (event.data.messageType === "notification-clicked") {
          console.log("[fcm] Received notification-clicked event");
          const link: string | undefined =
            event.data.notification?.click_action ?? event.data.data.link;
          track(TrackEvent.notificationClick, {
            link,
            ...(event.data.data ?? {}),
          });
          if (link) {
            console.log("[fcm] Open link", link);
            if (Router.asPath === link) {
              console.log(`[fcm] already at the link=${link}, do nothing`);
            } else {
              console.log(`[fcm] change route link=${link}`);

              // Add a sleep to avoid the installed application hang if there is a confirm navigation dialog
              await sleep(500);
              await Router.push(link);
            }
          }
        }
      };
      navigator.serviceWorker.addEventListener("message", messageEventHandler);

      return () => {
        console.log("[fcm] Remove message event listner");
        navigator.serviceWorker.removeEventListener(
          "message",
          messageEventHandler,
        );
      };
    }
  }, [inIframe]);

  useEffect(() => {
    if (!registration) {
      console.log("[fcm] No registration");
      return;
    }
    if (!token) {
      console.log("[fcm] No token");
      return;
    }
    console.log("[fcm] listening foreground message");
    const unsub = onMessage(getMessaging(), (payload) => {
      console.log("[fcm] Received foreground message ", payload);
      if (payload.data) {
        handleNotificationPayload(payload.data);
      }
    });
    return () => {
      console.log("[fcm] stop listening foreground message");
      unsub();
    };
  }, [registration, token]);

  useEffect(() => {
    if (inIframe) {
      return;
    }
    if (!isWritableUser) {
      return;
    }
    if (notificationStatus === "granted" && registration) {
      void uploadPushToken();
    }
  }, [
    inIframe,
    notificationStatus,
    registration,
    uploadPushToken,
    isWritableUser,
  ]);

  const enableNotifications = useCallback(async () => {
    if (!registration) {
      console.log("[fcm] No registration");
      return;
    }
    if (!("Notification" in window)) {
      console.log("[fcm] No notification");
      return;
    }
    try {
      const status = await Notification.requestPermission();
      console.log("[fcm] get status " + status);
      setNotificationStatus(status);
    } catch (error) {
      console.error(error);
      return;
    }
  }, [registration]);

  const newContext = (): FCMContextType => {
    return {
      registration,
      enableNotifications,
      notificationStatus,
      token,
    };
  };

  const context = useMemoCompare<FCMContextType>(newContext());

  return <FCMContext.Provider value={context}>{children}</FCMContext.Provider>;
};
