import FirestoreDataConverter from "./base/data-converter";

export class SimilarDeal {
  url: string;
  smsIds: string[];
  dealIds: string[];
  cofounderLinkedInUsernames: string[];
  names: string[];
  hiddenSmsIds?: string[] = [];

  constructor(
    url: string,
    smsIds: string[],
    dealIds: string[],
    cofounderLinkedInUsernames: string[],
    names: string[],
  ) {
    this.url = url;
    this.smsIds = smsIds;
    this.dealIds = dealIds;
    this.cofounderLinkedInUsernames = cofounderLinkedInUsernames;
    this.names = names;
  }
}

export const similarDealDataConverter: FirestoreDataConverter<SimilarDeal> = {
  toFirestoreModel: function (rateLmit: SimilarDeal) {
    return {
      url: rateLmit.url,
      sms_id: rateLmit.smsIds,
      deal_id: rateLmit.dealIds,
      cofounder_linkedin_username: rateLmit.cofounderLinkedInUsernames,
      name: rateLmit.names,
    };
  },
  fromFirestoreModel: function (data): SimilarDeal {
    return new SimilarDeal(
      data.url,
      data.sms_id,
      data.deal_id,
      data.cofounder_linkedin_username,
      data.name,
    );
  },
};
