import { createContext, useContext, useEffect, useState } from "react";

interface NetworkInformation extends EventTarget {
  rtt?: number;
  type?: string;
  saveData?: boolean;
  downLink?: number;
  downLinkMax?: number;
  effectiveType?: string;
}

type NetworkInfo = {
  online: boolean;
  since: string | undefined;
  rtt?: number;
  type?: string;
  saveData?: boolean;
  downLink?: number;
  downLinkMax?: number;
  effectiveType?: string;
};

const NetworkInfoContext = createContext<NetworkInfo | null>(null);

export default function useNetworkInfo(): NetworkInfo {
  const context = useContext(NetworkInfoContext);
  if (!context) {
    throw new Error("useNetworkInfo must be used within a NetworkInfoProvider");
  }
  return context;
}

export const NetworkInfoProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [state, setState] = useState<NetworkInfo>(() => {
    if (typeof navigator === "undefined") {
      return { online: false, since: undefined };
    }
    return {
      since: undefined,
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });

  useEffect(() => {
    const connection = getNetworkConnection();

    const handleOnline = () => {
      setState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };

    const handleOffline = () => {
      setState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };

    const handleConnectionChange = () => {
      setState((prevState) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    connection?.addEventListener("change", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      connection?.removeEventListener("change", handleConnectionChange);
    };
  }, []);
  return (
    <NetworkInfoContext.Provider value={state}>
      {children}
    </NetworkInfoContext.Provider>
  );
};

function getNetworkConnection() {
  if (typeof navigator === "undefined") {
    return null;
  }

  const nav = navigator as unknown as {
    connection?: NetworkInformation;
    mozConnection?: NetworkInformation;
    webkitConnection?: NetworkInformation;
  };

  return nav.connection || nav.mozConnection || nav.webkitConnection || null;
}

function getNetworkConnectionInfo(): Partial<NetworkInfo> {
  const connection = getNetworkConnection();

  if (!connection) {
    return {};
  }

  return {
    rtt: connection.rtt,
    type: connection.type,
    saveData: connection.saveData,
    downLink: connection.downLink,
    downLinkMax: connection.downLinkMax,
    effectiveType: connection.effectiveType,
  };
}
