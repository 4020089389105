import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

/** Keep in sync with iOS */
export enum PromptType {
  standard = "standard",

  /**
   * DEPRECATED
   * Use `experienceHub`
   *
   * Link to the latest arc. Only appears if the current user has an arc to link to.
   */
  latestArc = "latest_arc",

  /**
   * DEPRECATED
   * Use `experienceHub`.
   *
   * Link to the latest basecamp. Only appears if the current user is part of basecamp.
   */
  latestBaseCamp = "latest_base_camp",

  /** See ExperienceHubType. Some of the fields are computed in the backend, using values from the latest Hub this person can see */
  experienceHub = "experience_hub",

  /** Shown to those who can submit a memo */
  canSubmitOpportunity = "can_submit_opportunity",

  unknown = "unknown",
}

export enum PromptImageType {
  none = "none",
  custom = "custom",
  profile = "profile",
  unknown = "unknown",
}

export enum PromptState {
  disabled = "disabled",
  ready = "ready",
  fulfilled = "fulfilled",
  unknown = "unknown",
}

/**
 * Keep in sync with iOS
 *
 * Stored in `metadata.experienceHubType`
 */
export enum ExperienceHubType {
  arc = "arc",
  baseCamp = "base-camp",
  scoutCamp = "scout-camp",
  unknown = "unknown",
}

// export const PromptSubjectAllowedTags = new Set(["b", "em", "i", "br"]);

export class PromptBox {
  id: string;
  type: PromptType;
  imageType: PromptImageType;
  state: PromptState;
  createdAt: StorageTimestamp;
  visibility: string;
  userListId?: string;
  promptTitle?: string;
  promptSubject?: string;
  actionText?: string;
  actionLink?: string;
  profileId?: string;
  sortOrder?: number;
  startAt?: Date;
  endAt?: Date;
  updatedAt?: StorageTimestamp;
  metadata?: { [key: string]: any };

  constructor(
    id: string,
    type: PromptType,
    imageType: PromptImageType,
    state: PromptState,
    createdAt: StorageTimestamp,
    visibility: string,
    userListId?: string,
    promptTitle?: string,
    promptSubject?: string,
    actionText?: string,
    actionLink?: string,
    profileId?: string,
    sortOrder?: number,
    startAt?: Date,
    endAt?: Date,
    updatedAt?: StorageTimestamp,
    metadata?: { [key: string]: any },
  ) {
    this.id = id;
    this.type = type;
    this.imageType = imageType;
    this.state = state;
    this.createdAt = createdAt;
    this.visibility = visibility;
    this.userListId = userListId;
    this.promptTitle = promptTitle;
    this.promptSubject = promptSubject;
    this.actionText = actionText;
    this.actionLink = actionLink;
    this.profileId = profileId;
    this.sortOrder = sortOrder;
    this.startAt = startAt;
    this.endAt = endAt;
    this.updatedAt = updatedAt;
    this.metadata = metadata;
  }

  /**
   * Temporary value while editing
   */
  customImageURL?: URL;
}

export const promptBoxConverter: FirestoreDataConverter<PromptBox> = {
  toFirestoreModel: function (promptBox: PromptBox) {
    return {
      id: promptBox.id,
      type: promptBox.type,
      image_type: promptBox.imageType,
      state: promptBox.state,
      created_at: promptBox.createdAt,
      visibility: promptBox.visibility,
      user_list_id: promptBox.userListId,
      prompt_title: promptBox.promptTitle,
      prompt_subject: promptBox.promptSubject,
      action_text: promptBox.actionText,
      action_link: promptBox.actionLink,
      profile_id: promptBox.profileId,
      sort_order: promptBox.sortOrder,
      start_at: promptBox.startAt,
      end_at: promptBox.endAt,
      updated_at: promptBox.updatedAt,
      metadata: promptBox.metadata,
    };
  },
  fromFirestoreModel: function (data): PromptBox {
    return new PromptBox(
      data.id,
      PromptType[
        getEnumKeyByEnumValue(PromptType, data.type) ?? PromptType.unknown
      ],
      PromptImageType[
        getEnumKeyByEnumValue(PromptImageType, data.image_type) ??
          PromptImageType.unknown
      ],
      PromptState[
        getEnumKeyByEnumValue(PromptState, data.state) ?? PromptState.unknown
      ],
      new Date(data.created_at), // created_at is a string if received from cloud function.
      data.visibility,
      data.user_list_id,
      data.prompt_title,
      data.prompt_subject,
      data.action_text,
      data.action_link,
      data.profile_id,
      data.sort_order || 0,
      data.start_at && new Date(data.start_at),
      data.end_at && new Date(data.end_at),
      data.updated_at && new Date(data.updated_at),
      data.metadata,
    );
  },
};
