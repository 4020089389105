import * as Models from "../models/index";
import { compressSync, decompressSync, strFromU8, strToU8 } from "fflate";
import { isEqual } from "lodash";
import superjson from "superjson";

// Register all models so that they can be serialized
type Class = Parameters<superjson["registerClass"]>[0];
Object.entries(Models).forEach(([key, value]) => {
  if (typeof value === "function" && value.prototype.constructor === value) {
    superjson.registerClass(value as Class, `ampersand-${key}`);
  }
});

export function stringifyJSON<T>(
  obj: T,
  options: {
    minify?: boolean;
    compressed?: boolean;
  } = {},
) {
  const { minify = true, compressed = true } = options;
  let { json, meta } = superjson.serialize(obj);
  if (minify) {
    json = filterUndefined(json);
    meta = filterUndefinedArray(meta);
  }
  if (compressed) {
    return JSON.stringify({
      cjson: compressString(JSON.stringify(json)),
      cmeta: compressString(JSON.stringify(meta)),
      c: true,
    });
  }
  return JSON.stringify({ json, meta });
}

export function parseJSON<T>(str: string) {
  const { c, ...obj } = JSON.parse(str);
  let json;
  let meta;
  if (c) {
    const decompressedJson = decompressString(obj.cjson);
    json = decompressedJson ? JSON.parse(decompressedJson) : undefined;
    const decompressedMeta = decompressString(obj.cmeta);
    meta = decompressedMeta ? JSON.parse(decompressedMeta) : undefined;
    return superjson.deserialize<T>({ json, meta });
  }
  return superjson.deserialize<T>(obj);
}

function compressString(str: string) {
  const binary = compressSync(strToU8(str));
  return Buffer.from(binary).toString("base64");
}

function decompressString(str: string) {
  const binrary = Uint8Array.from(Buffer.from(str, "base64"));
  return strFromU8(decompressSync(binrary));
}

function filterUndefined<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj
      .map((item) => filterUndefined(item))
      .filter((item) => item !== null) as T;
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [key, filterUndefined(value)])
        .filter(([, value]) => value !== null),
    ) as T;
  }

  return obj;
}

function filterUndefinedArray<T>(obj: T): T | undefined {
  if (isEqual(obj, ["undefined"])) {
    return undefined;
  }
  if (Array.isArray(obj)) {
    return obj
      .map((item) => filterUndefinedArray(item))
      .filter((item) => item !== undefined) as T;
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [key, filterUndefinedArray(value)])
        .filter(([, value]) => value !== undefined),
    ) as T;
  }

  return obj;
}
