import { StorageTimestamp } from "./base/data-type";
import { TopicHierarchy, topicHierarchyConverter } from "./topic-hierarchy";
import { User } from "./user";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum PostType {
  regular = "regular",
  poll = "poll",
  welcomeVideo = "welcome_video",
  unknown = "unknown",
}

export enum PostActionType {
  create = "create",
  comment = "comment",
  commentReply = "comment_reply",
}

export class Attachment {
  id: string;
  name: string;
  storagePath: string;
  type: string;
  url: string;
  width?: number;
  height?: number;

  constructor(
    id: string,
    name: string,
    storagePath: string,
    type: string,
    url: string,
    width?: number,
    height?: number,
  ) {
    this.id = id;
    this.name = name;
    this.storagePath = storagePath;
    this.type = type;
    this.url = url;
    this.width = width;
    this.height = height;
  }
}

export class Poll {
  options: string[];
  concludedAt: Date;
  numAnswers: number;
  state: string;

  constructor(
    options: [string],
    concludedAt: Date,
    numAnswers: number,
    state: string,
  ) {
    this.options = options;
    this.concludedAt = concludedAt;
    this.numAnswers = numAnswers;
    this.state = state;
  }
}

export class LastPostAction {
  actionType: PostActionType;
  createdById: string;
  createdForId?: string;

  constructor(
    actionType: PostActionType,
    createdById: string,
    createdForId?: string,
  ) {
    this.actionType = actionType;
    this.createdById = createdById;
    this.createdForId = createdForId;
  }
}

export class Post {
  readonly id: string;
  title: string;
  readonly groupId: string;
  readonly createdAt: StorageTimestamp;
  readonly createdById: string;
  postType?: PostType;
  body?: string;
  mention?: { [key: string]: { [key: string]: string } };
  mentionList?: string[];
  attachment?: { [key: string]: Attachment };
  updatedAt?: StorageTimestamp;
  numComments?: number;
  poll?: Poll;
  like?: { [key: string]: Date };
  helpful?: { [key: string]: Date };
  empathize?: { [key: string]: Date };
  view?: Array<string>;
  viewable?: Array<string>;
  hashtag?: Array<string>;
  sortOrderAt?: StorageTimestamp;
  attributes?: Record<string, string>;
  lastAction?: LastPostAction;
  topicHierarchy?: TopicHierarchy;
  pinnedOrder?: number;

  // memo
  hasMemo?: boolean;
  isDraft?: boolean;

  // populated data
  createdBy?: User;

  constructor(
    id: string,
    title: string,
    groupId: string,
    createdAt: StorageTimestamp,
    createdById: string,
    postType?: PostType,
    body?: string,
    mention?: { [key: string]: { [key: string]: string } },
    mentionList?: string[],
    attachment?: { [key: string]: Attachment },
    updatedAt?: StorageTimestamp,
    numComments?: number,
    poll?: Poll,
    like?: { [key: string]: any },
    helpful?: { [key: string]: any },
    empathize?: { [key: string]: any },
    view?: Array<string>,
    viewable?: Array<string>,
    hashtag?: Array<string>,
    sortOrderAt?: StorageTimestamp,
    hasMemo?: boolean,
    attributes?: Record<string, string>,
    lastAction?: LastPostAction,
    isDraft?: boolean,
    topicHierarchy?: TopicHierarchy,
    pinnedOrder?: number,
  ) {
    this.id = id;
    this.title = title;
    this.groupId = groupId;
    this.createdAt = createdAt;
    this.createdById = createdById;
    this.postType = postType;
    this.body = body;
    this.mention = mention;
    this.mentionList = mentionList;
    this.attachment = attachment;
    this.updatedAt = updatedAt;
    this.numComments = numComments;
    this.poll = poll;
    this.like = like;
    this.helpful = helpful;
    this.empathize = empathize;
    this.view = view;
    this.viewable = viewable;
    this.hashtag = hashtag;
    this.sortOrderAt = sortOrderAt;
    this.hasMemo = hasMemo;
    this.attributes = attributes;
    this.lastAction = lastAction;
    this.isDraft = isDraft;
    this.topicHierarchy = topicHierarchy;
    this.pinnedOrder = pinnedOrder;
  }
}

export function convertAttachmentToFirebaseModel(originalAttachments?: {
  [key: string]: Attachment;
}): Record<string, Record<string, string>> {
  const attachments: Record<string, Record<string, string>> = {};
  if (originalAttachments) {
    Object.keys(originalAttachments).forEach((key) => {
      attachments[key] = attachmentDataConverter.toFirestoreModel(
        originalAttachments[key],
      );
    });
  }
  return attachments;
}

export function convertAttachmentsToFirebaseModel(
  originalAttachments?: Attachment[],
): Record<string, string>[] {
  if (!originalAttachments) {
    return [];
  }
  return originalAttachments.map((a) => {
    return attachmentDataConverter.toFirestoreModel(a);
  });
}

export function convertAttachmentFromFirebaseModel(attachment?: any): {
  [key: string]: Attachment;
} {
  let attachments: Record<string, Attachment> = {};
  if (attachment) {
    attachments = {};
    Object.keys(attachment).forEach((key) => {
      const value = attachmentDataConverter.fromFirestoreModel(attachment[key]);
      attachments[key] = value;
    });
  }
  return attachments;
}

export function convertAttachmenstFromFirebaseModel(
  attachment?: any,
): Attachment[] {
  if (!attachment) {
    return [];
  }
  return attachment.map((a: any) => {
    return attachmentDataConverter.fromFirestoreModel(a);
  });
}

export const postConverter: FirestoreDataConverter<Post> = {
  toFirestoreModel: function (post: Post) {
    return {
      id: post.id,
      title: post.title,
      group_id: post.groupId,
      created_at: post.createdAt,
      created_by_id: post.createdById,
      post_type: post.postType,
      body: post.body,
      mention: post.mention,
      mention_list: post.mentionList,
      attachment: convertAttachmentToFirebaseModel(post.attachment),
      updated_at: post.updatedAt,
      num_comments: post.numComments,
      poll: post.poll && pollConverter.toFirestoreModel(post.poll),
      like: post.like,
      helpful: post.helpful,
      empathize: post.empathize,
      view: post.view,
      viewable: post.viewable,
      hashtag: post.hashtag,
      sort_order_at: post.sortOrderAt,
      has_memo: post.hasMemo,
      attributes: post.attributes,
      last_action:
        post.lastAction &&
        lastPostActionConverter.toFirestoreModel(post.lastAction),
      is_draft: post.isDraft,
      topic_hierarchy:
        post.topicHierarchy &&
        topicHierarchyConverter.toFirestoreModel(post.topicHierarchy),
      pinned_order: post.pinnedOrder,
    };
  },
  fromFirestoreModel: function (data): Post {
    let poll: Poll | undefined = undefined;
    if (data.poll) {
      poll = pollConverter.fromFirestoreModel(data.poll);
    }
    let lastAction: LastPostAction | undefined = undefined;
    if (data.last_action) {
      lastAction = lastPostActionConverter.fromFirestoreModel(data.last_action);
    }
    let topicHierarchy: TopicHierarchy | undefined = undefined;
    if (data.topic_hierarchy) {
      topicHierarchy = topicHierarchyConverter.fromFirestoreModel(
        data.topic_hierarchy,
      );
    }
    return new Post(
      data.id,
      data.title,
      data.group_id,
      data.created_at,
      data.created_by_id,
      PostType[
        getEnumKeyByEnumValue(PostType, data.post_type) ?? PostType.unknown
      ],
      data.body,
      data.mention,
      data.mention_list,
      convertAttachmentFromFirebaseModel(data.attachment),
      data.updated_at,
      data.num_comments,
      poll,
      data.like,
      data.helpful,
      data.empathize,
      data.view,
      data.viewable,
      data.hashtag,
      data.sort_order_at,
      data.has_memo,
      data.attributes,
      lastAction,
      data.is_draft,
      topicHierarchy,
      data.pinned_order,
    );
  },
};

const pollConverter: FirestoreDataConverter<Poll> = {
  toFirestoreModel: function (poll: Poll) {
    return {
      options: poll.options,
      concluded_at: poll.concludedAt,
      num_answers: poll.numAnswers,
      state: poll.state,
    };
  },
  fromFirestoreModel: function (data): Poll {
    return new Poll(
      data.options,
      data.concluded_at,
      data.num_answers,
      data.state,
    );
  },
};

const lastPostActionConverter: FirestoreDataConverter<LastPostAction> = {
  toFirestoreModel: function (lastPostAction: LastPostAction) {
    return {
      action_type: lastPostAction.actionType,
      created_by_id: lastPostAction.createdById,
      created_for_id: lastPostAction.createdForId,
    };
  },
  fromFirestoreModel: function (data): LastPostAction {
    return new LastPostAction(
      data.action_type,
      data.created_by_id,
      data.created_for_id,
    );
  },
};

const attachmentDataConverter: FirestoreDataConverter<Attachment> = {
  toFirestoreModel: function (attachment: Attachment) {
    return {
      id: attachment.id,
      name: attachment.name,
      storage_path: attachment.storagePath,
      type: attachment.type,
      url: attachment.url,
      width: attachment.width,
      height: attachment.height,
    };
  },
  fromFirestoreModel: function (data): Attachment {
    return new Attachment(
      data.id,
      data.name,
      data.storage_path,
      data.type,
      data.url,
      data.width,
      data.height,
    );
  },
};
