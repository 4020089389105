export function getLocalStorageValue<T>(key: string, defaultValue: T): T {
  if (!(typeof window === "undefined")) {
    try {
      const value = window.localStorage.getItem(key);
      return value ? JSON.parse(value) : defaultValue;
    } catch (error) {
      console.log("getLocatStorageValue/error", error);
    }
  }
  return defaultValue;
}

export function setLocalStorageValue<T>(key: string, value: T) {
  try {
    if (!(typeof window === "undefined")) {
      if (value === undefined) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    }
  } catch (error) {
    console.log("setLocalStorageValue/error", error);
  }
}
