import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum AdminPushStatus {
  noDevice = "no device",
  sent = "sent",
  failed = "failed",
  unknown = "unknown",
}

export class AdminPush {
  id: string;
  createdById: string;
  recipientId: string;
  message: string;
  title?: string;
  deeplink?: string;
  sentAt?: StorageTimestamp;
  status: AdminPushStatus;
  dataHash?: string;

  constructor(
    id: string,
    createdById: string,
    recipientId: string,
    message: string,
    title?: string,
    deeplink?: string,
    sentAt?: StorageTimestamp,
    status: AdminPushStatus = AdminPushStatus.unknown,
    dataHash?: string,
  ) {
    this.id = id;
    this.createdById = createdById;
    this.recipientId = recipientId;
    this.message = message;
    this.title = title;
    this.deeplink = deeplink;
    this.sentAt = sentAt;
    this.status = status;
    this.dataHash = dataHash;
  }
}

export const adminPushConverter: FirestoreDataConverter<AdminPush> = {
  toFirestoreModel: function (adminPush: AdminPush) {
    return {
      id: adminPush.id,
      created_by_id: adminPush.createdById,
      recipient_id: adminPush.recipientId,
      message: adminPush.message,
      title: adminPush.title,
      deeplink: adminPush.deeplink,
      sent_at: adminPush.sentAt,
      status: adminPush.status,
      data_hash: adminPush.dataHash,
    };
  },
  fromFirestoreModel: function (data): AdminPush {
    return new AdminPush(
      data.id,
      data.created_by_id,
      data.recipient_id,
      data.message,
      data.title,
      data.deeplink,
      data.sent_at,
      AdminPushStatus[
        getEnumKeyByEnumValue(AdminPushStatus, data.status) ??
          AdminPushStatus.unknown
      ],
      data.data_hash,
    );
  },
};
