import {
  Attachment,
  convertAttachmentFromFirebaseModel,
  convertAttachmentToFirebaseModel,
} from "./post";
import { StorageTimestamp } from "./base/data-type";
import { User } from "./user";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum ParentType {
  comment = "comment",
  post = "post",
  unknown = "unknown",
}

export class ParentEntity {
  id: string;
  type: ParentType;

  get encodedId(): string {
    return this.type + ":" + this.id;
  }

  constructor(id: string, type: ParentType) {
    this.id = id;
    this.type = type;
  }
}

export class Comment {
  readonly id: string;
  readonly postId: string;
  readonly parentId: ParentEntity;
  body: string;
  readonly createdAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;
  readonly createdById: string;
  numComments?: number;
  like?: { [key: string]: Date };
  helpful?: { [key: string]: Date };
  empathize?: { [key: string]: Date };
  hashtag?: Array<string>;
  mention?: { [key: string]: Record<string, string> | undefined };
  attachment?: { [key: string]: Attachment };

  // populated data
  createdBy?: User;

  // special flag only used in server side to write, DO NOT USE THIS IN CLIENT/ADMIN SIDE
  skip_mention_notification?: boolean;

  constructor(
    id: string,
    postId: string,
    parentId: ParentEntity,
    body: string,
    createdAt: StorageTimestamp,
    createdById: string,
    updatedAt?: StorageTimestamp,
    numComments?: number,
    like?: { [key: string]: Date },
    helpful?: { [key: string]: Date },
    empathize?: { [key: string]: Date },
    hashtag?: Array<string>,
    mention?: { [key: string]: Record<string, string> | undefined },
    attachment?: { [key: string]: Attachment },
  ) {
    this.id = id;
    this.postId = postId;
    this.parentId = parentId;
    this.body = body;
    this.createdAt = createdAt;
    this.createdById = createdById;
    this.updatedAt = updatedAt;
    this.numComments = numComments;
    this.like = like;
    this.helpful = helpful;
    this.empathize = empathize;
    this.hashtag = hashtag;
    this.mention = mention;
    this.attachment = attachment;
  }
}

export const commentConverter: FirestoreDataConverter<Comment> = {
  toFirestoreModel: function (comment: Comment) {
    return {
      id: comment.id,
      post_id: comment.postId,
      parent_id: comment.parentId.encodedId,
      body: comment.body,
      created_at: comment.createdAt,
      created_by_id: comment.createdById,
      updated_at: comment.updatedAt,
      num_comments: comment.numComments,
      like: comment.like,
      helpful: comment.helpful,
      empathize: comment.empathize,
      hashtag: comment.hashtag,
      mention: comment.mention,
      attachment: convertAttachmentToFirebaseModel(comment.attachment),
      skip_mention_notification: comment.skip_mention_notification,
    };
  },
  fromFirestoreModel: function (data): Comment {
    const parentIdParts = data.parent_id.split(":");
    const parentId = new ParentEntity(
      parentIdParts[1],
      ParentType[
        getEnumKeyByEnumValue(ParentType, parentIdParts[0]) ??
          ParentType.unknown
      ],
    );
    return new Comment(
      data.id,
      data.post_id,
      parentId,
      data.body,
      data.created_at,
      data.created_by_id,
      data.updated_at,
      data.num_comments,
      data.like,
      data.helpful,
      data.empathize,
      data.hashtag,
      data.mention,
      convertAttachmentFromFirebaseModel(data.attachment),
    );
  },
};
