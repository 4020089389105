export enum ResizedImageSize {
  Square100 = 100,
  Square200 = 200,
  Square400 = 400,
  Square600 = 600,
  Square800 = 800,
  Square1600 = 1600,
  Original = 0, // 0 = Original
}

/**
 *
 */
export function resizedImageStoragePath(
  currentPath: string | null | undefined,
  newSize: ResizedImageSize,
) {
  if (!currentPath) {
    return currentPath || undefined;
  }

  /** Image types that are not resizeable (e.g. svg) should not be rewritten */
  if (!currentPath.match(/\.(jpg|jpeg|png|tiff|webp|gif)$/)) {
    return currentPath;
  }

  /** null if the currentPath is the original path */
  const matches = currentPath.match(
    /\/s\/[^/]*_([0-9]+)x([0-9]+)\.(jpg|jpeg|png|tiff|webp|gif)$/i,
  );
  const isOriginal = !matches;

  if (isOriginal) {
    if (newSize === ResizedImageSize.Original) {
      // noop
      return currentPath;
    } else {
      // Currently original. Return the resized path (/s/ and the size)
      return currentPath.replace(
        /([^/]+)(\.[^/.]+)$/i,
        `s/$1_${newSize}x${newSize}$2`,
      );
    }
  } else {
    if (newSize === ResizedImageSize.Original) {
      // Currently resized. Strip the /s/ and the size
      return currentPath
        .replace(/\/s\/([^/]+)$/i, "/$1")
        .replace(/_[0-9]+x[0-9]+(\.[^/]+)$/i, "$1");
    } else {
      // Currently resized. Return the newly desired resized path
      return currentPath.replace(
        /(_[0-9]+x[0-9]+)(\.[^/]+)$/i,
        `_${newSize}x${newSize}$2`,
      );
    }
  }
}
// Screen Shot 2021-12-08 at 7.25.40 PM.png
// Screen Shot 2021-12-08 at 7.25.40 PM_400x400.png
// const s = "user/08B6ZzsxsZTl1byF8lYe1aj94TC2/s/profilePicture_100x100.jpg";
// const o = "user/08B6ZzsxsZTl1byF8lYe1aj94TC2/profilePicture.jpg";
// // const s2 =
// ("https://storage.googleapis.com/founder-dev-8f6f5.appspot.com/s/resource%2Farc%2Famerica-spring-2023%2Fs%2F211011_Sequoia-9010_400x400.JPG");
// const s2 =
//   "https://storage.googleapis.com/founder-dev-8f6f5.appspot.com/resource/arc/america-spring-2023/s/211011_Sequoia-9010_400x400.JPG";
// const o2 =
//   "https://storage.googleapis.com/founder-dev-8f6f5.appspot.com/resource%2Farc%2Famerica-spring-2023%2F211011_Sequoia-9010.JPG";

// console.log(
//   [
//     "resizedImageStoragePath test",
//     s,
//     resizedImageStoragePath(s, ResizedImageSize.Original),
//     resizedImageStoragePath(s, ResizedImageSize.Square400),
//   ].join("\n")
// );

// console.log(
//   [
//     "resizedImageStoragePath test",
//     o,
//     resizedImageStoragePath(o, ResizedImageSize.Original),
//     resizedImageStoragePath(o, ResizedImageSize.Square400),
//   ].join("\n")
// );

// console.log(
//   [
//     "resizedImageStoragePath test",
//     s2,
//     resizedImageStoragePath(s2, ResizedImageSize.Original),
//     resizedImageStoragePath(s2, ResizedImageSize.Square400),
//   ].join("\n")
// );

// console.log(
//   [
//     "resizedImageStoragePath test",
//     o2,
//     resizedImageStoragePath(o2, ResizedImageSize.Original),
//     resizedImageStoragePath(o2, ResizedImageSize.Square400),
//   ].join("\n")
// );
