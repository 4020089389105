import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class GroupMemberMember {
  id: string;
  name: string;
  role: string;
  title: string;
  joinedAt: StorageTimestamp;
  updatedAt: StorageTimestamp;

  constructor(
    id: string,
    name: string,
    role: string,
    title: string,
    joinedAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
  ) {
    this.id = id;
    this.name = name;
    this.role = role;
    this.title = title;
    this.joinedAt = joinedAt;
    this.updatedAt = updatedAt;
  }
}

export class GroupMember {
  id: string;
  privacy: "opened" | "searchable" | "secret";
  members: Record<string, GroupMemberMember>;
  updatedAt: StorageTimestamp;

  constructor(
    id: string,
    privacy: "opened" | "searchable" | "secret",
    members: Record<string, GroupMemberMember>,
    updatedAt: StorageTimestamp,
  ) {
    this.id = id;
    this.privacy = privacy;
    this.members = members;
    this.updatedAt = updatedAt;
  }
}

export const groupMemberConverter: FirestoreDataConverter<GroupMember> = {
  toFirestoreModel: function (group: GroupMember) {
    const member: Record<string, Record<string, any>> = {};
    Object.keys(group.members).forEach((uid) => {
      member[uid] = groupMemberMemberConverter.toFirestoreModel(
        group.members[uid],
      );
    });
    return {
      id: group.id,
      privacy: group.privacy,
      member: member,
      updated_at: group.updatedAt,
    };
  },
  fromFirestoreModel: function (data): GroupMember {
    const member: Record<string, GroupMemberMember> = {};
    Object.keys(data.member).forEach((uid) => {
      member[uid] = groupMemberMemberConverter.fromFirestoreModel(
        data.member[uid],
      );
    });
    return new GroupMember(data.id, data.privacy, member, data.updated_at);
  },
};

const groupMemberMemberConverter: FirestoreDataConverter<GroupMemberMember> = {
  toFirestoreModel: function (info: GroupMemberMember) {
    return {
      id: info.id,
      name: info.name,
      role: info.role,
      title: info.title,
      joined_at: info.joinedAt,
      updated_at: info.updatedAt,
    };
  },
  fromFirestoreModel: function (data): GroupMemberMember {
    return new GroupMemberMember(
      data.id,
      data.name,
      data.role,
      data.title,
      data.joined_at,
      data.updated_at,
    );
  },
};
