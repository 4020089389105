import FirestoreDataConverter from "./base/data-converter";

export class Vouch {
  airtableId: string; // See if we need to keep this.  Hopefully it goes away.
  name: string;
  profileId?: string; // Ampersand user ID
  image?: string;
  title?: string;
  quote?: string;

  constructor(
    airtableId: string,
    name: string,
    profileId?: string,
    image?: string,
    title?: string,
    quote?: string,
  ) {
    this.airtableId = airtableId;
    this.name = name;
    this.profileId = profileId;
    this.image = image;
    this.title = title;
    this.quote = quote;
  }
}

export const vouchDataConverter: FirestoreDataConverter<Vouch> = {
  toFirestoreModel: function (vouch: Vouch) {
    return {
      airtable_id: vouch.airtableId,
      name: vouch.name,
      profile_id: vouch.profileId,
      image: vouch.image,
      title: vouch.title,
      quote: vouch.quote,
    };
  },
  fromFirestoreModel: function (data): Vouch {
    return new Vouch(
      data.airtable_id,
      data.name,
      data.profile_id,
      data.image,
      data.title,
      data.quote,
    );
  },
};
