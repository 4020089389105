export type DueDiligenceCheckListFormData = {
  qualifiedBusiness: string;
  freeFromContractualEmploymentObligations: string;
  unrestrictedUseOfIP: string;
  notObligatedTo3rdParty: string;
  clearOfLitigation: string;
  usBaseCorp: string;
  treatedAsCorporation: string;
  noBoardSeat?: string;
  withinOwnershipThreshold?: string;
  countryOfLegalDomicile?: string;
  countryOfOperation?: string;
  industryOfCompany?: string;
  legalEntityType?: string;
  otherComments?: string;
};

export type QuestionKey = keyof DueDiligenceCheckListFormData;

export const FirstPartOfQuestions: QuestionKey[] = [
  "qualifiedBusiness",
  "freeFromContractualEmploymentObligations",
  "unrestrictedUseOfIP",
  "notObligatedTo3rdParty",
  "clearOfLitigation",
  "usBaseCorp",
];
export const NonUSQuestionsPart1: QuestionKey[] = [
  "treatedAsCorporation",
  "noBoardSeat",
  "withinOwnershipThreshold",
];
export const NonUSQuestionsPart2: QuestionKey[] = [
  "countryOfLegalDomicile",
  "legalEntityType",
  "countryOfOperation",
  "industryOfCompany",
];
export const LastPartOfQuestions: QuestionKey[] = ["otherComments"];

export const AllDueDiligenceQuestionsInOrder: QuestionKey[] = [
  ...FirstPartOfQuestions,
  ...NonUSQuestionsPart1,
  ...NonUSQuestionsPart2,
  ...LastPartOfQuestions,
];

export interface DueDiligenceQuestionDefinition {
  question: JSX.Element | string;
  type: "yesNo" | "text";
  answerYes?: string;
  placeholder?: string;
  required?: boolean;
}

// https://docs.google.com/document/d/1NsIHfAAwxf0DAqhwFR12lIOERXKAD8MlbJlJBW4yGxs/edit#
export const DueDiligenceQuestionDefinitions: Record<
  QuestionKey,
  DueDiligenceQuestionDefinition
> = {
  qualifiedBusiness: {
    type: "yesNo",
    question: (
      <>
        Is the company a qualified business to invest in?
        <br />
        <i>
          (i.e.in line with the goals of the Sequoia Scout Program as described
          in the{" "}
          <a
            className={"lit"}
            href="https://sequoiacap.docsend.com/view/72f83bxug8u3ydkn"
            target="_blank"
            rel="noreferrer"
          >
            Due Diligence
          </a>{" "}
          section of the Program Guide)?
        </i>
      </>
    ),
    required: true,
  },
  freeFromContractualEmploymentObligations: {
    type: "yesNo",
    question: (
      <>
        Is the company free from contractual employment obligations ?
        <br />
        <i>
          (i.e.has formally resigned from employment, does not have any
          outstanding obligations to a former employer, IP not worked on during
          employment etc.) ?
        </i>
      </>
    ),
    required: true,
  },
  unrestrictedUseOfIP: {
    type: "yesNo",
    question: (
      <>
        Is the company unrestricted in the use of the intellectual property ?
        <br />
        <i>
          (i.e.the company does not rely on external IP in its core business and
          ownership of IP is not in dispute)
        </i>
      </>
    ),
    required: true,
  },
  notObligatedTo3rdParty: {
    type: "yesNo",
    question: (
      <>
        Is the company free from obligations to third parties ?
        <br />
        <i>
          (e.g.a former employer, other investors, current or former employees
          or former business partners) ?
        </i>
      </>
    ),
    required: true,
  },
  clearOfLitigation: {
    type: "yesNo",
    question: "Is the company clear of litigation issues?",
    required: true,
  },
  usBaseCorp: {
    type: "yesNo",
    question: "Is the company a US-based Corporation?",
    required: true,
  },
  treatedAsCorporation: {
    type: "yesNo",
    question: (
      <>
        The company is a Corporation(or Limited Company) and has made no
        elections to be treated as a tax - transparent entity(i.e.a flow -
        through).
      </>
    ),
    answerYes: "Yes, I confirm.",
    required: true,
  },
  noBoardSeat: {
    type: "yesNo",
    question: "Neither Sequoia nor the Scout will receive a board seat",
    answerYes: "Yes, I confirm.",
    required: true,
  },
  withinOwnershipThreshold: {
    type: "yesNo",
    question: (
      <>
        The holdings will be within the{" "}
        <a
          className={"lit"}
          href="https://sequoiacap.docsend.com/view/72f83bxug8u3ydkn"
          target="_blank"
          rel="noreferrer"
        >
          ownership thresholds
        </a>{" "}
        specified in the Program Guide
      </>
    ),
    answerYes: "Yes, I confirm.",
    required: true,
  },
  countryOfLegalDomicile: {
    type: "text",
    question: "What is the country of legal domicile?",
    required: true,
  },
  legalEntityType: {
    type: "text",
    question:
      "What is the legal entity type? (e.g. France SAS, Germany GbmH, UK Ltd.)",
    required: true,
  },
  countryOfOperation: {
    type: "text",
    question:
      "What is the principal country/countries of operations (if different from domicile)?  [Optional]",
  },
  industryOfCompany: {
    type: "text",
    question: "What is the industry/sector of the company?",
    required: true,
  },
  otherComments: {
    type: "text",
    question: "Any other comments or questions?",
  },
};
