import {
  FirebaseApp,
  //  setLogLevel
} from "firebase/app";
import {
  Firestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  // setLogLevel,
} from "firebase/firestore";
import { TrackErrorEvent, trackError } from "~/utils/analytics";

export class Fuego {
  public db: Firestore;

  constructor(app: FirebaseApp) {
    try {
      this.db = initializeFirestore(app, {
        experimentalAutoDetectLongPolling: true,
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
      });
    } catch (err) {
      trackError(TrackErrorEvent.errorIndexedDbPersistence, err);
      this.db = initializeFirestore(app, {
        experimentalForceLongPolling: true,
      });
    }
  }
}
