import { useCallback } from "react";

export function useOncePerSession(name: string, n: number = 1) {
  const numTimesDone = Number(window.sessionStorage?.getItem(name)) || 0;
  const doneEnough = numTimesDone >= n;

  const inc = useCallback(() => {
    window.sessionStorage?.setItem(name, `${numTimesDone + 1}`);
  }, [numTimesDone, name]);

  const clear = useCallback(() => {
    window.sessionStorage?.removeItem(name);
  }, [name]);

  return { doneEnough, inc, clear, numTimesDone } as const;
}
