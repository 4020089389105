import { Workbox } from "workbox-window";

// import { WorkboxInvocation } from "~/worker";

// export type WorkboxWithFunctions = Workbox & {
//   invoke: (invocation: WorkboxInvocation) => Promise<{
//     data?: unknown;
//     error?: Error;
//   }>;
// };

/**
 */
export function getWorkbox(): Workbox | undefined {
  if (
    typeof window !== "undefined" &&
    "serviceWorker" in navigator &&
    window.workbox !== undefined
  ) {
    return window.workbox as Workbox;
  }
  return undefined;
}
