import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class BuiltFirstItem {
  id: string;
  name: string;
  slug: string;
  createdAt: StorageTimestamp;
  description?: string;
  logoUrl?: string; // this url is from BuiltFirst API, need to download and store in storage
  imageUrl?: string; // this url is pointing to firebase storage that we can use directly
  updatedAt?: StorageTimestamp;
  deleted?: boolean;

  constructor(
    id: string,
    name: string,
    slug: string,
    createdAt: StorageTimestamp,
    description?: string,
    logoUrl?: string,
    imageUrl?: string,
    updatedAt?: StorageTimestamp,
    deleted?: boolean,
  ) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.createdAt = createdAt;
    this.description = description;
    this.logoUrl = logoUrl;
    this.imageUrl = imageUrl;
    this.updatedAt = updatedAt;
    this.deleted = !!deleted;
  }
}

export const builtFirstItemConverter: FirestoreDataConverter<BuiltFirstItem> = {
  toFirestoreModel: function (item: BuiltFirstItem) {
    return {
      id: item.id,
      name: item.name,
      slug: item.slug,
      created_at: item.createdAt,
      description: item.description,
      logo_url: item.logoUrl,
      image_url: item.imageUrl,
      updated_at: item.updatedAt,
      deleted: item.deleted,
    };
  },
  fromFirestoreModel: function (data): BuiltFirstItem {
    return new BuiltFirstItem(
      data.id,
      data.name,
      data.slug,
      data.created_at,
      data.description,
      data.logo_url,
      data.image_url,
      data.updated_at,
      data.deleted,
    );
  },
};
