export { default as AmpersandIcon } from "./AmpersandIcon.mjs";
export { default as ActivityIcon } from "./ActivityIcon.mjs";
export { default as AddUserIcon } from "./AddUserIcon.mjs";
export { default as AlignLeftDecreaseIcon } from "./AlignLeftDecreaseIcon.mjs";
export { default as ArrowDownCircleIcon } from "./ArrowDownCircleIcon.mjs";
export { default as ArrowDownRightIcon } from "./ArrowDownRightIcon.mjs";
export { default as ArrowDownSquareIcon } from "./ArrowDownSquareIcon.mjs";
export { default as ArrowDownIcon } from "./ArrowDownIcon.mjs";
export { default as ArrowDown2Icon } from "./ArrowDown2Icon.mjs";
export { default as ArrowDown3Icon } from "./ArrowDown3Icon.mjs";
export { default as ArrowLeftCircleIcon } from "./ArrowLeftCircleIcon.mjs";
export { default as ArrowLeftSquareIcon } from "./ArrowLeftSquareIcon.mjs";
export { default as ArrowLeftIcon } from "./ArrowLeftIcon.mjs";
export { default as ArrowLeft2Icon } from "./ArrowLeft2Icon.mjs";
export { default as ArrowLeft3Icon } from "./ArrowLeft3Icon.mjs";
export { default as ArrowRightCircleIcon } from "./ArrowRightCircleIcon.mjs";
export { default as ArrowRightSquareIcon } from "./ArrowRightSquareIcon.mjs";
export { default as ArrowRightIcon } from "./ArrowRightIcon.mjs";
export { default as ArrowRight2Icon } from "./ArrowRight2Icon.mjs";
export { default as ArrowRight3Icon } from "./ArrowRight3Icon.mjs";
export { default as ArrowUpCircleIcon } from "./ArrowUpCircleIcon.mjs";
export { default as ArrowUpRightIcon } from "./ArrowUpRightIcon.mjs";
export { default as ArrowUpSquareIcon } from "./ArrowUpSquareIcon.mjs";
export { default as ArrowUpIcon } from "./ArrowUpIcon.mjs";
export { default as ArrowUp2Icon } from "./ArrowUp2Icon.mjs";
export { default as ArrowUp3Icon } from "./ArrowUp3Icon.mjs";
export { default as ArrowsDiagonalContractIcon } from "./ArrowsDiagonalContractIcon.mjs";
export { default as ArrowsDiagonalMaximizeAltIcon } from "./ArrowsDiagonalMaximizeAltIcon.mjs";
export { default as ArrowsAltIcon } from "./ArrowsAltIcon.mjs";
export { default as BagIcon } from "./BagIcon.mjs";
export { default as Bag3Icon } from "./Bag3Icon.mjs";
export { default as BoldIcon } from "./BoldIcon.mjs";
export { default as BookClosedIcon } from "./BookClosedIcon.mjs";
export { default as BookOpenIcon } from "./BookOpenIcon.mjs";
export { default as BookmarkIcon } from "./BookmarkIcon.mjs";
export { default as BuildingOfficeIcon } from "./BuildingOfficeIcon.mjs";
export { default as BuyIcon } from "./BuyIcon.mjs";
export { default as CalendarIcon } from "./CalendarIcon.mjs";
export { default as CallMissedIcon } from "./CallMissedIcon.mjs";
export { default as CallSilentIcon } from "./CallSilentIcon.mjs";
export { default as CallIcon } from "./CallIcon.mjs";
export { default as CallingIcon } from "./CallingIcon.mjs";
export { default as CameraIcon } from "./CameraIcon.mjs";
export { default as CapIcon } from "./CapIcon.mjs";
export { default as CategoryIcon } from "./CategoryIcon.mjs";
export { default as CenteredViewIcon } from "./CenteredViewIcon.mjs";
export { default as ChartIcon } from "./ChartIcon.mjs";
export { default as ChatTopicIcon } from "./ChatTopicIcon.mjs";
export { default as ChatIcon } from "./ChatIcon.mjs";
export { default as CheckIcon } from "./CheckIcon.mjs";
export { default as ChevronExpandVericalIcon } from "./ChevronExpandVericalIcon.mjs";
export { default as CircleCheckIcon } from "./CircleCheckIcon.mjs";
export { default as CircleCheckedIcon } from "./CircleCheckedIcon.mjs";
export { default as CircleCloseIcon } from "./CircleCloseIcon.mjs";
export { default as CloseRemoveIcon } from "./CloseRemoveIcon.mjs";
export { default as CloseSquareIcon } from "./CloseSquareIcon.mjs";
export { default as CloseIcon } from "./CloseIcon.mjs";
export { default as ColorBlueIcon } from "./ColorBlueIcon.mjs";
export { default as ColorGreenIcon } from "./ColorGreenIcon.mjs";
export { default as ColorGreyIcon } from "./ColorGreyIcon.mjs";
export { default as ColorOrangeIcon } from "./ColorOrangeIcon.mjs";
export { default as ColorPurpleIcon } from "./ColorPurpleIcon.mjs";
export { default as ColorRedIcon } from "./ColorRedIcon.mjs";
export { default as ColorYellowIcon } from "./ColorYellowIcon.mjs";
export { default as ColumnsIcon } from "./ColumnsIcon.mjs";
export { default as CopyIcon } from "./CopyIcon.mjs";
export { default as CounterClockwiseUndoIcon } from "./CounterClockwiseUndoIcon.mjs";
export { default as DangerCircleIcon } from "./DangerCircleIcon.mjs";
export { default as DangerTriangleIcon } from "./DangerTriangleIcon.mjs";
export { default as DeleteIcon } from "./DeleteIcon.mjs";
export { default as DeleteOutlinedIcon } from "./DeleteOutlinedIcon.mjs";
export { default as DiscountIcon } from "./DiscountIcon.mjs";
export { default as DiscoveryIcon } from "./DiscoveryIcon.mjs";
export { default as DocumentIcon } from "./DocumentIcon.mjs";
export { default as DollarSignInvestorIcon } from "./DollarSignInvestorIcon.mjs";
export { default as DoorOpenIcon } from "./DoorOpenIcon.mjs";
export { default as DownloadIcon } from "./DownloadIcon.mjs";
export { default as DragMenuVerticalIcon } from "./DragMenuVerticalIcon.mjs";
export { default as EarthIcon } from "./EarthIcon.mjs";
export { default as EditAltIcon } from "./EditAltIcon.mjs";
export { default as EditSquareIcon } from "./EditSquareIcon.mjs";
export { default as EditIcon } from "./EditIcon.mjs";
export { default as FileIcon } from "./FileIcon.mjs";
export { default as FilterIcon } from "./FilterIcon.mjs";
export { default as Filter3Icon } from "./Filter3Icon.mjs";
export { default as FolderIcon } from "./FolderIcon.mjs";
export { default as FontTypefaceIcon } from "./FontTypefaceIcon.mjs";
export { default as GameIcon } from "./GameIcon.mjs";
export { default as GarageIcon } from "./GarageIcon.mjs";
export { default as GitMergeIcon } from "./GitMergeIcon.mjs";
export { default as GraphIcon } from "./GraphIcon.mjs";
export { default as GridInterface_1Icon } from "./GridInterface_1Icon.mjs";
export { default as HashtagIcon } from "./HashtagIcon.mjs";
export { default as HeartIcon } from "./HeartIcon.mjs";
export { default as HideIcon } from "./HideIcon.mjs";
export { default as HomeIcon } from "./HomeIcon.mjs";
export { default as ImageIcon } from "./ImageIcon.mjs";
export { default as InfoCircleIcon } from "./InfoCircleIcon.mjs";
export { default as InfoSquareIcon } from "./InfoSquareIcon.mjs";
export { default as ItalicIcon } from "./ItalicIcon.mjs";
export { default as LibraryAddPlusIcon } from "./LibraryAddPlusIcon.mjs";
export { default as LinkUrlIcon } from "./LinkUrlIcon.mjs";
export { default as LinkIcon } from "./LinkIcon.mjs";
export { default as ListBulletedIcon } from "./ListBulletedIcon.mjs";
export { default as ListNumberedIcon } from "./ListNumberedIcon.mjs";
export { default as LocationIcon } from "./LocationIcon.mjs";
export { default as LockIcon } from "./LockIcon.mjs";
export { default as LoginIcon } from "./LoginIcon.mjs";
export { default as LogoutIcon } from "./LogoutIcon.mjs";
export { default as MenuIcon } from "./MenuIcon.mjs";
export { default as MessageIcon } from "./MessageIcon.mjs";
export { default as MinusPlainIcon } from "./MinusPlainIcon.mjs";
export { default as MoreCircleIcon } from "./MoreCircleIcon.mjs";
export { default as MoreHorizontalIcon } from "./MoreHorizontalIcon.mjs";
export { default as MoreSquareIcon } from "./MoreSquareIcon.mjs";
export { default as NotificationSelectedIcon } from "./NotificationSelectedIcon.mjs";
export { default as NotificationIcon } from "./NotificationIcon.mjs";
export { default as PaperDownloadIcon } from "./PaperDownloadIcon.mjs";
export { default as PaperFailIcon } from "./PaperFailIcon.mjs";
export { default as PaperNegativeIcon } from "./PaperNegativeIcon.mjs";
export { default as PaperPlusIcon } from "./PaperPlusIcon.mjs";
export { default as PaperUploadIcon } from "./PaperUploadIcon.mjs";
export { default as PaperIcon } from "./PaperIcon.mjs";
export { default as PaperClipIcon } from "./PaperClipIcon.mjs";
export { default as PasswordIcon } from "./PasswordIcon.mjs";
export { default as PinIcon } from "./PinIcon.mjs";
export { default as PlayIcon } from "./PlayIcon.mjs";
export { default as PlaylistCasetteIcon } from "./PlaylistCasetteIcon.mjs";
export { default as PlusPlainIcon } from "./PlusPlainIcon.mjs";
export { default as PlusIcon } from "./PlusIcon.mjs";
export { default as ProfileIcon } from "./ProfileIcon.mjs";
export { default as RedoIcon } from "./RedoIcon.mjs";
export { default as RefreshLeftIcon } from "./RefreshLeftIcon.mjs";
export { default as RefreshRightIcon } from "./RefreshRightIcon.mjs";
export { default as ReplyIcon } from "./ReplyIcon.mjs";
export { default as RetouchMagicWandIcon } from "./RetouchMagicWandIcon.mjs";
export { default as RightUpBoxIcon } from "./RightUpBoxIcon.mjs";
export { default as RingtoneAddIcon } from "./RingtoneAddIcon.mjs";
export { default as RingtoneCheckIcon } from "./RingtoneCheckIcon.mjs";
export { default as ScanIcon } from "./ScanIcon.mjs";
export { default as SearchThunderIcon } from "./SearchThunderIcon.mjs";
export { default as SearchIcon } from "./SearchIcon.mjs";
export { default as SendIcon } from "./SendIcon.mjs";
export { default as Send2Icon } from "./Send2Icon.mjs";
export { default as SequoiaIcon } from "./SequoiaIcon.mjs";
export { default as SettingIcon } from "./SettingIcon.mjs";
export { default as ShieldDoneIcon } from "./ShieldDoneIcon.mjs";
export { default as ShieldFailIcon } from "./ShieldFailIcon.mjs";
export { default as ShowIcon } from "./ShowIcon.mjs";
export { default as ShrinkIcon } from "./ShrinkIcon.mjs";
export { default as SideViewIcon } from "./SideViewIcon.mjs";
export { default as SlidersIcon } from "./SlidersIcon.mjs";
export { default as SmileyIcon } from "./SmileyIcon.mjs";
export { default as SnoozeIcon } from "./SnoozeIcon.mjs";
export { default as SocialGithubIcon } from "./SocialGithubIcon.mjs";
export { default as SocialInstagramIcon } from "./SocialInstagramIcon.mjs";
export { default as SocialLinkedinIcon } from "./SocialLinkedinIcon.mjs";
export { default as SocialXIcon } from "./SocialXIcon.mjs";
export { default as SortDownIcon } from "./SortDownIcon.mjs";
export { default as SortUpIcon } from "./SortUpIcon.mjs";
export { default as SparklingIcon } from "./SparklingIcon.mjs";
export { default as SproutIcon } from "./SproutIcon.mjs";
export { default as StageIcon } from "./StageIcon.mjs";
export { default as StarSelectedIcon } from "./StarSelectedIcon.mjs";
export { default as StarIcon } from "./StarIcon.mjs";
export { default as StrikethroughIcon } from "./StrikethroughIcon.mjs";
export { default as SwapIcon } from "./SwapIcon.mjs";
export { default as ThumbLike_2Icon } from "./ThumbLike_2Icon.mjs";
export { default as ThumbsDownIcon } from "./ThumbsDownIcon.mjs";
export { default as ThumbsUpIcon } from "./ThumbsUpIcon.mjs";
export { default as ThunderIcon } from "./ThunderIcon.mjs";
export { default as TickSquareIcon } from "./TickSquareIcon.mjs";
export { default as TicketStarIcon } from "./TicketStarIcon.mjs";
export { default as TicketIcon } from "./TicketIcon.mjs";
export { default as TimeCircleIcon } from "./TimeCircleIcon.mjs";
export { default as TimeSquareIcon } from "./TimeSquareIcon.mjs";
export { default as TopicIcon } from "./TopicIcon.mjs";
export { default as TrendUpIcon } from "./TrendUpIcon.mjs";
export { default as UnderlineIcon } from "./UnderlineIcon.mjs";
export { default as UndoIcon } from "./UndoIcon.mjs";
export { default as UnlockIcon } from "./UnlockIcon.mjs";
export { default as UploadIcon } from "./UploadIcon.mjs";
export { default as User2Icon } from "./User2Icon.mjs";
export { default as User3Icon } from "./User3Icon.mjs";
export { default as VideoIcon } from "./VideoIcon.mjs";
export { default as VoiceIcon } from "./VoiceIcon.mjs";
export { default as Voice3Icon } from "./Voice3Icon.mjs";
export { default as VolumeDownIcon } from "./VolumeDownIcon.mjs";
export { default as VolumeOffIcon } from "./VolumeOffIcon.mjs";
export { default as VolumeUpIcon } from "./VolumeUpIcon.mjs";
export { default as WalletIcon } from "./WalletIcon.mjs";
export { default as WorkIcon } from "./WorkIcon.mjs";
export { default as PhoneCallIcon } from "./PhoneCallIcon.mjs";
export { default as PhoneIcon } from "./PhoneIcon.mjs";
export { default as QuestionIcon } from "./QuestionIcon.mjs";
export { default as ToolWrenchIcon } from "./ToolWrenchIcon.mjs";
import AmpersandIcon from "./AmpersandIcon";
import ActivityIcon from "./ActivityIcon";
import AddUserIcon from "./AddUserIcon";
import AlignLeftDecreaseIcon from "./AlignLeftDecreaseIcon";
import ArrowDownCircleIcon from "./ArrowDownCircleIcon";
import ArrowDownRightIcon from "./ArrowDownRightIcon";
import ArrowDownSquareIcon from "./ArrowDownSquareIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import ArrowDown2Icon from "./ArrowDown2Icon";
import ArrowDown3Icon from "./ArrowDown3Icon";
import ArrowLeftCircleIcon from "./ArrowLeftCircleIcon";
import ArrowLeftSquareIcon from "./ArrowLeftSquareIcon";
import ArrowLeftIcon from "./ArrowLeftIcon";
import ArrowLeft2Icon from "./ArrowLeft2Icon";
import ArrowLeft3Icon from "./ArrowLeft3Icon";
import ArrowRightCircleIcon from "./ArrowRightCircleIcon";
import ArrowRightSquareIcon from "./ArrowRightSquareIcon";
import ArrowRightIcon from "./ArrowRightIcon";
import ArrowRight2Icon from "./ArrowRight2Icon";
import ArrowRight3Icon from "./ArrowRight3Icon";
import ArrowUpCircleIcon from "./ArrowUpCircleIcon";
import ArrowUpRightIcon from "./ArrowUpRightIcon";
import ArrowUpSquareIcon from "./ArrowUpSquareIcon";
import ArrowUpIcon from "./ArrowUpIcon";
import ArrowUp2Icon from "./ArrowUp2Icon";
import ArrowUp3Icon from "./ArrowUp3Icon";
import ArrowsDiagonalContractIcon from "./ArrowsDiagonalContractIcon";
import ArrowsDiagonalMaximizeAltIcon from "./ArrowsDiagonalMaximizeAltIcon";
import ArrowsAltIcon from "./ArrowsAltIcon";
import BagIcon from "./BagIcon";
import Bag3Icon from "./Bag3Icon";
import BoldIcon from "./BoldIcon";
import BookClosedIcon from "./BookClosedIcon";
import BookOpenIcon from "./BookOpenIcon";
import BookmarkIcon from "./BookmarkIcon";
import BuildingOfficeIcon from "./BuildingOfficeIcon";
import BuyIcon from "./BuyIcon";
import CalendarIcon from "./CalendarIcon";
import CallMissedIcon from "./CallMissedIcon";
import CallSilentIcon from "./CallSilentIcon";
import CallIcon from "./CallIcon";
import CallingIcon from "./CallingIcon";
import CameraIcon from "./CameraIcon";
import CapIcon from "./CapIcon";
import CategoryIcon from "./CategoryIcon";
import CenteredViewIcon from "./CenteredViewIcon";
import ChartIcon from "./ChartIcon";
import ChatTopicIcon from "./ChatTopicIcon";
import ChatIcon from "./ChatIcon";
import CheckIcon from "./CheckIcon";
import ChevronExpandVericalIcon from "./ChevronExpandVericalIcon";
import CircleCheckIcon from "./CircleCheckIcon";
import CircleCheckedIcon from "./CircleCheckedIcon";
import CircleCloseIcon from "./CircleCloseIcon";
import CloseRemoveIcon from "./CloseRemoveIcon";
import CloseSquareIcon from "./CloseSquareIcon";
import CloseIcon from "./CloseIcon";
import ColorBlueIcon from "./ColorBlueIcon";
import ColorGreenIcon from "./ColorGreenIcon";
import ColorGreyIcon from "./ColorGreyIcon";
import ColorOrangeIcon from "./ColorOrangeIcon";
import ColorPurpleIcon from "./ColorPurpleIcon";
import ColorRedIcon from "./ColorRedIcon";
import ColorYellowIcon from "./ColorYellowIcon";
import ColumnsIcon from "./ColumnsIcon";
import CopyIcon from "./CopyIcon";
import CounterClockwiseUndoIcon from "./CounterClockwiseUndoIcon";
import DangerCircleIcon from "./DangerCircleIcon";
import DangerTriangleIcon from "./DangerTriangleIcon";
import DeleteIcon from "./DeleteIcon";
import DeleteOutlinedIcon from "./DeleteOutlinedIcon";
import DiscountIcon from "./DiscountIcon";
import DiscoveryIcon from "./DiscoveryIcon";
import DocumentIcon from "./DocumentIcon";
import DollarSignInvestorIcon from "./DollarSignInvestorIcon";
import DoorOpenIcon from "./DoorOpenIcon";
import DownloadIcon from "./DownloadIcon";
import DragMenuVerticalIcon from "./DragMenuVerticalIcon";
import EarthIcon from "./EarthIcon";
import EditAltIcon from "./EditAltIcon";
import EditSquareIcon from "./EditSquareIcon";
import EditIcon from "./EditIcon";
import FileIcon from "./FileIcon";
import FilterIcon from "./FilterIcon";
import Filter3Icon from "./Filter3Icon";
import FolderIcon from "./FolderIcon";
import FontTypefaceIcon from "./FontTypefaceIcon";
import GameIcon from "./GameIcon";
import GarageIcon from "./GarageIcon";
import GitMergeIcon from "./GitMergeIcon";
import GraphIcon from "./GraphIcon";
import GridInterface_1Icon from "./GridInterface_1Icon";
import HashtagIcon from "./HashtagIcon";
import HeartIcon from "./HeartIcon";
import HideIcon from "./HideIcon";
import HomeIcon from "./HomeIcon";
import ImageIcon from "./ImageIcon";
import InfoCircleIcon from "./InfoCircleIcon";
import InfoSquareIcon from "./InfoSquareIcon";
import ItalicIcon from "./ItalicIcon";
import LibraryAddPlusIcon from "./LibraryAddPlusIcon";
import LinkUrlIcon from "./LinkUrlIcon";
import LinkIcon from "./LinkIcon";
import ListBulletedIcon from "./ListBulletedIcon";
import ListNumberedIcon from "./ListNumberedIcon";
import LocationIcon from "./LocationIcon";
import LockIcon from "./LockIcon";
import LoginIcon from "./LoginIcon";
import LogoutIcon from "./LogoutIcon";
import MenuIcon from "./MenuIcon";
import MessageIcon from "./MessageIcon";
import MinusPlainIcon from "./MinusPlainIcon";
import MoreCircleIcon from "./MoreCircleIcon";
import MoreHorizontalIcon from "./MoreHorizontalIcon";
import MoreSquareIcon from "./MoreSquareIcon";
import NotificationSelectedIcon from "./NotificationSelectedIcon";
import NotificationIcon from "./NotificationIcon";
import PaperDownloadIcon from "./PaperDownloadIcon";
import PaperFailIcon from "./PaperFailIcon";
import PaperNegativeIcon from "./PaperNegativeIcon";
import PaperPlusIcon from "./PaperPlusIcon";
import PaperUploadIcon from "./PaperUploadIcon";
import PaperIcon from "./PaperIcon";
import PaperClipIcon from "./PaperClipIcon";
import PasswordIcon from "./PasswordIcon";
import PinIcon from "./PinIcon";
import PlayIcon from "./PlayIcon";
import PlaylistCasetteIcon from "./PlaylistCasetteIcon";
import PlusPlainIcon from "./PlusPlainIcon";
import PlusIcon from "./PlusIcon";
import ProfileIcon from "./ProfileIcon";
import RedoIcon from "./RedoIcon";
import RefreshLeftIcon from "./RefreshLeftIcon";
import RefreshRightIcon from "./RefreshRightIcon";
import ReplyIcon from "./ReplyIcon";
import RetouchMagicWandIcon from "./RetouchMagicWandIcon";
import RightUpBoxIcon from "./RightUpBoxIcon";
import RingtoneAddIcon from "./RingtoneAddIcon";
import RingtoneCheckIcon from "./RingtoneCheckIcon";
import ScanIcon from "./ScanIcon";
import SearchThunderIcon from "./SearchThunderIcon";
import SearchIcon from "./SearchIcon";
import SendIcon from "./SendIcon";
import Send2Icon from "./Send2Icon";
import SequoiaIcon from "./SequoiaIcon";
import SettingIcon from "./SettingIcon";
import ShieldDoneIcon from "./ShieldDoneIcon";
import ShieldFailIcon from "./ShieldFailIcon";
import ShowIcon from "./ShowIcon";
import ShrinkIcon from "./ShrinkIcon";
import SideViewIcon from "./SideViewIcon";
import SlidersIcon from "./SlidersIcon";
import SmileyIcon from "./SmileyIcon";
import SnoozeIcon from "./SnoozeIcon";
import SocialGithubIcon from "./SocialGithubIcon";
import SocialInstagramIcon from "./SocialInstagramIcon";
import SocialLinkedinIcon from "./SocialLinkedinIcon";
import SocialXIcon from "./SocialXIcon";
import SortDownIcon from "./SortDownIcon";
import SortUpIcon from "./SortUpIcon";
import SparklingIcon from "./SparklingIcon";
import SproutIcon from "./SproutIcon";
import StageIcon from "./StageIcon";
import StarSelectedIcon from "./StarSelectedIcon";
import StarIcon from "./StarIcon";
import StrikethroughIcon from "./StrikethroughIcon";
import SwapIcon from "./SwapIcon";
import ThumbLike_2Icon from "./ThumbLike_2Icon";
import ThumbsDownIcon from "./ThumbsDownIcon";
import ThumbsUpIcon from "./ThumbsUpIcon";
import ThunderIcon from "./ThunderIcon";
import TickSquareIcon from "./TickSquareIcon";
import TicketStarIcon from "./TicketStarIcon";
import TicketIcon from "./TicketIcon";
import TimeCircleIcon from "./TimeCircleIcon";
import TimeSquareIcon from "./TimeSquareIcon";
import TopicIcon from "./TopicIcon";
import TrendUpIcon from "./TrendUpIcon";
import UnderlineIcon from "./UnderlineIcon";
import UndoIcon from "./UndoIcon";
import UnlockIcon from "./UnlockIcon";
import UploadIcon from "./UploadIcon";
import User2Icon from "./User2Icon";
import User3Icon from "./User3Icon";
import VideoIcon from "./VideoIcon";
import VoiceIcon from "./VoiceIcon";
import Voice3Icon from "./Voice3Icon";
import VolumeDownIcon from "./VolumeDownIcon";
import VolumeOffIcon from "./VolumeOffIcon";
import VolumeUpIcon from "./VolumeUpIcon";
import WalletIcon from "./WalletIcon";
import WorkIcon from "./WorkIcon";
import PhoneCallIcon from "./PhoneCallIcon";
import PhoneIcon from "./PhoneIcon";
import QuestionIcon from "./QuestionIcon";
import ToolWrenchIcon from "./ToolWrenchIcon";
export const ICONS_BY_NAME = { 
  AmpersandIcon:AmpersandIcon,
ActivityIcon:ActivityIcon,
AddUserIcon:AddUserIcon,
AlignLeftDecreaseIcon:AlignLeftDecreaseIcon,
ArrowDownCircleIcon:ArrowDownCircleIcon,
ArrowDownRightIcon:ArrowDownRightIcon,
ArrowDownSquareIcon:ArrowDownSquareIcon,
ArrowDownIcon:ArrowDownIcon,
ArrowDown2Icon:ArrowDown2Icon,
ArrowDown3Icon:ArrowDown3Icon,
ArrowLeftCircleIcon:ArrowLeftCircleIcon,
ArrowLeftSquareIcon:ArrowLeftSquareIcon,
ArrowLeftIcon:ArrowLeftIcon,
ArrowLeft2Icon:ArrowLeft2Icon,
ArrowLeft3Icon:ArrowLeft3Icon,
ArrowRightCircleIcon:ArrowRightCircleIcon,
ArrowRightSquareIcon:ArrowRightSquareIcon,
ArrowRightIcon:ArrowRightIcon,
ArrowRight2Icon:ArrowRight2Icon,
ArrowRight3Icon:ArrowRight3Icon,
ArrowUpCircleIcon:ArrowUpCircleIcon,
ArrowUpRightIcon:ArrowUpRightIcon,
ArrowUpSquareIcon:ArrowUpSquareIcon,
ArrowUpIcon:ArrowUpIcon,
ArrowUp2Icon:ArrowUp2Icon,
ArrowUp3Icon:ArrowUp3Icon,
ArrowsDiagonalContractIcon:ArrowsDiagonalContractIcon,
ArrowsDiagonalMaximizeAltIcon:ArrowsDiagonalMaximizeAltIcon,
ArrowsAltIcon:ArrowsAltIcon,
BagIcon:BagIcon,
Bag3Icon:Bag3Icon,
BoldIcon:BoldIcon,
BookClosedIcon:BookClosedIcon,
BookOpenIcon:BookOpenIcon,
BookmarkIcon:BookmarkIcon,
BuildingOfficeIcon:BuildingOfficeIcon,
BuyIcon:BuyIcon,
CalendarIcon:CalendarIcon,
CallMissedIcon:CallMissedIcon,
CallSilentIcon:CallSilentIcon,
CallIcon:CallIcon,
CallingIcon:CallingIcon,
CameraIcon:CameraIcon,
CapIcon:CapIcon,
CategoryIcon:CategoryIcon,
CenteredViewIcon:CenteredViewIcon,
ChartIcon:ChartIcon,
ChatTopicIcon:ChatTopicIcon,
ChatIcon:ChatIcon,
CheckIcon:CheckIcon,
ChevronExpandVericalIcon:ChevronExpandVericalIcon,
CircleCheckIcon:CircleCheckIcon,
CircleCheckedIcon:CircleCheckedIcon,
CircleCloseIcon:CircleCloseIcon,
CloseRemoveIcon:CloseRemoveIcon,
CloseSquareIcon:CloseSquareIcon,
CloseIcon:CloseIcon,
ColorBlueIcon:ColorBlueIcon,
ColorGreenIcon:ColorGreenIcon,
ColorGreyIcon:ColorGreyIcon,
ColorOrangeIcon:ColorOrangeIcon,
ColorPurpleIcon:ColorPurpleIcon,
ColorRedIcon:ColorRedIcon,
ColorYellowIcon:ColorYellowIcon,
ColumnsIcon:ColumnsIcon,
CopyIcon:CopyIcon,
CounterClockwiseUndoIcon:CounterClockwiseUndoIcon,
DangerCircleIcon:DangerCircleIcon,
DangerTriangleIcon:DangerTriangleIcon,
DeleteIcon:DeleteIcon,
DeleteOutlinedIcon:DeleteOutlinedIcon,
DiscountIcon:DiscountIcon,
DiscoveryIcon:DiscoveryIcon,
DocumentIcon:DocumentIcon,
DollarSignInvestorIcon:DollarSignInvestorIcon,
DoorOpenIcon:DoorOpenIcon,
DownloadIcon:DownloadIcon,
DragMenuVerticalIcon:DragMenuVerticalIcon,
EarthIcon:EarthIcon,
EditAltIcon:EditAltIcon,
EditSquareIcon:EditSquareIcon,
EditIcon:EditIcon,
FileIcon:FileIcon,
FilterIcon:FilterIcon,
Filter3Icon:Filter3Icon,
FolderIcon:FolderIcon,
FontTypefaceIcon:FontTypefaceIcon,
GameIcon:GameIcon,
GarageIcon:GarageIcon,
GitMergeIcon:GitMergeIcon,
GraphIcon:GraphIcon,
GridInterface_1Icon:GridInterface_1Icon,
HashtagIcon:HashtagIcon,
HeartIcon:HeartIcon,
HideIcon:HideIcon,
HomeIcon:HomeIcon,
ImageIcon:ImageIcon,
InfoCircleIcon:InfoCircleIcon,
InfoSquareIcon:InfoSquareIcon,
ItalicIcon:ItalicIcon,
LibraryAddPlusIcon:LibraryAddPlusIcon,
LinkUrlIcon:LinkUrlIcon,
LinkIcon:LinkIcon,
ListBulletedIcon:ListBulletedIcon,
ListNumberedIcon:ListNumberedIcon,
LocationIcon:LocationIcon,
LockIcon:LockIcon,
LoginIcon:LoginIcon,
LogoutIcon:LogoutIcon,
MenuIcon:MenuIcon,
MessageIcon:MessageIcon,
MinusPlainIcon:MinusPlainIcon,
MoreCircleIcon:MoreCircleIcon,
MoreHorizontalIcon:MoreHorizontalIcon,
MoreSquareIcon:MoreSquareIcon,
NotificationSelectedIcon:NotificationSelectedIcon,
NotificationIcon:NotificationIcon,
PaperDownloadIcon:PaperDownloadIcon,
PaperFailIcon:PaperFailIcon,
PaperNegativeIcon:PaperNegativeIcon,
PaperPlusIcon:PaperPlusIcon,
PaperUploadIcon:PaperUploadIcon,
PaperIcon:PaperIcon,
PaperClipIcon:PaperClipIcon,
PasswordIcon:PasswordIcon,
PinIcon:PinIcon,
PlayIcon:PlayIcon,
PlaylistCasetteIcon:PlaylistCasetteIcon,
PlusPlainIcon:PlusPlainIcon,
PlusIcon:PlusIcon,
ProfileIcon:ProfileIcon,
RedoIcon:RedoIcon,
RefreshLeftIcon:RefreshLeftIcon,
RefreshRightIcon:RefreshRightIcon,
ReplyIcon:ReplyIcon,
RetouchMagicWandIcon:RetouchMagicWandIcon,
RightUpBoxIcon:RightUpBoxIcon,
RingtoneAddIcon:RingtoneAddIcon,
RingtoneCheckIcon:RingtoneCheckIcon,
ScanIcon:ScanIcon,
SearchThunderIcon:SearchThunderIcon,
SearchIcon:SearchIcon,
SendIcon:SendIcon,
Send2Icon:Send2Icon,
SequoiaIcon:SequoiaIcon,
SettingIcon:SettingIcon,
ShieldDoneIcon:ShieldDoneIcon,
ShieldFailIcon:ShieldFailIcon,
ShowIcon:ShowIcon,
ShrinkIcon:ShrinkIcon,
SideViewIcon:SideViewIcon,
SlidersIcon:SlidersIcon,
SmileyIcon:SmileyIcon,
SnoozeIcon:SnoozeIcon,
SocialGithubIcon:SocialGithubIcon,
SocialInstagramIcon:SocialInstagramIcon,
SocialLinkedinIcon:SocialLinkedinIcon,
SocialXIcon:SocialXIcon,
SortDownIcon:SortDownIcon,
SortUpIcon:SortUpIcon,
SparklingIcon:SparklingIcon,
SproutIcon:SproutIcon,
StageIcon:StageIcon,
StarSelectedIcon:StarSelectedIcon,
StarIcon:StarIcon,
StrikethroughIcon:StrikethroughIcon,
SwapIcon:SwapIcon,
ThumbLike_2Icon:ThumbLike_2Icon,
ThumbsDownIcon:ThumbsDownIcon,
ThumbsUpIcon:ThumbsUpIcon,
ThunderIcon:ThunderIcon,
TickSquareIcon:TickSquareIcon,
TicketStarIcon:TicketStarIcon,
TicketIcon:TicketIcon,
TimeCircleIcon:TimeCircleIcon,
TimeSquareIcon:TimeSquareIcon,
TopicIcon:TopicIcon,
TrendUpIcon:TrendUpIcon,
UnderlineIcon:UnderlineIcon,
UndoIcon:UndoIcon,
UnlockIcon:UnlockIcon,
UploadIcon:UploadIcon,
User2Icon:User2Icon,
User3Icon:User3Icon,
VideoIcon:VideoIcon,
VoiceIcon:VoiceIcon,
Voice3Icon:Voice3Icon,
VolumeDownIcon:VolumeDownIcon,
VolumeOffIcon:VolumeOffIcon,
VolumeUpIcon:VolumeUpIcon,
WalletIcon:WalletIcon,
WorkIcon:WorkIcon,
PhoneCallIcon:PhoneCallIcon,
PhoneIcon:PhoneIcon,
QuestionIcon:QuestionIcon,
ToolWrenchIcon:ToolWrenchIcon
}