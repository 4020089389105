export interface DocumentSnapshotCallback<Type> {
  (document: Type | undefined): void;
}

export interface QuerySnapshotCallback<Type> {
  (snapshot: Snapshot<Type>): void;
}
export interface SnapshotErrorCallback {
  (error: Error): void;
}

export interface SnapshotListenerUnsubscribe {
  (): void;
}

export enum SnapshotChangeType {
  added,
  modified,
  removed,
}

export class SnapshotChange<T> {
  id: String;
  data: T | undefined;
  type: SnapshotChangeType;
  oldIndex: number;
  newIndex: number;

  constructor(
    id: String,
    data: T | undefined,
    type: SnapshotChangeType,
    oldIndex: number,
    newIndex: number,
  ) {
    this.id = id;
    this.data = data;
    this.type = type;
    this.oldIndex = oldIndex;
    this.newIndex = newIndex;
  }
}

export class Snapshot<Type> {
  readonly data: Type[];
  readonly changes: SnapshotChange<Type>[];

  constructor(data: Type[], changes: SnapshotChange<Type>[]) {
    this.data = data;
    this.changes = changes;
  }
}
