import FirestoreDataConverter from "./base/data-converter";

export class DeletedPostRecord {
  readonly id: string;

  constructor(id: string) {
    this.id = id;
  }
}

export const deletedPostRecordDataConverter: FirestoreDataConverter<DeletedPostRecord> =
  {
    toFirestoreModel: function (deletedPost: DeletedPostRecord) {
      return {
        id: deletedPost.id,
      };
    },
    fromFirestoreModel: function (data): DeletedPostRecord {
      return new DeletedPostRecord(data.id);
    },
  };
