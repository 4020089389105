import {
  ResizedImageSize,
  resizedImageStoragePath,
} from "@sequoiacap/shared/utils/resizeImageStoragePath";
import { STORAGE_PATH, createNetworkCacheDb } from "~/worker-db";
import {
  StorageReference,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
} from "firebase/storage";
import { TrackErrorEvent, trackError } from "~/utils/analytics";
import { useAPIGetServerProp } from "~/network/user-api";
import getConfig from "next/config";
import useLightUserInfo from "~/network/useLightUserInfo";
import useSWR, { mutate } from "swr";
import useServiceWorker from "~/hooks/useServiceWorker";

const { publicRuntimeConfig } = getConfig();

// Get storage download URL from Firebase Storage if it is not already in SWR cache.
export async function getStorageFileDownloadURL(
  path: string,
  shouldRevalidate = false,
): Promise<string> {
  const key = {
    type: "storage",
    pathOnly: true,
    path,
  };
  const result = await mutate<string>(
    key,
    async (currentValue: string | undefined) => {
      if (currentValue && !shouldRevalidate) {
        return currentValue;
      }
      return await getDownloadURLHelper(path);
    },
  );
  if (result) {
    return result;
  }
  throw new Error(`Not found: ${path}`);
}

function useCDNByServerProp() {
  const { userId: loggedInUserId } = useLightUserInfo();
  const { data: serverProp, loading: spLoading } =
    useAPIGetServerProp(loggedInUserId);
  const { swVersion, loading: swLoading } = useServiceWorker();

  let cdn = false;
  let loading = spLoading;
  if (!serverProp?.cdnImage) {
    cdn = false;
  } else {
    if (serverProp.cdnImage === "false") {
      cdn = false;
    } else if (serverProp.cdnImage === "true") {
      cdn = true;
    } else if (!serverProp.serviceWorkerV2) {
      cdn = true;
    } else if (swLoading) {
      loading = true;
    } else if (!swVersion) {
      cdn = true;
    } else {
      const minVersion = parseInt(serverProp.cdnImage) ?? 0;
      const swInstallDate = parseInt(swVersion?.split(".")[0] ?? "0");
      if (swInstallDate >= minVersion) {
        cdn = true;
      }
    }
  }

  return { cdn, loading };
}

export function useStorageImage(
  src: string | undefined | null,
  size: ResizedImageSize = ResizedImageSize.Original,
) {
  const { cdn: useCdn, loading: cdnPropLoading } = useCDNByServerProp();
  const { data, error } = useSWR<
    string,
    Error,
    {
      type: "storage-image";
      size: ResizedImageSize;
      src: string;
      cdn: boolean;
    } | null
  >(
    src && !cdnPropLoading
      ? {
          type: "storage-image",
          size: size,
          src,
          cdn: useCdn,
        }
      : null,
    async (key) => {
      const { src: fetchSrc, size: fetchSize, cdn } = key;
      if (/^(([a-z]+:)?\/\/)/i.test(fetchSrc)) {
        // Already an absolute URL
        return fetchSrc;
      }
      if (/^\/cdn\//i.test(fetchSrc)) {
        console.log(`useStorageImage/fetchSrc: Already a cdn path ${fetchSrc}`);
        // Already a cdn path
        return fetchSrc;
      }
      if (cdn) {
        const cdnPath = await getCDNPathHelper(fetchSrc, size);
        if (cdnPath) {
          const domain = publicRuntimeConfig.domain;
          if (window.location.hostname === domain) {
            return cdnPath;
          }
          return `https://${domain}${cdnPath}`;
        }
      }
      const resizedPath =
        fetchSize === undefined
          ? undefined
          : resizedImageStoragePath(fetchSrc, fetchSize);
      if (resizedPath) {
        const resizedSrc = await getDownloadURLHelper(resizedPath);
        if (resizedSrc) {
          return resizedSrc;
        }
        trackError(TrackErrorEvent.storageImageError, new Error("Not found"), {
          path: src || "",
          size: size?.toString() || "",
          resized_path: resizedPath,
        });
      }
      console.log(
        `useStorageImage/getStorageFileDownloadURL: Resized path ${resizedPath} was empty. (Maybe the resize failed?). Falling back to original ${fetchSrc}`,
      );
      const baseSizeUrl = await getDownloadURLHelper(fetchSrc);
      if (baseSizeUrl) {
        return baseSizeUrl;
      }
      throw new Error(`Not found: ${fetchSrc}`);
    },
  );
  if (!src) {
    return { loading: false };
  }
  return { src: data, loading: !error && !data, error };
}

async function getCDNPathHelper(path: string, size: ResizedImageSize) {
  if (path.startsWith("public/")) {
    return `/cdn/storage?path=${encodeURIComponent(path)}&size=${size}`;
  }
  const urlStr = await getDownloadURLHelper(path);
  if (urlStr) {
    const storageUrl = new URL(urlStr);
    const token = storageUrl.searchParams.get("token");
    if (token) {
      return `/cdn/storage?path=${encodeURIComponent(
        path,
      )}&token=${token}&size=${size}`;
    }
  }
  return undefined;
}

// Get storage download URL from Firebase Storage first,
// if it failed, then try to get it from indexedDB.
async function getDownloadURLHelper(path: string) {
  const fileRef = ref(getStorage(), path);
  const startTs = Date.now();
  let fetchedPath;
  try {
    fetchedPath = await getDownloadURL(fileRef);
  } catch (e) {
    console.log("getDownloadURLHelper/getDownloadURL error", path, e);
  }
  if (fetchedPath) {
    try {
      const db = await createNetworkCacheDb();
      await db.put(STORAGE_PATH, {
        key: path,
        path: fetchedPath,
        timestamp: Date.now(),
      });
    } catch (e) {
      console.error("getDownloadURLHelper/store cache error", e);
    }
    console.log(
      `getDownloadURLHelper/fetched from firebase storage path=${path} ms=${
        Date.now() - startTs
      }`,
    );
    return fetchedPath;
  }

  // Try to get from indexedDB.
  try {
    const db = await createNetworkCacheDb();
    const cachedPath = await db.get(STORAGE_PATH, path);
    if (cachedPath) {
      console.log("getDownloadURLHelper/return from cache", path);
      return cachedPath.path;
    }
  } catch (e) {
    console.error("getDownloadURLHelper/get cache error", e);
  }
  return fetchedPath;
}

/**
 * Loads a value from Firebase Storage as a JSON object.
 *
 * If T is a simple object or contains only other simple objects, then you don't need to directly cast the result.
 * Otherwise, the types may be incorrect (e.g. if T = { d: Date }, then useSWRStorage<T>('...').data.d may not be a Date).
 */
export function useStorage<T>(fullPath: string | undefined | null | false): {
  loading: boolean;
  error?: Error;
  data?: T;
} {
  const key = fullPath
    ? {
        type: "storage",
        pathOnly: false,
        path: fullPath,
      }
    : null;
  const { data, error } = useSWR(key, async (fetchKey) => {
    const downloadURL = await getDownloadURLHelper(fetchKey.path);
    if (downloadURL) {
      const response = await fetch(downloadURL);
      return await response.json();
    } else {
      throw new Error("File not found path=" + fetchKey.path);
    }
  });
  return {
    loading: !!fullPath && !data && !error,
    data,
    error,
  };
}

/**
 * Generates a download URL for the given resource.
 *
 * Caller must have permission to access the resource but once the link is created, anyone
 * can access until manually revoked (e.g. Firebase console, admin tool).
 *
 * @param throwOnError If false, error will not be returned in the result and you'll get an empty string. The result will be cached.
 */
export function useStorageDownloadURL(
  fullPath: string | null,
  throwOnError = true,
): {
  loading: boolean;
  error?: Error;
  url?: string;
} {
  const key = fullPath
    ? {
        type: "storage",
        pathOnly: true,
        path: fullPath,
      }
    : null;
  const { data, error } = useSWR(key, async (fetchKey) => {
    try {
      const result = await getDownloadURLHelper(fetchKey.path);
      if (result) {
        return result;
      } else if (!throwOnError) {
        return "";
      }
      throw new Error("File not found path=" + fetchKey.path);
    } catch (e) {
      if (throwOnError) {
        throw e;
      } else {
        return "";
      }
    }
  });
  return {
    loading: !!fullPath && data === undefined && !error,
    url: data,
    error,
  };
}

export function useListStorageItems(fullPath: string | undefined): {
  loading: boolean;
  error?: Error;
  data?: StorageReference[];
} {
  const { data, error } = useSWR(
    fullPath ? fullPath : null,
    async (storagePath: string) => {
      const response = await listAll(ref(getStorage(), storagePath));
      return response.items;
    },
  );
  return {
    loading: !!fullPath && !data && !error,
    data,
    error,
  };
}
