import { Event } from "./event";
import { LibraryEntry } from "./library-entry";
import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export type EdgeStoryEntity = LibraryEntry | Event;

export enum EdgeStoryObjectStyle {
  profileOnLeft = "profile_on_left",
  imageOnRight = "image_on_right",
}

export const EdgeStoryMaxDisplayedObjectListItems = 3;
export class EdgeStory {
  readonly id: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  /**story type, used for backend only */
  storyType: string;
  /** used for rules to determine who can see this story 
   * ```{
    event_id?: string;
    audience?: string[];
    viewable?: string[];
    user_list_id?: string;
}```
  */
  metadata: Record<string, string[] | string | undefined>;

  /** version of the story format, used for iOS to drop stories that it can't display properly */
  version?: number;

  /** header info */
  headerInfo?: HeaderInfo;

  title?: string;
  subtitle?: string;
  body?: string;
  imageUrl?: string;

  actionLink?: string;

  listOfObject?: EdgeStoryObject[];
  listOfStyledObject?: EdgeStoryObject[];

  /** If the object list is being displayed, adding this text/link will
   * add a clickable footer. Right now that footer is always displayed with
   * an -> to the right of it.  Like "See all events ->" etc.
   */
  listFooterText?: string;
  listFooterActionLink?: string;

  cta?: {
    title: string;
    actionLink: string;
    style: string; // primary, secondary
  };

  // only used in web for combining stories
  listOfEntities?: EdgeStoryEntity[];

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    storyType: string,
    metadata: Record<string, string[] | string | undefined>,
    version?: number,
    headerInfo?: HeaderInfo,
    title?: string,
    subtitle?: string,
    body?: string,
    imageUrl?: string,
    actionLink?: string,
    listOfObject?: EdgeStoryObject[],
    listOfStyledObject?: EdgeStoryObject[],
    listFooterText?: string,
    listFooterActionLink?: string,
    cta?: {
      title: string;
      actionLink: string;
      style: string;
    },
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.storyType = storyType;
    this.metadata = metadata;
    this.version = version;
    this.headerInfo = headerInfo;
    this.title = title;
    this.subtitle = subtitle;
    this.body = body;
    this.imageUrl = imageUrl;
    this.actionLink = actionLink;
    this.listOfObject = listOfObject;
    this.listOfStyledObject = listOfStyledObject;
    this.listFooterText = listFooterText;
    this.listFooterActionLink = listFooterActionLink;
    this.cta = cta;
  }
}

export class HeaderInfo {
  /* html string */
  formattedText: string;
  /* url to the icon,
    either iconUrl or profileUserId should be present,
    if both are present, profileUserId will be used
  */
  iconUrl?: string;
  profileUserId?: string;
  /* optional, url to the action link */
  actionLinkList?: (string | null)[];
  displayTimestamp?: StorageTimestamp;
  constructor(
    formattedText: string,
    iconUrl?: string,
    profileUserId?: string,
    actionLinkList?: (string | null)[],
    displayTimestamp?: StorageTimestamp,
  ) {
    this.formattedText = formattedText;
    this.iconUrl = iconUrl;
    this.profileUserId = profileUserId;
    this.actionLinkList = actionLinkList;
    this.displayTimestamp = displayTimestamp;
  }
}

export class EdgeStoryObject {
  /* for user, user name */
  displayName: string;

  /* for user, user CompanyCo • Title */
  metaText?: string;

  /* either iconUrl or profileUserId should be present, if both are present, profileUserId will be used */
  profileUserId?: string;
  avatarUrl?: string;

  /* optional, chatId for this user */
  chatId?: string;

  /* optional, url to the action link, only exits when there is a deep link */
  actionLink?: string;
  body?: string;
  tags?: string[];

  /* entries above this are used by pre-styled-object entries, and
    we use them for as much as we can to keep driving older clients consistently.
    newer clients will have version num we can bump for incompatible style changes. */
  style: EdgeStoryObjectStyle;

  /* If the display type is imageOnRight, this controls the
    content of the image on the right. */
  imageUrl?: string;
  imageDate?: StorageTimestamp;

  constructor(
    displayName: string,
    style: EdgeStoryObjectStyle = EdgeStoryObjectStyle.profileOnLeft,
    metaText?: string,
    profileUserId?: string,
    avatarUrl?: string,
    chatId?: string,
    actionLink?: string,
    body?: string,
    tags?: string[],
    imageUrl?: string,
    imageDate?: StorageTimestamp,
  ) {
    this.displayName = displayName;
    this.style = style;
    this.metaText = metaText;
    this.profileUserId = profileUserId;
    this.avatarUrl = avatarUrl;
    this.chatId = chatId;
    this.actionLink = actionLink;
    this.body = body;
    this.tags = tags;
    this.imageUrl = imageUrl;
    this.imageDate = imageDate;
  }
}

const headerInfoConverter: FirestoreDataConverter<HeaderInfo> = {
  toFirestoreModel: function (headerInfo: HeaderInfo) {
    return {
      formatted_text: headerInfo.formattedText,
      icon_url: headerInfo.iconUrl,
      profile_user_id: headerInfo.profileUserId,
      action_link_list: headerInfo.actionLinkList,
      display_timestamp: headerInfo.displayTimestamp,
    };
  },
  fromFirestoreModel: function (snapshot): HeaderInfo {
    return new HeaderInfo(
      snapshot.formatted_text,
      snapshot.icon_url,
      snapshot.profile_user_id,
      snapshot.action_link_list,
      snapshot.display_timestamp,
    );
  },
};

const edgeStoryObjectConverter: FirestoreDataConverter<EdgeStoryObject> = {
  toFirestoreModel: function (storyObject: EdgeStoryObject) {
    return {
      display_name: storyObject.displayName,
      style: storyObject.style,
      meta_text: storyObject.metaText,
      profile_user_id: storyObject.profileUserId,
      avatar_url: storyObject.avatarUrl,
      chat_id: storyObject.chatId,
      action_link: storyObject.actionLink,
      body: storyObject.body,
      tags: storyObject.tags,
      image_url: storyObject.imageUrl,
      image_date: storyObject.imageDate,
    };
  },
  fromFirestoreModel: function (snapshot): EdgeStoryObject {
    return new EdgeStoryObject(
      snapshot.display_name,
      EdgeStoryObjectStyle[
        getEnumKeyByEnumValue(EdgeStoryObjectStyle, snapshot.style) ??
          "profileOnLeft"
      ],
      snapshot.meta_text,
      snapshot.profile_user_id,
      snapshot.avatar_url,
      snapshot.chat_id,
      snapshot.action_link,
      snapshot.body,
      snapshot.tags,
      snapshot.image_url,
      snapshot.image_date,
    );
  },
};

export const edgeStoryDataConverter: FirestoreDataConverter<EdgeStory> = {
  toFirestoreModel: function (story: EdgeStory) {
    const headerInfo = story.headerInfo
      ? headerInfoConverter.toFirestoreModel(story.headerInfo)
      : undefined;
    const listOfObject = story.listOfObject?.map((obj) =>
      edgeStoryObjectConverter.toFirestoreModel(obj),
    );
    const listOfStyledObject = story.listOfStyledObject?.map((entity) =>
      edgeStoryObjectConverter.toFirestoreModel(entity),
    );
    return {
      id: story.id,
      created_at: story.createdAt,
      updated_at: story.updatedAt,
      story_type: story.storyType,
      metadata: story.metadata,
      version: story.version,
      header_info: headerInfo,
      title: story.title,
      subtitle: story.subtitle,
      body: story.body,
      image_url: story.imageUrl,
      action_link: story.actionLink,
      list_of_object: listOfObject,
      list_of_styled_object: listOfStyledObject,
      list_footer_text: story.listFooterText,
      list_footer_action_link: story.listFooterActionLink,
      cta: story.cta,
    };
  },
  fromFirestoreModel: function (snapshot): EdgeStory {
    const headerInfo = snapshot.header_info
      ? headerInfoConverter.fromFirestoreModel(snapshot.header_info)
      : undefined;
    const listOfObject = snapshot.list_of_object?.map((obj: any) =>
      edgeStoryObjectConverter.fromFirestoreModel(obj),
    );
    const listOfStyledObject = snapshot.list_of_styled_object?.map(
      (entity: any) => edgeStoryObjectConverter.fromFirestoreModel(entity),
    );
    return new EdgeStory(
      snapshot.id,
      snapshot.created_at,
      snapshot.updated_at,
      snapshot.story_type,
      snapshot.metadata,
      snapshot.version,
      headerInfo,
      snapshot.title,
      snapshot.subtitle,
      snapshot.body,
      snapshot.image_url,
      snapshot.action_link,
      listOfObject,
      listOfStyledObject,
      snapshot.list_footer_text,
      snapshot.list_footer_action_link,
      snapshot.cta,
    );
  },
};
