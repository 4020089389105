import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class Scout {
  id: string;
  investorId: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  publicScoutGroupId?: string;
  privateScoutGroupId?: string;

  /** Sequoia Administered Fund scouts do not require approval rating. We provide the backoffice */
  saf: boolean;

  followUpInvestorId?: string;

  constructor(
    id: string,
    investorId: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    publicScoutGroupId?: string,
    privateScoutGroupId?: string,
    saf?: boolean,
    followUpInvestorId?: string,
  ) {
    this.id = id;
    this.investorId = investorId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.publicScoutGroupId = publicScoutGroupId;
    this.privateScoutGroupId = privateScoutGroupId;
    this.saf = saf ?? false;
    this.followUpInvestorId = followUpInvestorId;
  }
}

export const scoutConverter: FirestoreDataConverter<Scout> = {
  toFirestoreModel: function (scout: Scout) {
    return {
      id: scout.id,
      investor_id: scout.investorId,
      created_at: scout.createdAt,
      updated_at: scout.updatedAt,
      public_scout_group_id: scout.publicScoutGroupId,
      private_scout_group_id: scout.privateScoutGroupId,
      saf: scout.saf,
      follow_up_investor_id: scout.followUpInvestorId,
    };
  },
  fromFirestoreModel: function (data): Scout {
    return new Scout(
      data.id,
      data.investor_id,
      data.created_at,
      data.updated_at,
      data.public_scout_group_id,
      data.private_scout_group_id,
      data.saf,
      data.follow_up_investor_id,
    );
  },
};
