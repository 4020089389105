import {
  FirestoreDataConverter as DataConverter,
  ServerTimestamp,
} from "@sequoiacap/shared/models";
import {
  DocumentData,
  FieldPath,
  FieldValue,
  FirestoreDataConverter,
  PartialWithFieldValue,
  Timestamp,
  serverTimestamp,
} from "firebase/firestore";
import { cloneDeepWith } from "lodash";

export type StorageTimestamp = Date | ServerTimestamp;

/**
 * Wraps the shared converters, converting shared/base fields to firebase ones (e..g FieldValue.serverTimeStamp),
 * since shared/ cannot refer to firebase
 */
export default function getFirebaseDataConverter<T>(
  converter: DataConverter<T>,
): FirestoreDataConverter<T> {
  return {
    toFirestore: function (
      modelObject: PartialWithFieldValue<T>,
      options?: {
        merge?: boolean;
        mergeFields?: Array<string | FieldPath>;
      },
    ): DocumentData {
      return cloneToFirebaseDb(
        converter.toFirestoreModel(
          modelObject,
          options
            ? {
                merge: options.merge,
                mergeFields: options.mergeFields?.map((field) =>
                  field.toString(),
                ),
              }
            : undefined,
        ),
      );
    },
    fromFirestore: function (snapshot, options) {
      const data = cloneFromFirebaseDb(snapshot.data(options));
      data._doc_id = snapshot.id;
      return converter.fromFirestoreModel(data);
    },
  };
}

// helper function to create data for firebase firestore API
export function cloneToFirebaseDb(object: { [key: string]: unknown }): {
  [key: string]: FieldValue | Partial<unknown> | undefined;
} {
  return cloneDeepWith(object, (value) => {
    Object.keys(value).forEach((key) => {
      if (value[key] instanceof ServerTimestamp) {
        value[key] = serverTimestamp();
      } else if (value[key] instanceof Date) {
        value[key] = Timestamp.fromDate(value[key]);
      } else if (value[key] instanceof Object) {
        value[key] = cloneToFirebaseDb(value[key]);
      } else if (value[key] === undefined) {
        delete value[key];
      }
    });
    return value;
  });
}

// helper function read data from firebase firestore API
function cloneFromFirebaseDb(data: { [key: string]: unknown }): {
  [key: string]: unknown;
} {
  return cloneDeepWith(data, (value) => {
    Object.keys(value).forEach((key) => {
      if (value[key] instanceof Timestamp) {
        value[key] = value[key].toDate();
      } else if (value[key] instanceof Object) {
        value[key] = cloneFromFirebaseDb(value[key]);
      }
    });
    return value;
  });
}
