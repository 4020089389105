/* eslint-disable @typescript-eslint/no-explicit-any */
import { Document } from "../types/Document";
import {
  DocumentData,
  PartialWithFieldValue,
  SetOptions,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { collectionCache } from "../classes/Cache";
import { empty } from "../helpers/empty";
import { exists } from "@sequoiacap/shared/utils/exists";
import { fuego } from "../context";
import { isEqual } from "lodash";
import { mutate } from "swr";
import { updateArrayItemInCache } from "~/network/firebase/network-cache-db";

/**
 * Function that, when called, refreshes all queries that match this document path.
 *
 * This can be useful for a pull to refresh that isn't on the same screen as the `useCollection` hook, for example.
 */
const revalidateDocument = (path: string) => {
  return mutate(path);
};

/**
 * Function that, when called, refreshes all queries that match this document path.
 *
 * This can be useful for a pull to refresh that isn't on the same screen as the `useCollection` hook, for example.
 */
const revalidateCollection = (path: string) => {
  const promises: Promise<any>[] = [];
  collectionCache.getSWRKeysFromCollectionPath(path).forEach((key) => {
    promises.push(mutate(key));
  });
  return Promise.all(promises);
};

const mutateDocumentInCollection = <T>(path: string, data: T | undefined) => {
  let collection: string | string[] = path.split(`/`).filter(Boolean);
  const docId = collection.pop(); // remove last item, which is the /doc-id
  collection = collection.join("/");

  // if the path is not a document, return
  if (!docId || !collection) {
    return;
  }

  const promises: Promise<any>[] = [];
  collectionCache.getSWRKeysFromCollectionPath(collection).forEach((key) => {
    // Update the SWR firebase collection cache
    promises.push(
      mutate(
        key,
        (currentState: Document<T>[] | undefined) => {
          if (!currentState) return currentState;

          // don't mutate the current state if it doesn't include this doc
          // why? to prevent creating a new reference of the state
          // creating a new reference could trigger unnecessary re-renders
          if (
            !currentState.some(
              (document) =>
                document.id === docId && !isEqual(document.data, data),
            )
          ) {
            return currentState;
          }

          return currentState
            .map((document = empty.object as Document<T>) => {
              if (document.id === docId) {
                if (data === undefined) {
                  return undefined;
                }
                return { ...document, data };
              }
              return document;
            })
            .filter(exists);
        },
        false,
      ),
    );
    // Update the indexedDB cache
    promises.push(
      updateArrayItemInCache(key, (current) => {
        if ((current as Document<T>).id === docId) {
          if (data === undefined) {
            return undefined;
          }
          if (isEqual((current as Document<T>).data, data)) {
            return current;
          }
          return { ...current, data };
        }
        return current;
      }),
    );
  });

  return Promise.all(promises);
};

const set = <T extends object>(
  path: string | null,
  data: Partial<T>,
  options: Extract<SetOptions, { merge?: boolean }> = {},
  /**
   * If true, the local cache won't be updated. Default `false`.
   */
  ignoreLocalMutation = false,
) => {
  if (path === null) return null;

  const isDocument = path.trim().split("/").filter(Boolean).length % 2 === 0;

  if (!isDocument)
    throw new Error(
      `[@nandorojo/swr-firestore] error: called set() function with path: ${path}. This is not a valid document path.

data: ${JSON.stringify(data)}`,
    );

  if (!ignoreLocalMutation) {
    void mutate(
      path,
      (prevState = empty.object) => {
        if (!options?.merge) return data;
        return {
          ...prevState,
          ...data,
        };
      },
      false,
    );
  }

  let collection: string | string[] = path.split(`/`).filter(Boolean);
  const docId = collection.pop(); // remove last item, which is the /doc-id
  collection = collection.join("/");

  collectionCache.getSWRKeysFromCollectionPath(collection).forEach((key) => {
    void mutate(
      key,
      (currentState: Document<T>[] = empty.array) => {
        // don't mutate the current state if it doesn't include this doc
        // why? to prevent creating a new reference of the state
        // creating a new reference could trigger unnecessary re-renders
        if (!currentState.some((document) => document.id === docId)) {
          return currentState;
        }
        return currentState.map((document = empty.object as Document<T>) => {
          if (document.id === docId) {
            if (!options?.merge) return document;
            return { ...document, ...data };
          }
          return document;
        });
      },
      false,
    );
  });

  return setDoc(doc(fuego.db, path), data, options);
};

const update = <T extends object>(
  path: string | null,
  data: PartialWithFieldValue<T>,
  /**
   * If true, the local cache won't be updated. Default `false`.
   */
  ignoreLocalMutation = false,
) => {
  if (path === null) return null;
  const isDocument = path.trim().split("/").filter(Boolean).length % 2 === 0;

  if (!isDocument)
    throw new Error(
      `[@nandorojo/swr-firestore] error: called update function with path: ${path}. This is not a valid document path.

data: ${JSON.stringify(data)}`,
    );

  if (!ignoreLocalMutation) {
    void mutate(
      path,
      (prevState = empty.object) => {
        return {
          ...prevState,
          ...data,
        };
      },
      false,
    );
  }

  let collection: string | string[] = path.split(`/`).filter(Boolean);
  const docId = collection.pop(); // remove last item, which is the /doc-id
  collection = collection.join("/");

  collectionCache.getSWRKeysFromCollectionPath(collection).forEach((key) => {
    void mutate(
      key,
      (currentState: Document<T>[] = empty.array): Document<T>[] => {
        // don't mutate the current state if it doesn't include this doc
        if (!currentState.some((document) => document.id === docId)) {
          return currentState;
        }
        return currentState.map((document = empty.object as Document<T>) => {
          if (document.id === docId) {
            return { ...document, ...data };
          }
          return document;
        });
      },
      false,
    );
  });
  return updateDoc(
    doc(fuego.db, path),
    data as PartialWithFieldValue<DocumentData>,
  );
};

const deleteDocument = <T extends object>(
  path: string | null,
  /**
   * If true, the local cache won't be updated immediately. Default `false`.
   */
  ignoreLocalMutation = false,
) => {
  if (path === null) return null;

  const isDocument = path.trim().split("/").filter(Boolean).length % 2 === 0;

  if (!isDocument)
    throw new Error(
      `[@nandorojo/swr-firestore] error: called delete() function with path: ${path}. This is not a valid document path.`,
    );

  if (!ignoreLocalMutation) {
    void mutate(path, null, false);

    let collection: string | string[] = path.split(`/`).filter(Boolean);
    const docId = collection.pop(); // remove last item, which is the /doc-id
    collection = collection.join("/");

    collectionCache.getSWRKeysFromCollectionPath(collection).forEach((key) => {
      void mutate(
        key,
        (currentState: Document<T>[] = empty.array) => {
          // don't mutate the current state if it doesn't include this doc
          // why? to prevent creating a new reference of the state
          // creating a new reference could trigger unnecessary re-renders
          if (
            !currentState.some((document) => document && document.id === docId)
          ) {
            return currentState;
          }
          return currentState.filter((document) => {
            if (!document) return false;
            if (document.id === docId) {
              // delete this doc
              return false;
            }
            return true;
          });
        },
        false,
      );
    });
  }

  return deleteDoc(doc(fuego.db, path));
};

export {
  set,
  update,
  revalidateDocument,
  revalidateCollection,
  mutateDocumentInCollection,
  deleteDocument,
};
