import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum NotificationType {
  postTagged = "post_tagged",
  postLiked = "post_liked",
  postHelpful = "post_helpful",
  postEmpathize = "post_empathize",
  postReply = "reply",
  postReactedReply = "post_reacted_reply",
  commentReactedReply = "comment_reacted_reply",
  postSubscribedReply = "post_subscribed_reply",
  postPollVoted = "post_poll_voted",
  postPollConcluded = "post_poll_concluded",
  commentTagged = "comment_tagged",
  commentReply = "comment_reply",
  commentLiked = "comment_liked",
  commentHelpful = "comment_helpful",
  commentEmpathize = "comment_empathize",
  groupPost = "group_post",
  groupInvite = "group_invite",
  groupJoined = "group_joined",
  groupRequest = "group_request",
  groupAccepted = "group_accepted",
  builderMentioned = "builder_mentioned",

  memoApproved = "memo_approved",
  memoApprovedOther = "memo_approved_other",
  memoSubmitted = "memo_submitted",
  memoFeedbackRequest = "memo_feedback_request",
  memoEdited = "memo_edited",
  memoDueDiligenceCompleted = "memo_due_diligence_completed",
  memoComment = "memo_comment",
  memoTagged = "memo_tagged",
  memoCommentTagged = "memo_comment_tagged",
  memoCommentReply = "memo_comment_reply",

  /** Does not appear in activity stream. Used to notify everyone when a null_group post was made */
  postNoGroup = "post",

  /** Does not appear in activity stream. Used for things like promotional push notifications that may have a deep link */
  generic = "generic",

  /** Does not appear in activity stream. Used to notify when someone send you a direct message */
  chatMessage = "chat_message",
  chatMessageReaction = "chat_message_reaction",

  /** Does not appear in activity stream. Used to notify when admin send a message */
  adminPush = "admin_push",
}

export interface Metadata {
  post_id?: string;
  sender_id?: string;
  group_id?: string;
  comment_id?: string;
  deal_sharing_id?: string;
  [key: string]: string | undefined;
}

export class Notification {
  id: string;
  createdAt: StorageTimestamp;
  notification: {
    title?: string;
    subtitle?: string;
    body: string;
  };
  data: {
    type: NotificationType;
    link?: string;
  };
  /** */
  metadata: Metadata;
  collapseKey?: string;
  threadId?: string;
  skipBadge?: boolean;
  state?: string;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    notification: {
      title?: string;
      subtitle?: string;
      body: string;
    },
    data: {
      type: NotificationType;
      link?: string;
    },
    metadata: Metadata,
    collapseKey?: string,
    threadId?: string,
    skipBadge?: boolean,
    state?: string,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.notification = notification;
    this.data = data;
    this.metadata = metadata;
    this.collapseKey = collapseKey;
    this.threadId = threadId;
    this.skipBadge = skipBadge;
    this.state = state;
  }
}

export class NotificationPerDay {
  id: string;
  userId: string;
  createdAt: StorageTimestamp;
  notification: Record<string, Notification>;
  updatedAt?: StorageTimestamp;

  constructor(
    id: string,
    userId: string,
    createdAt: StorageTimestamp,
    notification: Record<string, Notification>,
    updatedAt?: StorageTimestamp,
  ) {
    this.id = id;
    this.userId = userId;
    this.createdAt = createdAt;
    this.notification = notification;
    this.updatedAt = updatedAt;
  }
}

export const notificationPerDayDataConverter: FirestoreDataConverter<NotificationPerDay> =
  {
    toFirestoreModel: function (notificationPerDay: NotificationPerDay) {
      const notification: Record<string, unknown> = {};
      if (notificationPerDay.notification) {
        Object.entries(notificationPerDay.notification).forEach(
          ([key, item]) => {
            notification[key] =
              notificationDataConverter.toFirestoreModel(item);
          },
        );
      }
      return {
        user_id: notificationPerDay.userId,
        created_at: notificationPerDay.createdAt,
        notification: notification,
        updated_at: notificationPerDay.updatedAt,
      };
    },
    fromFirestoreModel: function (data): NotificationPerDay {
      const notification: Record<string, Notification> = {};

      if (data.notification) {
        Object.keys(data.notification).forEach((key) => {
          const value = data.notification[key];
          value.id = key;
          value.thread_id = data._doc_id;
          notification[key] =
            notificationDataConverter.fromFirestoreModel(value);
        });
      }
      return new NotificationPerDay(
        data._doc_id,
        data.user_id,
        data.created_at,
        notification,
        data.updated_at,
      );
    },
  };

export const notificationDataConverter: FirestoreDataConverter<Notification> = {
  toFirestoreModel: function (notification: Notification) {
    return {
      id: notification.id,
      created_at: notification.createdAt,
      notification: notification.notification,
      data: notification.data,
      metadata: notification.metadata,
      collapse_key: notification.collapseKey,
      thread_id: notification.threadId,
      skip_badge: notification.skipBadge,
      state: notification.state,
    };
  },
  fromFirestoreModel: function (data): Notification {
    const type =
      NotificationType[
        getEnumKeyByEnumValue(NotificationType, data.data.type) ??
          NotificationType.generic
      ];
    return new Notification(
      data.id,
      data.created_at,
      data.notification,
      {
        ...data.data,
        type,
      },
      data.metadata,
      data.collapse_key,
      data.thread_id,
      data.skip_badge,
      data.state,
    );
  },
};
