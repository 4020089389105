import { useReadDocument } from "./firebase/FirebaseAPI";

export function useAPIListenAppVersion(loading: boolean) {
  return useReadDocument<{ id: string; min_web_version: string }>(
    loading ? null : "app_version/0",
    {
      fromFirestoreModel: (data) => ({
        id: "0",
        // 20240124.022953.6d46940
        min_web_version: "",
        ...data,
      }),
      toFirestoreModel: (data) => data,
    },
    { listen: true },
  );
}

export function parseBuildNumber(buildNumber: string) {
  const [, year, month, day, hour, minute, seconds, hash] = buildNumber.split(
    /([0-9]{4})([0-9]{2})([0-9]{2})\.([0-9]{2})([0-9]{2})([0-9]{2})\.([0-9a-f]+)/,
  );
  return {
    buildNumber,
    date: new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour),
      Number(minute),
      Number(seconds),
    ),
    year,
    month,
    day,
    hour,
    minute,
    seconds,
    hash,
  };
  //'20240124.022953.6d46940') {
  // }//.split(/([0-9]{4})([0-9]{2})([0-9]{2})/)
}
