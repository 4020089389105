import { BuilderInfo, builderInfoDataConverter } from "./builder-info";
import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum BadgeType {
  post = "post",
  reply = "reply",
  engagement = "engagement",
  login = "login",
  helpful = "helpful",
  insightful = "insightful",
  awesome = "awesome",
  poster = "poster",
  replier = "replier",
  engaged = "engaged",
  unknown = "unknown",
}
export class ActivityBadge {
  badgeType: BadgeType;
  assignedAt: StorageTimestamp;
  postId?: string;
  commentId?: string;
  constructor(
    badgeType: BadgeType,
    assignedAt: StorageTimestamp,
    postId?: string,
    commentId?: string,
  ) {
    this.badgeType = badgeType;
    this.assignedAt = assignedAt;
    this.postId = postId;
    this.commentId = commentId;
  }
}

export enum UserAudience {
  founder = "founder",
  scout = "scout",
  builder = "leader",
  talent = "talent",
  sequoia = "sequoia",
  // NOTE ON ADDING: keep in sync with iOS and consider if needed for Contentful.
  // For Contentful, we also list Ghost (not a real audience but necessary) and don't include Sequoia (since Sequoia can see all Library) and we need this for all Content Models in Contentful.
}
export class User {
  id: string;
  name: string;
  title?: string;
  type_DEPRECATED_FIELD: string;
  companyName?: string;
  location: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  specialGroup: string[];
  videoId?: string;
  companyUrl?: string;
  companyId?: string;
  companyTagline?: string; /** Not actually the company's tagline; this is a one-liner explaining the company, written by us. */
  companyStealth?: boolean; /** Indicate whether our investment is stealth or not (https://app.asana.com/0/1184397562448889/1204481368638374) */
  company?: { [key: string]: any };
  background?: string;
  communityGives?: { [key: string]: any };
  communityGets?: { [key: string]: any };
  publicContact?: { [key: string]: any };
  topBadge?: ActivityBadge;
  recentBadge?: ActivityBadge;
  hashtag?: Array<any>;
  audience?: UserAudience[];
  sector?: string[];
  scoutingSector?: string[];
  structuredLocation?: string[];
  initial?: string;
  investmentStage?: Array<string>;
  archived?: boolean;
  builderInfo?: BuilderInfo;

  /** Populated and used typically in admin only */
  version?: string; // semver
  /** Populated and used typically in admin and Extension (if the backend happened to populate it) */
  profileImageURL?: string;
  /** Populated and used typically in admin only */
  engagements?: Array<{ [key: string]: any }>;
  /** Populated and used typically in admin only */
  customClaims?: Record<string, any>;
  /** Populated and used typically in admin only */
  allEngagements?: Array<{ [key: string]: any }>;
  /** Populated and used typically in admin only */
  email?: string;

  constructor(
    id: string,
    name: string,
    title: string | undefined,
    type_DEPRECATED_FIELD: "founder" | "sequoia", // DEPRECATED FIELD, necessary for old iOS client
    companyName: string | undefined,
    location: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    specialGroup: string[],
    audience: UserAudience[],
    sector?: string[],
    scoutingSector?: string[],
    structuredLocation?: string[],
    videoId?: string,
    companyUrl?: string,
    companyId?: string,
    companyTagline?: string,
    companyStealth?: boolean,
    company?: { [key: string]: any },
    background?: string,
    communityGives?: { [key: string]: any },
    communityGets?: { [key: string]: any },
    publicContact?: { [key: string]: any },
    topBadge?: ActivityBadge,
    recentBadge?: ActivityBadge,
    hashtag?: Array<any>,
    initial?: string,
    investmentStage?: Array<string>,
    archived?: boolean,
    builderInfo?: BuilderInfo,
  ) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.type_DEPRECATED_FIELD = type_DEPRECATED_FIELD;
    this.companyName = companyName;
    this.location = location;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.specialGroup = specialGroup;
    this.audience = audience;
    this.sector = sector;
    this.scoutingSector = scoutingSector;
    this.structuredLocation = structuredLocation;
    this.videoId = videoId;
    this.companyUrl = companyUrl;
    this.companyId = companyId;
    this.companyTagline = companyTagline;
    this.companyStealth = companyStealth;
    this.company = company;
    this.background = background;
    this.communityGives = communityGives;
    this.communityGets = communityGets;
    this.publicContact = publicContact;
    this.topBadge = topBadge;
    this.recentBadge = recentBadge;
    this.hashtag = hashtag;
    this.initial = initial;
    this.investmentStage = investmentStage;
    this.archived = archived;
    this.builderInfo = builderInfo;
  }
}

export const userConverter: FirestoreDataConverter<User> = {
  toFirestoreModel: function (user: User) {
    const top_badge = user.topBadge
      ? activityBadgeDataConverter.toFirestoreModel(user.topBadge)
      : undefined;
    const recent_badge = user.recentBadge
      ? activityBadgeDataConverter.toFirestoreModel(user.recentBadge)
      : undefined;
    const builder_info = user.builderInfo
      ? builderInfoDataConverter.toFirestoreModel(user.builderInfo)
      : undefined;
    return {
      id: user.id,
      name: user.name,
      title: user.title,
      type: user.type_DEPRECATED_FIELD, // DEPRECATED FIELD
      company_name: user.companyName,
      location: user.location,
      created_at: user.createdAt,
      updated_at: user.updatedAt,
      special_group: user.specialGroup,
      audience: user.audience,
      sector: user.sector,
      scouting_sector: user.scoutingSector,
      structured_location: user.structuredLocation,
      video_id: user.videoId,
      company_url: user.companyUrl,
      company_id: user.companyId,
      company_tagline: user.companyTagline,
      company_stealth: user.companyStealth,
      company: user.company,
      background: user.background,
      community_gives: user.communityGives,
      community_gets: user.communityGets,
      public_contact: user.publicContact,
      top_badge: top_badge,
      recent_badge: recent_badge,
      hashtag: user.hashtag,
      initial: user.initial,
      investment_stage: user.investmentStage,
      archived: user.archived,
      builder_info: builder_info,
    };
  },
  fromFirestoreModel: function (data): User {
    const topBadge = data.top_badge
      ? activityBadgeDataConverter.fromFirestoreModel(data.top_badge)
      : undefined;
    const recentBadge = data.recent_badge
      ? activityBadgeDataConverter.fromFirestoreModel(data.recent_badge)
      : undefined;
    const builderInfo = data.builder_info
      ? builderInfoDataConverter.fromFirestoreModel(data.builder_info)
      : undefined;
    return new User(
      data.id,
      data.name,
      data.title,
      data.type, // DEPRECATED FIELD
      data.company_name,
      data.location,
      data.created_at,
      data.updated_at,
      data.special_group,
      data.audience,
      data.sector,
      data.scouting_sector,
      data.structured_location,
      data.video_id,
      data.company_url,
      data.company_id,
      data.company_tagline,
      data.company_stealth,
      data.company,
      data.background,
      data.community_gives,
      data.community_gets,
      data.public_contact,
      topBadge,
      recentBadge,
      data.hashtag,
      data.initial,
      data.investment_stage,
      data.archived,
      builderInfo,
    );
  },
};

export const activityBadgeDataConverter: FirestoreDataConverter<ActivityBadge> =
  {
    toFirestoreModel: function (activityBadge: ActivityBadge) {
      return {
        type: activityBadge.badgeType,
        expired_at: activityBadge.assignedAt, // Server has wrong key
        post_id: activityBadge.postId,
        comment_id: activityBadge.commentId,
      };
    },
    fromFirestoreModel: function (data): ActivityBadge {
      return new ActivityBadge(
        BadgeType[
          getEnumKeyByEnumValue(BadgeType, data.type) ?? BadgeType.unknown
        ],
        data.expired_at,
        data.post_id,
        data.comment_id,
      );
    },
  };
