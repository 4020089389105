import { useEffect } from "react";

export type UsePromise = () => <T>(promise: Promise<T>) => Promise<T>;

// eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
const noop = () => {};

/**
 * Example:
 * ```
 *  useAsyncEffect(
 *    async (isMounted) => {
 *      await doSomethingForAWhile(param1, param2);
 *      // when the effect is unmounted, do not do anything
 *      if (!isMounted()) {
 *        return;
 *      }
 *      // update state or other stuff
 *      setFinish(true);
 *    },
 *    () => {
 *      console.log("This effect is unmounted");
 *    },
 *    [param1, param2]
 *  );
 * ```
 * @param generator Generator function that returns a promise
 * @param destory (optional) Call when the effect is destroyed
 * @param deps (optional) Dependencies for the effect
 */
function useAsyncEffect<T>(
  generator: (isMounted: () => boolean) => Promise<T>,
  onDestroyOrDeps: React.DependencyList | (() => void) = noop,
  deps: React.DependencyList = [],
): void {
  const hasDestroy = typeof onDestroyOrDeps === "function";
  const dependencyList: React.DependencyList = hasDestroy
    ? deps
    : (onDestroyOrDeps as unknown as React.DependencyList);
  const onDestroy: () => void =
    typeof onDestroyOrDeps === "function" ? onDestroyOrDeps : noop;

  useEffect(() => {
    let isMounted = true;
    const instance = generator(() => isMounted);
    Promise.resolve(instance).catch((e) =>
      console.error("Unhandled Error in useAsyncEffect", e),
    );

    return () => {
      isMounted = false;
      onDestroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList);
}

export default useAsyncEffect;
