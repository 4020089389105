import { isIOSStandAlone } from "~/utils/isStandalone";
import getConfig from "next/config";
import useTimeoutFn from "~/hooks/useTimeoutFn";

const { publicRuntimeConfig } = getConfig();

export function RedirectIOSStandalone() {
  useTimeoutFn(() => {
    const isiOSStandalone = isIOSStandAlone();
    const destURL = `https://${publicRuntimeConfig.domain}/ios-standalone`;
    if (isiOSStandalone && window.location.toString() !== destURL) {
      window.location.href = destURL;
    }
  }, 1000);
  return null;
}
