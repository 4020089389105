import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class GroupRequest {
  id: string;
  groupId: string;
  state: string;
  user: string;
  userId: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;

  constructor(
    id: string,
    groupId: string,
    state: string,
    user: string,
    userId: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
  ) {
    this.id = id;
    this.groupId = groupId;
    this.state = state;
    this.user = user;
    this.userId = userId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export const groupRequestConverter: FirestoreDataConverter<GroupRequest> = {
  toFirestoreModel: function (groupRequest: GroupRequest) {
    return {
      id: groupRequest.id,
      group_id: groupRequest.groupId,
      state: groupRequest.state,
      user: groupRequest,
      user_id: groupRequest.userId,
      created_at: groupRequest.createdAt,
      updated_at: groupRequest.updatedAt,
    };
  },
  fromFirestoreModel: function (data): GroupRequest {
    return new GroupRequest(
      data.id,
      data.group_id,
      data.state,
      data.user,
      data.user_id,
      data.created_at,
      data.updated_at,
    );
  },
};
