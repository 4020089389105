import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class Company {
  id: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  name: string;
  domain: string[];
  employees?: string[];
  sequoiaPartner?: string;
  stage?: string;
  sector?: string[];
  tagline?: string;
  stealth?: boolean;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    name: string,
    domain: string[],
    employees?: string[],
    sequoiaPartner?: string,
    stage?: string,
    sector?: string[],
    tagline?: string,
    stealth?: boolean,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.name = name;
    this.domain = domain;
    this.employees = employees;
    this.sequoiaPartner = sequoiaPartner;
    this.stage = stage;
    this.sector = sector;
    this.tagline = tagline;
    this.stealth = stealth;
  }
}

export const companyDataConverter: FirestoreDataConverter<Company> = {
  toFirestoreModel: function (company: Company) {
    return {
      id: company.id,
      created_at: company.createdAt,
      updated_at: company.updatedAt,
      name: company.name,
      domain: company.domain,
      employee: company.employees,
      sequoia_partner: company.sequoiaPartner,
      stage: company.stage,
      sector: company.sector,
      tagline: company.tagline,
      stealth: company.stealth,
    };
  },
  fromFirestoreModel: function (data): Company {
    return new Company(
      data.id,
      data.created_at,
      data.updated_at,
      data.name,
      data.domain,
      data.employee,
      data.sequoia_partner,
      data.stage,
      data.sector,
      data.tagline,
      data.stealth,
    );
  },
};
