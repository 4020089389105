import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

// Define the functions that can be granted access to
export const FunctionGroup: Record<
  string,
  { pages: string[]; functions: string[]; readableUserLists?: string[] }
> = {
  deployLibrary: {
    pages: ["/content-push"],
    functions: ["deployLibraryChange", "deployLibraryFetchUpdate"],
  },
};

export class AdminFunctionAllowList {
  id: string;
  functionGroups: string[];
  pages: string[];
  functions: string[];
  readableUserLists: string[];
  updatedAt: StorageTimestamp;

  constructor(
    id: string,
    functionGroups: string[],
    pages: string[],
    functions: string[],
    readableUserLists: string[],
    updatedAt: StorageTimestamp,
  ) {
    this.id = id;
    this.functionGroups = functionGroups;
    this.pages = pages;
    this.functions = functions;
    this.readableUserLists = readableUserLists;
    this.updatedAt = updatedAt;
  }
}

export const adminFunctionAllowListConverter: FirestoreDataConverter<AdminFunctionAllowList> =
  {
    toFirestoreModel: function (deployLibrary: AdminFunctionAllowList) {
      return {
        id: deployLibrary.id,
        function_group: deployLibrary.functionGroups,
        page: deployLibrary.pages,
        function: deployLibrary.functions,
        readable_user_list: deployLibrary.readableUserLists,
        updated_at: deployLibrary.updatedAt,
      };
    },
    fromFirestoreModel: function (data): AdminFunctionAllowList {
      return new AdminFunctionAllowList(
        data.id,
        data.function_group,
        data.page,
        data.function,
        data.readable_user_list,
        data.updated_at,
      );
    },
  };
