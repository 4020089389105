import { Vouch, vouchDataConverter } from "./vouch";
import FirestoreDataConverter from "./base/data-converter";

export class BuilderInfo {
  employmentHistory: string;
  function?: string;
  topics?: string[];
  vouches?: Vouch[];
  airtableId?: string;

  constructor(
    employmentHistory: string,
    builderFunction?: string, // Can't have function as a parameter name.
    topics?: string[],
    vouches?: Vouch[],
    airtableId?: string,
  ) {
    this.employmentHistory = employmentHistory;
    this.function = builderFunction;
    this.topics = topics;
    this.vouches = vouches;
    this.airtableId = airtableId;
  }
}

export const builderInfoDataConverter: FirestoreDataConverter<BuilderInfo> = {
  toFirestoreModel: function (builderInfo: BuilderInfo) {
    const convertedVouches = builderInfo.vouches?.map((vouch) => {
      return vouchDataConverter.toFirestoreModel(vouch);
    });

    return {
      employment_history: builderInfo.employmentHistory,
      function: builderInfo.function,
      topics: builderInfo.topics,
      vouches: convertedVouches,
      airtable_id: builderInfo.airtableId,
    };
  },
  fromFirestoreModel: function (data): BuilderInfo {
    const typedVouches = data.vouches as [{ [field: string]: any }];
    const convertedVouches =
      typedVouches &&
      typedVouches.map((vouch) => {
        return vouchDataConverter.fromFirestoreModel(vouch);
      });
    return new BuilderInfo(
      data.employment_history,
      data.function,
      data.topics,
      convertedVouches,
      data.airtable_id,
    );
  },
};
