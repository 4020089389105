export function isAndroidStandalone() {
  return window.matchMedia("(display-mode: standalone)").matches;
}

export function isIOSStandAlone() {
  return (window.navigator as any).standalone === true;
}

export function isStandalone() {
  return isAndroidStandalone() || isIOSStandAlone();
}
