export enum WorkerCommand {
  GetVersion = "GET_VERSION",
  ClearCache = "CLEAR_CACHE",
  GetClients = "GET_CLIENTS",
}

export type WorkerCommandInvocation = {
  command: WorkerCommand;
  params?: Record<string, unknown>;
};
