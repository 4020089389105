import { UserAudience } from "../models";

/**
 * Returns the default cohort out of a list of cohorts
 *
 * TODO(kwyee): rename this
 */
export function getLibraryAudienceKey(
  audiences: UserAudience[] | undefined,
): string {
  if (!audiences) {
    return "ghost";
  }
  // The order of this array is important. It's the order of the audiences
  // in the library listing file.
  return audiences.includes(UserAudience.sequoia)
    ? "sequoia"
    : audiences.sort().join("_");
}
