import * as React from "react";
import { forwardRef } from "react";
const VolumeOffIcon = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", fill: "currentColor", "aria-hidden": "true", width: 24, height: 24, ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("mask", { id: "a", width: 13, height: 14, x: 3, y: 4, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "M3 4.601h12.034v13.186H3V4.601Z", clipRule: "evenodd" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#a)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "M12.23 6.111c-.866 0-1.979.916-2.904 1.678-.89.732-1.592 1.31-2.297 1.366-.315.024-.585.035-.823.044-.74.027-.926.045-1.258.33-.453.386-.45 1.934-.447 2.858v.493c-.002.924-.006 2.472.447 2.86.339.29.53.307 1.288.333.229.008.488.017.787.038.086.006.174.02.264.042l6.237-6.238c-.072-2.225-.297-3.223-.827-3.697a1.003 1.003 0 0 0-.466-.107ZM7.464 17.787a.744.744 0 0 1-.375-.1.65.65 0 0 0-.175-.08 19.665 19.665 0 0 0-.73-.035c-.816-.028-1.461-.05-2.211-.692-.98-.838-.976-2.593-.972-4.004v-.485c-.004-1.41-.008-3.167.973-4.004.74-.633 1.374-.657 2.175-.687.22-.009.47-.018.762-.04.25-.033.98-.633 1.462-1.029 1.406-1.159 3.333-2.745 5.135-1.677a.59.59 0 0 1 .093.064c1.123.915 1.363 2.463 1.432 5.18a.75.75 0 0 1-.22.55l-6.818 6.82a.75.75 0 0 1-.531.22Z", clipRule: "evenodd" })), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "M12.233 20.666c-1.01 0-1.97-.585-2.68-1.1a.749.749 0 1 1 .879-1.214c1.313.949 1.905.886 2.264.698.582-.519.803-1.687.837-4.393.006-.414.309-.736.76-.74a.75.75 0 0 1 .74.76c-.038 2.955-.27 4.628-1.433 5.573a.79.79 0 0 1-.092.063 2.461 2.461 0 0 1-1.275.353Z", clipRule: "evenodd" }), /* @__PURE__ */ React.createElement("mask", { id: "b", width: 18, height: 18, x: 3, y: 4, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "M3.002 4H20.5v17.5H3.002V4Z", clipRule: "evenodd" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#b)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "M3.752 21.5a.749.749 0 0 1-.53-1.28l15.999-16a.749.749 0 1 1 1.06 1.06l-16 16a.744.744 0 0 1-.53.22Z", clipRule: "evenodd" })));
const ForwardRef = forwardRef(VolumeOffIcon);
export default ForwardRef;
