/* eslint-disable @typescript-eslint/no-explicit-any */
import { Notification, notificationDataConverter } from "./notification-record";
import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class EmailReply {
  readonly id: string;
  readonly createdAt: StorageTimestamp;
  readonly toUserId: string;
  readonly notification: Notification;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    toUserId: string,
    notification: Notification,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.toUserId = toUserId;
    this.notification = notification;
  }
}

export const emailReplyDataConverter: FirestoreDataConverter<EmailReply> = {
  toFirestoreModel: function (message: EmailReply) {
    return {
      id: message.id,
      created_at: message.createdAt,
      to_user_id: message.toUserId,
      notification: notificationDataConverter.toFirestoreModel(
        message.notification,
      ),
    };
  },
  fromFirestoreModel: function (data): EmailReply {
    return new EmailReply(
      data.id,
      data.created_at,
      data.to_user_id,
      notificationDataConverter.fromFirestoreModel(data.notification),
    );
  },
};
