export type ConnectionsPage<T> = {
  airtableAppId: string;
  data?: T[];
};

export type Builder = {
  airtableId: string;
  name: string;
  profileId?: string; // Ampersand user ID
  bio?: string;
  image?: string;
  linkedin?: string;
  history?: string;
  function?: string;
  topics?: string[];
  website?: string;
  email?: string;
  /** Keyed by airtableId */
  vouchesById?: Record<string, Vouch>;
  vouches?: Vouch[];
};

export type Agency = Builder & {
  type: "Agency" | "Freelancer";
};

export type Vouch = {
  airtableId: string;
  name: string;
  profileId?: string; // Ampersand user ID
  image?: string;
  title?: string;
  quote?: string;
};

export const CONNECTIONS_DATA_VERSION = 3;
export const CONNECTIONS_HOSTING_BASE = `resource/data/v${CONNECTIONS_DATA_VERSION}`;

export const BUILDER_CONNECTIONS_DATA_PATH = `${CONNECTIONS_HOSTING_BASE}/builder-connections-data.json`;
export const AGENCY_CONNECTIONS_DATA_PATH = `${CONNECTIONS_HOSTING_BASE}/agency-connections-data.json`;
