/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useMemo, useRef } from "react";
import useSWR, { SWRConfiguration, mutate as mutateStatic } from "swr";

// import { useMemoOne as useMemo } from 'use-memo-one'
import {
  Document,
  OrderByArray,
  OrderByType,
  WhereArray,
  WhereItem,
  WhereType,
} from "../types";
import {
  DocumentSnapshot,
  FieldPath,
  Query,
  Timestamp,
  collection,
  collectionGroup,
  endAt,
  endBefore,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { FirestoreDataConverter } from "@sequoiacap/shared/models";
import { collectionCache } from "~/network/swr-firebase/classes/Cache";
import { empty } from "~/network/swr-firebase/helpers/empty";
import { fuego } from "../context";
import {
  hasSubcription,
  registerSubscription,
  unregisterSubscription,
} from "../classes/SubscriptionObject";
import { mutateDocument } from "./use-swr-document";
import getFirebaseDataConverter from "~/network/firebase/firebase-data-converter";

// type Document<T = {}> = T & { id: string }

export type CollectionQueryType<T extends object> = {
  limit?: number;
  orderBy?: OrderByType<T>;
  where?: WhereType<T>;
  isCollectionGroup?: boolean;

  /**
   * For now, this can only be a number, since it has to be JSON serializable.
   *
   */
  startAt?: DocumentSnapshot<T> | string;
  /**
   * For now, this can only be a number, since it has to be JSON serializable.
   *
   */
  endAt?: DocumentSnapshot<T> | string;
  /**
   * For now, this can only be a number, since it has to be JSON serializable.
   *
   */
  startAfter?: DocumentSnapshot<T> | string;
  /**
   * For now, this can only be a number, since it has to be JSON serializable.
   *
   */
  endBefore?: DocumentSnapshot<T> | string;

  // THESE ARE NOT JSON SERIALIZABLE
  // startAt?: number | DocumentSnapshot
  // endAt?: number | DocumentSnapshot
  // startAfter?: number | DocumentSnapshot
  // endBefore?: number | DocumentSnapshot
};

export const getCollection = async <T extends object>(
  path: string,
  // queryString: string = '{}',
  queryConstaints: CollectionQueryType<T> = {},
  {
    converter,
    ignoreFirestoreDocumentSnapshotField = false,
  }: {
    converter: FirestoreDataConverter<T>;
    ignoreFirestoreDocumentSnapshotField: boolean;
  },
): Promise<Document<T>[]> => {
  const ref = createFirestoreRef(path, queryConstaints, converter);
  const startTs = Date.now();
  console.log(`[SWR] getCollection fetching path=${path}`);
  const data: Document<T>[] = await getDocs(ref)
    .then(async (querySnapshot) => {
      const array: Document<T>[] = [];
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const docData = doc.data({ serverTimestamps: "estimate" });
          const docToAdd = {
            id: doc.id,
            path: doc.ref.path,
            data: docData,
            snapshot: ignoreFirestoreDocumentSnapshotField ? undefined : doc,
            s: "fsc",
          };
          // update individual docs in the cache
          // console.log(`[SWR] getCollection updateDoc ${doc.ref.path}`);
          // skip snapshot for this cache

          await mutateStatic(
            doc.ref.path,
            {
              id: doc.id,
              path: doc.ref.path,
              data: docData,
              s: "fsc",
            },
            false,
          ).catch(console.error);
          array.push(docToAdd);
        }),
      );
      console.log(
        `[SWR] getCollection fetched path=${path} length=${array.length} in ${
          Date.now() - startTs
        }ms`,
      );
      return array;
    })
    .catch((err) => {
      console.log("[SWR] getCollection error", path, queryConstaints, err);
      throw err;
    });
  return data;
};

function multipleConditions<Doc extends object = {}>(
  w: WhereType<Doc>,
): w is WhereArray<Doc> {
  return !!(w as WhereArray) && Array.isArray(w[0]);
}

function multipleOrderBy<Doc extends object = {}>(
  o: OrderByType<Doc>,
): o is OrderByArray<Doc>[] {
  return Array.isArray((o as OrderByArray<Doc>[])[0]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseTimestamp(timestamp: any): Timestamp {
  if (
    timestamp instanceof Object &&
    timestamp.seconds !== undefined &&
    timestamp.nanoseconds !== undefined
  ) {
    return Timestamp.fromMillis(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000,
    );
  }
  return timestamp;
}

function createFirestoreRef<T extends object>(
  path: string,
  {
    where: whereQuery,
    orderBy: orderByQuery,
    limit: limitQuery,
    startAt: startAtQuery,
    endAt: endAtQuery,
    startAfter: startAfterQuery,
    endBefore: endBeforeQuery,
    isCollectionGroup,
  }: CollectionQueryType<T>,
  converter: FirestoreDataConverter<T>,
): Query<T> {
  // { isCollectionGroup = false }: { isCollectionGroup?: boolean } = empty.object
  let ref: Query<T> = collection(fuego.db, path).withConverter(
    getFirebaseDataConverter(converter),
  );

  if (isCollectionGroup) {
    ref = collectionGroup(fuego.db, path).withConverter(
      getFirebaseDataConverter(converter),
    );
  }

  if (whereQuery) {
    if (multipleConditions(whereQuery)) {
      const constraints = whereQuery.map((w) => {
        return where(w[0] as string | FieldPath, w[1], parseTimestamp(w[2]));
      });
      ref = query(ref, ...constraints);
    } else if (
      typeof whereQuery[0] === "string" &&
      typeof whereQuery[1] === "string"
    ) {
      ref = query(
        ref,
        where(whereQuery[0], whereQuery[1], parseTimestamp(whereQuery[2])),
      );
    }
  }

  if (orderByQuery) {
    if (typeof orderByQuery === "string") {
      ref = query(ref, orderBy(orderByQuery));
    } else if (Array.isArray(orderByQuery)) {
      if (multipleOrderBy(orderByQuery)) {
        const constraints = orderByQuery.map(([order, direction]) => {
          return orderBy(order as string | FieldPath, direction);
        });
        ref = query(ref, ...constraints);
      } else {
        const [order, direction] = orderByQuery;
        ref = query(ref, orderBy(order as string | FieldPath, direction));
      }
    }
  }

  if (startAtQuery) {
    ref = query(ref, startAt(startAtQuery));
  }

  if (endAtQuery) {
    ref = query(ref, endAt(endAtQuery));
  }

  if (startAfterQuery) {
    ref = query(ref, startAfter(startAfterQuery));
  }

  if (endBeforeQuery) {
    ref = query(ref, endBefore(endBeforeQuery));
  }

  if (limitQuery) {
    ref = query(ref, limit(limitQuery));
  }

  return ref;
}

export type ListenerReturnType<T> = {
  initialData: Document<T>[] | null;
  unsubscribe: ReturnType<typeof onSnapshot>;
};

const createListenerAsync = async <T extends object>(
  path: string,
  queryString: string,
  {
    converter,
    ignoreFirestoreDocumentSnapshotField = false,
  }: {
    converter: FirestoreDataConverter<T>;
    ignoreFirestoreDocumentSnapshotField: boolean;
  },
  id: number,
): Promise<ListenerReturnType<T>> => {
  return new Promise((resolve, reject) => {
    const queryConstraints: CollectionQueryType<T> =
      JSON.parse(queryString) ?? {};
    const ref = createFirestoreRef(path, queryConstraints, converter);
    let startTs: number | undefined = Date.now();
    console.log(`[SWR] onCollectionSnapshot create query listener for ${path}`);
    const subscriptionKey = `[${path}, ${queryString}]`;
    const unsubscribe = onSnapshot(
      ref,
      { includeMetadataChanges: false },
      (querySnapshot) => {
        // Don't skip if there are pending writes
        // let hasPendingWrites = false;
        // querySnapshot.forEach((doc) => {
        //   hasPendingWrites = hasPendingWrites || doc.metadata.hasPendingWrites;
        // });
        // if (hasPendingWrites) {
        //   return;
        // }
        const changes = querySnapshot.docChanges({
          includeMetadataChanges: false,
        });

        changes.forEach((change) => {
          if (change.type === "removed") {
            void mutateDocument(change.doc.ref.path, converter, true);
          } else {
            const docData = change.doc.data({
              serverTimestamps: "estimate",
            });
            mutateStatic(
              change.doc.ref.path,
              {
                id: change.doc.id,
                path: change.doc.ref.path,
                data: docData,
              },
              false,
            ).catch(console.error);
          }
        });

        const data: Document<T>[] = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data({
            serverTimestamps: "estimate",
          });
          const docToAdd = {
            id: doc.id,
            data: docData,
            path: doc.ref.path,
            snapshot: ignoreFirestoreDocumentSnapshotField ? undefined : doc,
            s: "fsc",
          };
          // console.log(`[SWR] onCollectionSnapshot updateDoc ${doc.ref.path}`);
          // update individual docs in the cache

          data.push(docToAdd);
        });

        // resolve initial data
        resolve({
          initialData: data,
          unsubscribe: () => {
            // unregister the subscription, to reduce the reference count
            console.log(
              `[SWR] onCollectionSnapshot unsubscribe [${path}, ${queryString}] ${id}`,
            );
            unregisterSubscription(subscriptionKey, id);
          },
        });
        // update on listener fire
        if (startTs) {
          console.log(
            `[SWR] onCollectionSnapshot updateCollection [${path}, ${queryString}] time=${
              Date.now() - startTs
            }ms`,
          );
          startTs = undefined;
        } else {
          console.log(
            `[SWR] onCollectionSnapshot updateCollection [${path}, ${queryString}] changes=${changes.length}`,
          );
        }

        void mutateStatic([path, queryString], data, false);
      },
      (error) => {
        console.error(
          `[SWR] onCollectionSnapshot error [${path}, ${queryString}]/error`,
          error,
        );
        reject(error);
      },
    );

    // register the subscription, make sure there is one and only one listener for this query
    registerSubscription(subscriptionKey, id, unsubscribe);
  });
};

export async function clearCollectionCache(path: string): Promise<void> {
  await Promise.all(
    collectionCache.getSWRKeysFromCollectionPath(path).map((key) => {
      console.log(`[SWR]clearCollectionCache key=${key} dataKey=${path}`);
      return mutateStatic(key, null);
    }),
  );
}

export type CollectionSWROptions<T> = SWRConfiguration<Document<T>[] | null>;
/**
 * Call a Firestore Collection
 * @template Doc
 * @param path String if the document is ready. If it's not ready yet, pass `null`, and the request won't start yet.
 * @param [queryContraints] - Dictionary with options to query the collection.
 * @param [options] - Dictionary with option `listen`. If true, it will open a socket listener. Also takes any of SWR's options.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCollection = <T extends object>(
  path: string | null,
  queryContraints: CollectionQueryType<T> & {
    /**
     * If `true`, sets up a real-time subscription to the Firestore backend.
     *
     * Default: `false`
     */
    listen?: boolean;
    converter: FirestoreDataConverter<T>;
    /**
     * If `true`, docs returned in `data` will not include the firestore `__snapshot` field. If `false`, it will include a `__snapshot` field. This lets you access the document snapshot, but makes the document not JSON serializable.
     *
     * Default: `true`
     */
    ignoreFirestoreDocumentSnapshotField?: boolean;
  },
  options: CollectionSWROptions<T> = empty.object,
) => {
  const unsubscribeRef = useRef<ListenerReturnType<T>["unsubscribe"] | null>(
    null,
  );

  const {
    where: whereQuery,
    endAt: endAtQuery,
    endBefore: endBeforeQuery,
    startAfter: startAfterQuery,
    startAt: startAtQuery,
    orderBy: orderByQuery,
    limit: limitQuery,
    listen = false,
    converter,
    // __unstableCollectionGroup: isCollectionGroup = false,
    isCollectionGroup,
    ignoreFirestoreDocumentSnapshotField = true,
  } = queryContraints;

  // if we're listening, the firestore listener handles all revalidation
  const {
    refreshInterval = listen ? 0 : undefined,
    refreshWhenHidden = listen ? false : undefined,
    refreshWhenOffline = listen ? false : undefined,
    revalidateOnFocus = listen ? false : undefined,
    revalidateOnReconnect = listen ? false : undefined,
    dedupingInterval = listen ? 0 : undefined,
  } = options;

  const swrOptions = {
    ...options,
    refreshInterval,
    refreshWhenHidden,
    refreshWhenOffline,
    revalidateOnFocus,
    revalidateOnReconnect,
    dedupingInterval,
  };

  // why not just put this into the ref directly?
  // so that we can use the useEffect down below that triggers revalidate()
  const memoQueryString = useMemo(() => {
    const endAtString =
      endAtQuery instanceof DocumentSnapshot ? endAtQuery.ref.path : endAtQuery;
    const endBeforeString =
      endBeforeQuery instanceof DocumentSnapshot
        ? endBeforeQuery.ref.path
        : endBeforeQuery;
    const startAfterString =
      startAfterQuery instanceof DocumentSnapshot
        ? startAfterQuery.ref.path
        : startAfterQuery;
    const startAtString =
      startAtQuery instanceof DocumentSnapshot
        ? startAtQuery.ref.path
        : startAtQuery;
    return JSON.stringify({
      where: whereQuery,
      endAt: endAtString,
      endBefore: endBeforeString,
      startAfter: startAfterString,
      startAt: startAtString,
      orderBy: orderByQuery,
      limit: limitQuery,
      isCollectionGroup,
    });
  }, [
    endAtQuery,
    endBeforeQuery,
    isCollectionGroup,
    limitQuery,
    orderByQuery,
    startAfterQuery,
    startAtQuery,
    whereQuery,
  ]);

  // we move this to a Ref
  // why? because we shouldn't have to include it in the key
  // if we do, then calling mutate() won't be consistent for all
  // collections with the same path & query
  // TODO figure out if this is the right behavior...probably not because of the paths. hm.
  // TODO it's not, move this to the
  // const isCollectionGroupQuery = useRef(isCollectionGroup)
  // useEffect(() => {
  //   isCollectionGroupQuery.current = isCollectionGroup
  // }, [isCollectionGroup])

  // A random number used for subscription id,
  const randomIdRef = useRef(Math.random());
  const converterRef = useRef(converter);
  useEffect(() => {
    converterRef.current = converter;
  }, [converter]);

  // we move listen to a Ref
  // why? because we shouldn't have to include "listen" in the key
  // if we do, then calling mutate() won't be consistent for all
  // collections with the same path & query
  const shouldListen = useRef(listen);
  useEffect(() => {
    shouldListen.current = listen;
  });

  const shouldIgnoreSnapshot = useRef(ignoreFirestoreDocumentSnapshotField);
  useEffect(() => {
    shouldIgnoreSnapshot.current = ignoreFirestoreDocumentSnapshotField;
  }, [ignoreFirestoreDocumentSnapshotField]);

  console.log(
    `useCollection path=${path} query=${memoQueryString}`,
    swrOptions,
  );

  const swr = useSWR<Document<T>[] | null>(
    // if the path is null, this means we don't want to fetch yet.
    path === null ? null : [path, memoQueryString],
    async ([fetcherPath, queryString]: [string, string]) => {
      if (shouldListen.current) {
        if (unsubscribeRef.current) {
          unsubscribeRef.current();
          unsubscribeRef.current = null;
        }
        const { unsubscribe, initialData } = await createListenerAsync<T>(
          fetcherPath,
          queryString,
          {
            converter: converterRef.current,
            ignoreFirestoreDocumentSnapshotField: shouldIgnoreSnapshot.current,
          },
          randomIdRef.current,
        );
        unsubscribeRef.current = unsubscribe;
        return initialData;
      }

      const chunks: CollectionQueryType<T>[] = splitQueryIntoChunks(
        // TODO FIX THIS
        JSON.parse(queryString) as CollectionQueryType<T>,
      );

      const chunkData = await Promise.all(
        chunks.map(async (chunk) => {
          return await getCollection<T>(fetcherPath, chunk, {
            converter: converterRef.current,
            ignoreFirestoreDocumentSnapshotField: shouldIgnoreSnapshot.current,
          });
        }),
      );
      return chunkData.flat();
    },
    swrOptions,
  );

  // if listen or changes
  // we run revalidate.
  // This triggers SWR to fetch again
  // Why? because we don't want to put listen
  // in the useSWR key. If we did, then we couldn't mutate
  // based on query alone. If we had useSWR(['users', true]),
  // but then a `users` fetch with `listen` set to `false` updated, it wouldn't mutate both.
  // thus, we move the `listen` and option to a ref user in `useSWR`,
  // and we call `revalidate` if it changes.
  const mounted = useRef(false);
  useEffect(() => {
    // TODO should this only happen if listen is false? No, BC swr should revalidate on a change.

    if (mounted.current) {
      void revalidateRef.current();
    } else {
      mounted.current = true;
    }
  }, [listen]);

  // this MUST be after the previous effect to avoid duplicate initial validations.
  // only happens on updates, not initial mounting
  const revalidateRef = useRef(swr.mutate);
  useEffect(() => {
    revalidateRef.current = swr.mutate;
  });

  useEffect(() => {
    // TODO should this only be for listen, since SWR updates with the others?
    // also should it go before the useSWR?
    return () => {
      // clean up listener on unmount if it exists
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = null;
      }
    };
    // should depend on the path, queyr, and listen being the same...
  }, [path, listen, memoQueryString]);

  // add the collection to the cache,
  // so that we can mutate it from document calls later
  useEffect(() => {
    if (path) collectionCache.addCollectionToCache(path, memoQueryString);
  }, [path, memoQueryString]);

  const { data, error, mutate } = swr;

  // When there is data and we are listening to the collection,
  // make sure there is a listener on the collection.
  // If there is no listener, call mutate would create a new listener
  // When there is a listener, we register to the subscription
  useEffect(() => {
    if (data && listen && path && memoQueryString) {
      const subscriptionKey = `[${path}, ${memoQueryString}]`;
      if (!hasSubcription(subscriptionKey)) {
        void mutate();
      } else {
        registerSubscription(subscriptionKey, randomIdRef.current);
      }
    }
  }, [data, listen, memoQueryString, mutate, path]);

  // Unregister from the subscription when unmountingj:w
  useEffect(() => {
    if (listen && path && memoQueryString) {
      const subscriptionKey = `[${path}, ${memoQueryString}]`;
      const id = randomIdRef.current;
      return () => {
        unregisterSubscription(subscriptionKey, id);
      };
    }
  }, [listen, memoQueryString, path]);

  // /**
  //  * `add(data)`: Extends the Firestore document [`add` function](https://firebase.google.com/docs/firestore/manage-data/add-data).
  //  * - It also updates the local cache using SWR's `mutate`. This will prove highly convenient over the regular `add` function provided by Firestore.
  //  */
  // const add = useCallback(
  //   (addData: T | T[]) => {
  //     if (!path) return null;

  //     const dataArray = Array.isArray(addData) ? addData : [addData];

  //     const ref = fuego.db.collection(path);

  //     const docsToAdd: Document<T>[] = dataArray.map((doc) => ({
  //       data: doc,
  //       // generate IDs we can use that in the local cache that match the server
  //       id: ref.doc().id,
  //       snapshot: undefined,
  //     })); // solve this annoying TS bug 😅

  //     // add to cache
  //     if (!listen) {
  //       // we only update the local cache if we don't have a listener set up
  //       // why? because Firestore automatically handles this part for subscriptions
  //
  //       mutate((prevState) => {
  //         const state = prevState ?? empty.array;
  //         return [...state, ...docsToAdd];
  //       }, false);
  //     }

  //     // add to network
  //     const batch = fuego.db.batch();

  //     docsToAdd.forEach(({ id: id, ...doc }) => {
  //       // take the ID out of the document
  //       batch.set(ref.doc(id), doc);
  //     });

  //     return batch.commit();
  //   },
  //   [listen, mutate, path]
  // );
  // const startTsRef = useRef(Date.now());
  // const loading = !!path && !data && !error;
  // useEffect(() => {
  //   if (!loading && path) {
  //     const endTs = Date.now();
  //     const duration = endTs - startTsRef.current;
  //     console.log(
  //       `HEREEEEEEEE readCollection ${path} ${memoQueryString} duration=${duration} listen=${shouldListen.current}`
  //     );
  //   }
  // }, [loading, path, memoQueryString]);

  return {
    data,
    error,
    mutate,
    isValidating: swr.isValidating,
    loading: !!path && !data && !error,
    /**
     * A function that, when called, unsubscribes the Firestore listener.
     *
     * The function can be null, so make sure to check that it exists before calling it.
     *
     * Note: This is not necessary to use. `useCollection` already unmounts the listener for you. This is only intended if you want to unsubscribe on your own.
     */
    //unsubscribe: unsubscribeRef.current,
  };
};

function splitQueryIntoChunks<T extends object>(
  collectionQuery: CollectionQueryType<T>,
): CollectionQueryType<T>[] {
  let result = [collectionQuery];
  if (!collectionQuery.where || collectionQuery.where.length === 0) {
    return result;
  }

  if (!multipleConditions(collectionQuery.where)) {
    collectionQuery.where = [collectionQuery.where];
  }

  for (let i = 0; i < collectionQuery.where.length; i++) {
    const condition = collectionQuery.where[i];
    if (condition[1] === "in") {
      result = splitConditionIntoChunks(result, i, condition);
    }
  }

  return result;
}

function splitConditionIntoChunks<T extends object>(
  collectionQueries: CollectionQueryType<T>[],
  i: number,
  whereItem: WhereItem<T>,
): CollectionQueryType<T>[] {
  const values = whereItem[2];
  if (!Array.isArray(values) || values.length <= 10) {
    return collectionQueries;
  }
  return collectionQueries
    .map((collectionQuery) => {
      return arrayChunk(values, 10).map((chunk) => {
        const newQuery = {
          ...collectionQuery,
          where: collectionQuery.where?.slice(),
        } as CollectionQueryType<T>;
        if (newQuery.where && newQuery.where[i]) {
          newQuery.where[i] = [whereItem[0], whereItem[1], chunk];
        }
        return newQuery;
      });
    })
    .flat();
}

function arrayChunk<T>(array: T[], chunkSize = 10) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}
