import {
  ReadDocumentOptions,
  useReadDocument,
  useReadQuery,
} from "./firebase/FirebaseAPI";
import { ReadDocumentReturnType, ReadQueryReturnType } from "./firebase/types";
import {
  User,
  UserList,
  userListDataConverter,
} from "@sequoiacap/shared/models";
import { useAPIGetUsersByIds } from "./user-api";

export function useAPIGetUserLists(
  userId?: string | null,
): ReadDocumentReturnType<UserList[]> {
  const { data, error } = useReadQuery(
    userId ? "user_list" : null,
    userListDataConverter,
    undefined,
    undefined,
    [["user", "array-contains", userId]],
  );
  return {
    data: data,
    loading: !!userId && data === undefined,
    error,
  };
}

export function useAPIGetUserList(
  userListId: string | null | undefined,
  options: ReadDocumentOptions<UserList> = {},
): ReadDocumentReturnType<UserList> {
  return useReadDocument(
    userListId ? `user_list/${userListId}` : null,
    userListDataConverter,
    options,
  );
}

export function useAPIGetPopulatedUserList(
  userListId: string | null | undefined,
  options: ReadDocumentOptions<UserList> = {},
): ReadQueryReturnType<User> {
  const {
    data: userList,
    loading,
    error,
  } = useReadDocument(
    userListId ? `user_list/${userListId}` : null,
    userListDataConverter,
    options,
  );

  const userListResult = useAPIGetUsersByIds(userList?.user);

  if (userList) {
    return userListResult;
  }

  return {
    loading,
    error,
  };
}
