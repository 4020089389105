import { StorageTimestamp } from "./base/data-type";
import { UserAudience } from "./user";
import FirestoreDataConverter from "./base/data-converter";

export class RetoolGroup {
  id: string;
  name: string;
  createdAt: StorageTimestamp;
  updatedAt?: StorageTimestamp;
  domain?: string[];
  audience?: UserAudience[];
  users?: string[];
  companyIds?: string[];

  constructor(
    id: string,
    name: string,
    createdAt: StorageTimestamp,
    updatedAt?: StorageTimestamp,
    domain?: string[],
    audience?: UserAudience[],
    users?: string[],
    companyIds?: string[],
  ) {
    this.id = id;
    this.name = name;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.domain = domain;
    this.audience = audience;
    this.users = users;
    this.companyIds = companyIds;
  }
}

export const retoolGroupDataConverter: FirestoreDataConverter<RetoolGroup> = {
  toFirestoreModel: function (retoolGroup: RetoolGroup) {
    return {
      id: retoolGroup.id,
      name: retoolGroup.name,
      created_at: retoolGroup.createdAt,
      updated_at: retoolGroup.updatedAt,
      domain: retoolGroup.domain,
      audience: retoolGroup.audience,
      user: retoolGroup.users,
      company_id: retoolGroup.companyIds,
    };
  },

  fromFirestoreModel: function (data): RetoolGroup {
    return new RetoolGroup(
      data.id,
      data.name,
      data.created_at,
      data.updated_at,
      data.domain,
      data.audience,
      data.user,
      data.company_id,
    );
  },
};
