import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

type TokenStatus = "ready" | "revoked" | "expired";
export class EventOAuthToken {
  id: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  tokens: Record<string, string | number>;
  status: TokenStatus;
  watch?: {
    id: string;
    resourceId: string;
    expireAt: StorageTimestamp;
  };

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    tokens: Record<string, string | number>,
    status: TokenStatus,
    watch?: {
      id: string;
      resourceId: string;
      expireAt: StorageTimestamp;
    },
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.tokens = tokens;
    this.status = status;
    this.watch = watch;
  }
}

export const eventOAuthTokenDataConverter: FirestoreDataConverter<EventOAuthToken> =
  {
    toFirestoreModel: function (token: EventOAuthToken) {
      const watch = token.watch
        ? {
            id: token.watch.id,
            expire_at: token.watch.expireAt,
            resource_id: token.watch.resourceId,
          }
        : undefined;
      return {
        id: token.id,
        created_at: token.createdAt,
        updated_at: token.updatedAt,
        tokens: token.tokens,
        status: token.status,
        watch: watch,
      };
    },
    fromFirestoreModel: function (data): EventOAuthToken {
      const watch = data.watch
        ? {
            id: data.watch.id,
            expireAt: data.watch.expire_at,
            resourceId: data.watch.resource_id,
          }
        : undefined;
      return new EventOAuthToken(
        data.id,
        data.created_at,
        data.updated_at,
        data.tokens,
        data.status,
        watch,
      );
    },
  };
