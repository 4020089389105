import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class UserList {
  id: string;
  name: string;
  user: string[];
  createdAt: StorageTimestamp;
  modifiable: boolean;
  updatedAt: StorageTimestamp;
  description?: string;

  constructor(
    id: string,
    name: string,
    user: string[],
    createdAt: StorageTimestamp,
    modifiable: boolean,
    updatedAt: StorageTimestamp,
    description?: string,
  ) {
    this.id = id;
    this.name = name;
    this.user = user;
    this.createdAt = createdAt;
    this.modifiable = modifiable;
    this.updatedAt = updatedAt;
    this.description = description;
  }
}

export const userListDataConverter: FirestoreDataConverter<UserList> = {
  toFirestoreModel: function (userList: UserList) {
    return {
      id: userList.id,
      name: userList.name,
      user: userList.user,
      created_at: userList.createdAt,
      modifiable: userList.modifiable,
      updated_at: userList.updatedAt,
      description: userList.description,
    };
  },
  fromFirestoreModel: function (data): UserList {
    return new UserList(
      data.id,
      data.name,
      data.user,
      data.created_at,
      data.modifiable,
      data.updated_at,
      data.description,
    );
  },
};
