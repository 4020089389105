export class TimeoutError extends Error {}

/**
 * https://javascript.plainenglish.io/how-to-add-a-timeout-limit-to-asynchronous-javascript-functions-3676d89c186d
 *
 * Call an async function with a maximum time limit (in milliseconds) for the timeout
 * @param {Promise<any>} asyncPromise An asynchronous promise to resolve
 * @param {number} timeLimit Time limit to attempt function in milliseconds
 * @returns {Promise<any> | undefined} Resolved promise for async function call, or an error if time limit reached
 */
export async function asyncCallWithTimeout<T>(
  asyncPromise: Promise<T>,
  timeLimit: number,

  /**
   * String for debugging who set this async timeout.
   *
   * Rejection happens in a timeout promise, which has lost context of the caller (aka which request timed out).
   * So this string is used to identify the caller in Sentry, etc.
   */
  contextDescription: string,
): Promise<T> {
  let timeoutHandle: ReturnType<typeof setTimeout>;

  const timeoutPromise = new Promise<T>((_resolve, reject) => {
    timeoutHandle = setTimeout(
      () =>
        reject(
          new TimeoutError(
            `Async call timeout limit reached: ${contextDescription}`,
          ),
        ),
      timeLimit,
    );
  });

  return Promise.race([asyncPromise, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
}
