import {
  Attachment,
  convertAttachmenstFromFirebaseModel,
  convertAttachmentsToFirebaseModel,
} from "./post";
import { StorageTimestamp } from "./base/data-type";
import { asEnum } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum DealType {
  memo = "memo",
  opportunity = "opportunity",
  message = "message",
  unknown = "unknown",
}

export enum DueDiligenceState {
  pending = "pending",
  completed = "completed",
  unknown = "unknown",
}

export enum ApprovalState {
  /**
   * Pre-memo. No formal process to move to full memo; scout needs to complete more, to make it a full memo. Same as "Request Feedback"
   */
  requestFeedback = "request_feedback",
  /**
   * A full memo, waiting for final approval. Same as "Memo Approval request"
   */
  pending = "pending",
  /**
   * Approved memo, may or may not have completed Due Diligence.
   */
  approved = "approved",
  /**
   * Rejected for various reasons. Currently done via Firebase Console only
   */
  rejected = "rejected",
  unknown = "unknown",
}

/** Those who run the Scouts program. This excludes legal + finance (differs from "internal_scout_team"). These folks can approve on behalf of investors */
export const SCOUT_SERVICE = "scout_services";

/** Sequoia employees added to all new Investor<>Scout{,s} groups. This includes "scout_service", legal, finance, etc. */
export const INTERNAL_SCOUT_TEAM = "internal_scout_team";

export const NON_US_SCOUT_FINANCE = "non_us_scout_finance";

export type ApprovalStateChange = {
  from?: ApprovalState;
  to: ApprovalState;
  createdById: string;
  createdAt: StorageTimestamp;
};

export enum DealWarningType {
  multipleScouts = "multiple_scouts",
  unknown = "unknown",
}

export type DealWarning = {
  type: DealWarningType;
  message: string;
};

export class DealSharing {
  id: string;
  createdById: string;
  dealType: DealType;
  investorId: string;
  meta: Record<string, any>;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  dueDiligenceState?: DueDiligenceState;
  dueDiligenceStateUpdatedAt?: StorageTimestamp;
  approvalState?: ApprovalState;
  approvalStateUpdatedAt?: StorageTimestamp;
  approvedById?: string;
  approvalStateChanges?: ApprovalStateChange[];
  groupId?: string;
  dueDiligence?: Record<string, string>;
  dueDiligenceUpdatedAt?: StorageTimestamp;
  memo?: string;
  uploadedFiles?: Attachment[];
  smsNoteId?: string;
  smsCompanyId?: string;
  smsPendingNoteId?: string;
  safInvestment?: boolean;
  dealWarnings?: DealWarning[];

  constructor(
    id: string,
    createdById: string,
    dealType: DealType,
    investorId: string,
    meta: Record<string, any>,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    dueDiligenceState?: DueDiligenceState,
    dueDiligenceStateUpdatedAt?: StorageTimestamp,
    approvalState?: ApprovalState,
    approvalStateUpdatedAt?: StorageTimestamp,
    approvedById?: string,
    groupId?: string,
    /** NOTE: iOS requires `Record<string,string>` for unpacking */
    dueDiligence?: Record<string, string>,
    dueDiligenceUpdatedAt?: StorageTimestamp,
    memo?: string,
    uploadedFiles?: Attachment[],
    smsNoteId?: string,
    smsCompanyId?: string,
    smsPendingNoteId?: string,
    approvalStateChanges?: ApprovalStateChange[],
    safInvestment?: boolean,
    dealWarnings?: DealWarning[],
  ) {
    this.id = id;
    this.createdById = createdById;
    this.dealType = dealType;
    this.investorId = investorId;
    this.meta = meta;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.dueDiligenceState = dueDiligenceState;
    this.dueDiligenceStateUpdatedAt = dueDiligenceStateUpdatedAt;
    this.approvalState = approvalState;
    this.approvalStateUpdatedAt = approvalStateUpdatedAt;
    this.approvedById = approvedById;
    this.groupId = groupId;
    this.dueDiligence = dueDiligence;
    this.dueDiligenceUpdatedAt = dueDiligenceUpdatedAt;
    this.memo = memo;
    this.uploadedFiles = uploadedFiles;
    this.smsNoteId = smsNoteId;
    this.smsCompanyId = smsCompanyId;
    this.smsPendingNoteId = smsPendingNoteId;
    this.approvalStateChanges = approvalStateChanges;
    this.safInvestment = safInvestment;
    this.dealWarnings = dealWarnings;
  }
}

export const dealSharingConverter: FirestoreDataConverter<DealSharing> = {
  toFirestoreModel: function (deal: DealSharing) {
    const uploadedFiles = convertAttachmentsToFirebaseModel(deal.uploadedFiles);
    return {
      id: deal.id,
      created_by_id: deal.createdById,
      deal_type: deal.dealType,
      investor_id: deal.investorId,
      meta: deal.meta,
      created_at: deal.createdAt,
      updated_at: deal.updatedAt,
      due_diligence_state: deal.dueDiligenceState,
      due_diligence_state_updated_at: deal.dueDiligenceStateUpdatedAt,
      approval_state: deal.approvalState,
      approval_state_updated_at: deal.approvalStateUpdatedAt,
      approved_by_id: deal.approvedById,
      group_id: deal.groupId,
      due_diligence: deal.dueDiligence,
      due_diligence_updated_at: deal.dueDiligenceUpdatedAt,
      memo: deal.memo,
      uploaded_file: uploadedFiles,
      sms_note_id: deal.smsNoteId,
      sms_company_id: deal.smsCompanyId,
      sms_pending_note_id: deal.smsPendingNoteId,
      approval_state_change: deal.approvalStateChanges?.map(
        (approvalStateChange) =>
          approvalStateChangeConverter.toFirestoreModel(approvalStateChange),
      ),
      saf_investment: deal.safInvestment,
      deal_warning: deal.dealWarnings,
    };
  },
  fromFirestoreModel: function (data): DealSharing {
    const uploadedFiles = convertAttachmenstFromFirebaseModel(
      data.uploaded_file,
    );

    let approvalStateChanges: ApprovalStateChange[] | undefined = undefined;
    if (data.approval_state_change) {
      approvalStateChanges = data.approval_state_change.map(
        (approval_state_change: Record<string, unknown>) =>
          approvalStateChangeConverter.fromFirestoreModel(
            approval_state_change,
          ),
      );
    }
    return new DealSharing(
      data.id,
      data.created_by_id,
      asEnum(DealType, data.deal_type, DealType.unknown),
      data.investor_id,
      data.meta,
      data.created_at,
      data.updated_at,
      asEnum(
        DueDiligenceState,
        data.due_diligence_state,
        DueDiligenceState.unknown,
      ),
      data.due_diligence_state_updated_at,
      asEnum(ApprovalState, data.approval_state, ApprovalState.unknown),
      data.approval_state_updated_at,
      data.approved_by_id,
      data.group_id,
      data.due_diligence,
      data.due_diligence_updated_at,
      data.memo,
      uploadedFiles,
      data.sms_note_id,
      data.sms_company_id,
      data.sms_pending_note_id,
      approvalStateChanges,
      data.saf_investment,
      data.deal_warning?.map((warning: Record<string, string>) => {
        return {
          type: asEnum(DealWarningType, warning.type, DealWarningType.unknown),
          message: warning.message,
        };
      }),
    );
  },
};

const approvalStateChangeConverter: FirestoreDataConverter<ApprovalStateChange> =
  {
    toFirestoreModel: function (approvalStateChange: ApprovalStateChange) {
      return {
        ...(approvalStateChange.from && { from: approvalStateChange.from }),
        to: approvalStateChange.to,
        created_by_id: approvalStateChange.createdById,
        created_at: approvalStateChange.createdAt,
      };
    },
    fromFirestoreModel: function (data): ApprovalStateChange {
      return {
        ...(data.from && { from: data.from }),
        to: data.to,
        createdById: data.created_by_id,
        createdAt: data.created_at,
      };
    },
  };
