export enum Sector {
  All = "All",
  Consumer = "consumer",
  Enterprise = "enterprise",
  //  Fintech = "Fintech",
  Healthcare = "healthcare",
  //  Crypto = "Crypto",

  // Emerging = "emerging",
  // Investment = "investment",
  // Nonprofit = "nonprofit",
  // Government = "government",
  // Consulting = "consulting",
}
