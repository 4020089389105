export function omitUndefined(obj: any): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.filter((item) => item !== undefined).map(omitUndefined);
  }

  console.log("obj", obj);

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    console.log("obj 2", key, value);
    if (typeof value === "object") {
      obj[key] = omitUndefined(value);
    } else if (value === undefined) {
      delete obj[key];
    }
  });

  return obj;
}
