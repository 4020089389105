import { TrackEvent, track } from "~/utils/analytics";
import { WorkboxLifecycleEvent } from "workbox-window";
import { WorkerCommand } from "~/worker-command";
import { asyncCallWithTimeout } from "@sequoiacap/shared/utils/asyncCallWithTimeout";
import { getWorkbox } from "~/utils/getWorkbox";
import { isFromAmpersandWebVC } from "~/network/ampersand-web-vc-bridge";
import { useCallback, useEffect, useState } from "react";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

function useSetupWorkbox(ready: boolean): {
  showNewVersionToast: boolean;
  onDismissToast: (accept: boolean) => void;
} {
  const [showNewVersionToast, setShowNewVersionToast] = useState(false);
  const onDismissToast = useCallback((accept: boolean) => {
    setShowNewVersionToast(false);
    track(TrackEvent.newSWRefreshClicked, {
      webVersion: publicRuntimeConfig.BUILD_NUMBER,
      accept,
    });
    if (accept) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (!ready) {
      return;
    }
    const inIframe = !!window.frameElement;
    if (inIframe) {
      console.log(`[wb] in iframe, don't need to setup`);
      return;
    }
    const wb = getWorkbox();
    if (!wb) {
      console.log(`[wb] missing wb`);
      return;
    }

    // add event listeners to handle any of PWA lifecycle event
    // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
    wb.addEventListener("installed", (event) => {
      console.log(`[wb] Event ${event.type} is triggered.`, event);
    });

    wb.addEventListener("controlling", (event) => {
      console.log(`[wb] Event ${event.type} is triggered.`, event);
    });

    wb.addEventListener("activated", (event) => {
      console.log(`[wb] Event ${event.type} is triggered.`, event);
    });

    // const promptForUpdate = async () => {
    //   return new Promise<boolean>((resolve) => {
    //     const updateAccept = confirm(
    //       "A new version of this app is available. Do you want to reload the app to use the latest version?"
    //     );
    //     resolve(updateAccept);
    //   });
    // };

    // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
    // NOTE: MUST set skipWaiting to false in next.config.js pwa object
    // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
    // const showSkipWaitingPrompt = async (event: WorkboxLifecycleEvent) => {
    //   console.log(`[wb] showSkipWaitingPrompt`, event);
    //   wb.addEventListener("controlling", () => {
    //     window.location.reload();
    //   });

    //   const updateAccept = await promptForUpdate();
    //   if (updateAccept) {
    //     // Send a message to the waiting service worker, instructing it to activate.
    //     wb.messageSkipWaiting();
    //   } else {
    //     console.log(
    //       "User rejected to reload the web app, keep using old version. New version will be automatically load when user open the app next time."
    //     );
    //   }
    // };
    const waitingListener = async (event: WorkboxLifecycleEvent) => {
      let getVersionResult: any;
      try {
        getVersionResult = await asyncCallWithTimeout(
          wb?.messageSW({
            command: WorkerCommand.GetVersion,
          }) ?? Promise.resolve(null),
          1000,
          "getVersion",
        );
      } catch (err) {
        getVersionResult = undefined;
      }
      if (isFromAmpersandWebVC()) {
        // Update all client silently in the backend
        console.log(
          `[wb] detected new version. AutoUpdating within AWVC`,
          event,
        );
        await wb?.update();
        await wb?.register({ immediate: true });
        wb.addEventListener("controlling", () => {
          window.location.reload();
        });
        wb?.messageSkipWaiting();
      } else {
        console.log(`[wb] messageSkipWaiting and wait for controlling`, event);
        wb.addEventListener("controlling", () => {
          track(TrackEvent.newSWRefrechShown, {
            webVersion: publicRuntimeConfig.BUILD_NUMBER,
            swVersion: getVersionResult?.data,
          });
          setShowNewVersionToast(true);
        });
        wb?.messageSkipWaiting();
      }
    };
    wb.addEventListener("waiting", waitingListener);

    // never forget to call register as auto register is turned off in next.config.js
    void wb.register();

    console.log(`[wb] setup done`);
    return () => {
      console.log(`[wb] unload listener`);
      wb.removeEventListener("waiting", waitingListener);
    };
  }, [ready]);

  return {
    // From AWVC, our js should not setShowNewVersionToast(true); – but this is extra cautious
    showNewVersionToast: isFromAmpersandWebVC() ? false : showNewVersionToast,
    onDismissToast,
  };
}

export default useSetupWorkbox;
