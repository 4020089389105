import * as React from "react";
import { forwardRef } from "react";
const TicketStarIcon = ({
  title,
  titleId,
  ...props
}, ref) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", fill: "currentColor", "aria-hidden": "true", width: 24, height: 24, ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("mask", { id: "a", width: 20, height: 19, x: 2, y: 3, maskUnits: "userSpaceOnUse", style: {
  maskType: "luminance"
} }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "M2 3h20v18.166H2V3Z", clipRule: "evenodd" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#a)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "M3.5 14.98v2.044c0 .706.274 1.37.773 1.868a2.626 2.626 0 0 0 1.868.774h11.716a2.646 2.646 0 0 0 2.643-2.64V14.98a2.996 2.996 0 0 1-2.24-2.893c0-1.39.953-2.56 2.239-2.895l.001-2.045A2.645 2.645 0 0 0 17.861 4.5H6.144A2.647 2.647 0 0 0 3.5 7.142V9.26c.486.116.937.355 1.312.705.569.531.897 1.252.925 2.03.003 1.466-.95 2.65-2.237 2.986Zm14.357 6.186H6.142a4.115 4.115 0 0 1-2.93-1.213A4.117 4.117 0 0 1 2 17.025v-2.7a.75.75 0 0 1 .75-.75 1.49 1.49 0 0 0 1.489-1.49 1.427 1.427 0 0 0-.45-1.024 1.36 1.36 0 0 0-1.013-.377.766.766 0 0 1-.547-.21.753.753 0 0 1-.229-.54v-2.79A4.15 4.15 0 0 1 6.144 3h11.712A4.148 4.148 0 0 1 22 7.147v2.7a.75.75 0 0 1-.75.75c-.821 0-1.49.668-1.49 1.488.001.821.669 1.49 1.49 1.49a.75.75 0 0 1 .75.75v2.7a4.149 4.149 0 0 1-4.143 4.142Z", clipRule: "evenodd" })), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", d: "m10.104 11.382.509.495c.276.271.4.657.335 1.034l-.12.699.629-.332c.339-.18.746-.18 1.088.002l.625.329-.12-.7a1.17 1.17 0 0 1 .336-1.032l.509-.495-.705-.102a1.16 1.16 0 0 1-.875-.638L12 10.005l-.315.637a1.162 1.162 0 0 1-.877.638l-.704.102Zm3.514 4.005a1.16 1.16 0 0 1-.544-.136L12 14.688l-1.075.564a1.16 1.16 0 0 1-1.227-.088 1.16 1.16 0 0 1-.463-1.139l.205-1.196-.87-.847a1.158 1.158 0 0 1-.295-1.191c.138-.425.498-.73.939-.794l1.204-.176.537-1.09c.197-.4.597-.648 1.045-.648.446 0 .847.249 1.044.65l.538 1.088 1.202.176c.442.063.803.369.94.793.137.425.024.882-.296 1.194l-.87.847.206 1.194a1.164 1.164 0 0 1-1.146 1.362Z", clipRule: "evenodd" }));
const ForwardRef = forwardRef(TicketStarIcon);
export default ForwardRef;
