import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class LinkedInExtensionSetting {
  id: string;
  updatedAt: StorageTimestamp;
  sequoiaPartnershipsUsername: string;
  sequoiaPartnershipsUserId: string;

  constructor(
    id: string,
    updatedAt: StorageTimestamp,
    sequoiaPartnershipsUsername: string,
    sequoiaPartnershipsUserId: string,
  ) {
    this.id = id;
    this.updatedAt = updatedAt;
    this.sequoiaPartnershipsUsername = sequoiaPartnershipsUsername;
    this.sequoiaPartnershipsUserId = sequoiaPartnershipsUserId;
  }
}

export const linkedInExtensionSettingDataConverter: FirestoreDataConverter<LinkedInExtensionSetting> =
  {
    toFirestoreModel: (model: LinkedInExtensionSetting) => {
      return {
        id: model.id,
        updated_at: model.updatedAt,
        sequoia_partnerships_username: model.sequoiaPartnershipsUsername,
        sequoia_partnerships_user_id: model.sequoiaPartnershipsUserId,
      };
    },
    fromFirestoreModel: (data): LinkedInExtensionSetting => {
      return new LinkedInExtensionSetting(
        data.id,
        data.updated_at,
        data.sequoia_partnerships_username,
        data.sequoia_partnerships_user_id,
      );
    },
  };
