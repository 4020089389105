import { StorageTimestamp } from "./base/data-type";
import { User } from "./user";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum ConnectionType {
  /** DM on Ampersand */
  direct = "direct",
  SequoiaInteraction = "SequoiaInteraction",
  work = "work",
  education = "education",
  linkedin = "linkedin",
  firstDegreeLinkedIn = "firstDegreeLinkedIn",

  // client only
  askSequoia = "askSequoia",
  noConnections = "noConnections",
}

export enum TalentPipelineStatusType {
  Unavailable = "unavailable",
  Empty = "empty",
  Populated = "populated",
}

export enum VouchType {
  extension = "via-extension",
  sheet = "via-sheet",
  none = "none",
}

export class ProfileInsightConnection {
  linkedInSlug?: string;
  name?: string;
  userId: string;
  reason: string;
  type: ConnectionType;

  // for client caching
  user?: User;

  constructor(
    userId: string,
    reason: string,
    type: ConnectionType,
    linkedInSlug?: string,
    name?: string,
  ) {
    this.userId = userId;
    this.reason = reason;
    this.type = type;
    this.linkedInSlug = linkedInSlug;
    this.name = name;
  }
}

export class LinkedInProfileInsight {
  /**
   * <userId>_<linkedInSlug>
   */
  id: string;
  /**
   * Viewer's ampersand user id,
   */
  userId: string;
  /**
   * Target LinkedIn slug,
   */
  linkedInSlug: string;
  /**
   * Target's ampersand user id,
   */
  ampersandUserId?: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  expiresAt?: StorageTimestamp;
  hasPositiveSignal: boolean;
  shouldShowVouch: boolean;
  getMyVouchForProfile: VouchType;
  vouchedText?: string;
  notables: string[];
  slope: string[];
  sector: string[];
  stage: string[];

  talentPipelineStatus: TalentPipelineStatusType;
  talentPipelineIframe?: string;
  stageHeatmap?: Record<string, number>;

  connections?: ProfileInsightConnection[];

  constructor(
    id: string,
    userId: string,
    linkedInSlug: string,
    ampersandUserId: string | undefined,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    expiresAt: StorageTimestamp,
    hasPositiveSignal: boolean,
    shouldShowVouch: boolean,
    getMyVouchForProfile: VouchType,
    vouchedText: string,
    notables: string[],
    slope: string[],
    sector: string[],
    stage: string[],
    talentPipelineStatus: TalentPipelineStatusType,
    talentPipelineIframe: string,
    connections: ProfileInsightConnection[],
    stageHeatmap?: Record<string, number>,
  ) {
    this.id = id;
    this.userId = userId;
    this.linkedInSlug = linkedInSlug;
    this.ampersandUserId = ampersandUserId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.expiresAt = expiresAt;
    this.hasPositiveSignal = hasPositiveSignal;
    this.shouldShowVouch = shouldShowVouch;
    this.getMyVouchForProfile = getMyVouchForProfile;
    this.vouchedText = vouchedText;
    this.notables = notables;
    this.slope = slope;
    this.sector = sector;
    this.stage = stage;
    this.talentPipelineStatus = talentPipelineStatus;
    this.talentPipelineIframe = talentPipelineIframe;
    this.connections = connections;
    this.stageHeatmap = stageHeatmap;
  }
}

export const profileInsightConnectionConverter: FirestoreDataConverter<ProfileInsightConnection> =
  {
    toFirestoreModel: function (connection: ProfileInsightConnection) {
      return {
        user_id: connection.userId,
        reason: connection.reason,
        type: connection.type,
        linkedin_slug: connection.linkedInSlug,
        name: connection.name,
      };
    },
    fromFirestoreModel: function (data: any) {
      return new ProfileInsightConnection(
        data.user_id,
        data.reason,
        data.type,
        data.linkedin_slug,
        data.name,
      );
    },
  };

export const linkedInProfileInsightConverter: FirestoreDataConverter<LinkedInProfileInsight> =
  {
    toFirestoreModel: function (profileInsight: LinkedInProfileInsight) {
      return {
        id: profileInsight.id,
        user_id: profileInsight.userId,
        linkedin_slug: profileInsight.linkedInSlug,
        ampersand_user_id: profileInsight.ampersandUserId,
        created_at: profileInsight.createdAt,
        updated_at: profileInsight.updatedAt,
        expires_at: profileInsight.expiresAt,
        has_positive_signal: profileInsight.hasPositiveSignal,
        should_show_vouch: profileInsight.shouldShowVouch,
        get_my_vouch_for_profile: profileInsight.getMyVouchForProfile,
        vouched_text: profileInsight.vouchedText,
        notables: profileInsight.notables,
        slope: profileInsight.slope,
        sector: profileInsight.sector,
        stage: profileInsight.stage,
        talent_pipeline_status: profileInsight.talentPipelineStatus,
        talent_pipeline_iframe: profileInsight.talentPipelineIframe,
        stage_heatmap: profileInsight.stageHeatmap,
        connections: profileInsight.connections?.map((connection) =>
          profileInsightConnectionConverter.toFirestoreModel(connection),
        ),
      };
    },
    fromFirestoreModel: function (data: any) {
      return new LinkedInProfileInsight(
        data.id,
        data.user_id,
        data.linkedin_slug,
        data.ampersand_user_id,
        data.created_at,
        data.updated_at,
        data.expires_at,
        data.has_positive_signal,
        data.should_show_vouch,
        VouchType[
          getEnumKeyByEnumValue(VouchType, data.get_my_vouch_for_profile) ??
            VouchType.none
        ],
        data.vouched_text,
        data.notables,
        data.slope,
        data.sector,
        data.stage,
        data.talent_pipeline_status,
        data.talent_pipeline_iframe,
        data.connections.map(
          profileInsightConnectionConverter.fromFirestoreModel,
        ),
        data.stage_heatmap,
      );
    },
  };
