import { Text } from "@sequoiacap/client-ui";
import { TrackErrorEvent, trackError } from "~/utils/analytics";
import { useEffect } from "react";
import Head from "next/head";
import classNames from "classnames";
import styles from "./ErrorComponent.module.scss";

export type ErrorProps = {
  statusCode: number;
  title?: string;
  subtitle?: React.ReactNode;
  fullPage?: boolean; // Default true
  error: Error;
};

function ErrorComponent({
  fullPage = true,
  ...props
}: ErrorProps): JSX.Element {
  let code = 404;
  let defaultTitle = "Page Not Found";

  if (props.statusCode === 404) {
    code = 404;
    defaultTitle = "Page Not Found";
  } else if (props.statusCode === 403) {
    code = 403;
    defaultTitle = "Forbidden";
  } else if (props.statusCode === 500) {
    code = 500;
    defaultTitle = "Internal Server Error";
  } else {
    // TODO add more error code;
  }
  const title = props.title ?? `${code} - ${defaultTitle}`;

  useEffect(() => {
    trackError(TrackErrorEvent.errorComponent, props.error, {
      code: code.toString(),
      title,
    });
  }, [code, props.error, title]);

  return (
    <>
      <Head>
        <title>{title} - Ampersand</title>
      </Head>
      <div
        className={classNames(styles.container, fullPage && styles.fullPage)}
      >
        <Text as="h1" variant="header4" className={styles.title}>
          {title}
        </Text>
        <p className={classNames(styles.subtitle, "subtitle3")}>
          {props.subtitle}
        </p>
      </div>
    </>
  );
}

export default ErrorComponent;
