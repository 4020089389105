import FirestoreDataConverter from "./base/data-converter";

export class TopicHierarchy {
  lvl1: string[] | undefined;
  lvl2: string[] | undefined;

  /** Consider using `newTopicHierarchy` instead, which is safer. */
  constructor(lvl1: [string] | undefined, lvl2: [string] | undefined) {
    this.lvl1 = lvl1;
    this.lvl2 = lvl2;
  }
}

/**
 * We don't currently have any cases that explicitly need conversion, but we
 * use this converter to make it safer to add to later.
 */
export const topicHierarchyConverter: FirestoreDataConverter<TopicHierarchy> = {
  toFirestoreModel: function (topicHierarchy: TopicHierarchy) {
    return {
      lvl1: topicHierarchy.lvl1,
      lvl2: topicHierarchy.lvl2,
    };
  },
  fromFirestoreModel: function (data): TopicHierarchy {
    return new TopicHierarchy(data.lvl1, data.lvl2);
  },
};

/**
 * Convenience to create a new instance of TopicHierarchy with the correct delimiter, optional root, etc.
 *
 * Returns undefined if invalid.
 */
export function newTopicHierarchy(
  level1: string | undefined,
  level2?: string | undefined,
) {
  if (level1) {
    return new TopicHierarchy(
      [level1],
      level2 ? [`${level1} → ${level2}`] : undefined,
    );
  } else if (level2) {
    // No parent, just a dangling leaf
    return new TopicHierarchy(undefined, [level2]);
  } else {
    return undefined;
  }
}
