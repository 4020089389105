import { useEffect } from "react";
import { useRouter } from "next/router";

function useSetupRouterToHandleInternalLink(): void {
  const router = useRouter();

  useEffect(() => {
    const handleClick = (mouseEvent: MouseEvent) => {
      if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
        return;
      }

      const eventWillRedirect = mouseEvent.composedPath().filter((el) => {
        return el instanceof Node && el.nodeName === "A";
      });
      if (eventWillRedirect.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const path = (eventWillRedirect[0] as any).attributes.href?.value;
        const target =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (eventWillRedirect[0] as any).attributes.target?.value || "_self";

        if (path?.startsWith("/") && target === "_self") {
          console.log("[useSetupLinkClickTrap] detected link clicked", path);
          mouseEvent.preventDefault();

          router.push(path).catch(console.error);
        }
      }
    };

    document.addEventListener("click", handleClick, { capture: true });
    return () =>
      document.removeEventListener("click", handleClick, { capture: true });
  }, [router]);
}

export default useSetupRouterToHandleInternalLink;
