import {
  CloudFunctionName,
  callCloudFunction,
  useReadDocument,
  useReadQuery,
} from "./firebase/FirebaseAPI";
import {
  Group,
  GroupMember,
  GroupMemberMember,
  GroupPrivacy,
  GroupRequest,
  defaultFirestoreDataConverter,
  groupConverter,
  groupMemberConverter,
  groupRequestConverter,
} from "@sequoiacap/shared/models";
import { ReadDocumentReturnType, ReadQueryReturnType } from "./firebase/types";
import { exists } from "@sequoiacap/shared/utils/exists";
import { keyBy } from "lodash";
import { mutateDocument } from "./swr-firebase";
import { useAPIGetAllUsers } from "./user-api";
import {
  useDeepCompareEffectNoCheck,
  useDeepCompareMemoize,
} from "use-deep-compare-effect";
import { useState } from "react";
import { useStorageDownloadURL } from "./swr-firebase/hooks/use-swr-storage";
import useLightUserInfo from "./useLightUserInfo";
import useUserInfo from "./useUserInfo";

export function useAPIGetMyGroups(): ReadQueryReturnType<Group> {
  const [myNormalGroups, setMyNormalGroups] = useState<Group[] | undefined>();
  const {
    userId: loggedInUserId,
    specialGroupIds,
    loading: userInfoLoading,
  } = useUserInfo();
  const isReady = loggedInUserId && !userInfoLoading;

  const {
    data: myGroupMember,
    error: groupMemberError,
    loading: groupMemberLoading,
  } = useReadQuery(
    isReady ? "group_member" : null,
    groupMemberConverter,
    undefined,
    undefined,
    [[`member.${loggedInUserId}.id`, "==", loggedInUserId]],
  );
  const {
    data: specialGroups,
    error: specialGroupError,
    loading: specialGroupLoading,
  } = useReadQuery(
    isReady && (specialGroupIds?.length ?? 0) > 0 ? "special_group" : null,
    groupConverter,
    undefined,
    undefined,
    ["id", "in", specialGroupIds],
  );

  const myGroupIds: string[] | undefined =
    myGroupMember && myGroupMember.map((group) => group.id);

  useDeepCompareEffectNoCheck(() => {
    if (myGroupIds !== undefined) {
      void (async function readEachGroup() {
        const groups = await Promise.all(
          myGroupIds.map(async (id) => {
            return mutateDocument(`group/${id}`, groupConverter);
          }),
        );
        setMyNormalGroups(groups.filter((g): g is Group => !!g));
      })();
    }
  }, [myGroupIds]);

  if (isReady && !groupMemberLoading && specialGroups !== undefined) {
    const combinedGroups = sortGroup([
      ...specialGroups,
      ...(myNormalGroups ?? []),
    ]);
    return {
      loading: false,
      data: combinedGroups,
    };
  }

  if (!isReady || groupMemberLoading || specialGroupLoading) {
    return {
      loading: true,
    };
  }

  return {
    loading: false,
    error: groupMemberError ?? specialGroupError,
  };
}

export function useAPIGetMyNormalGroupIds(
  loggedInUserId: string | null | undefined,
): ReadQueryReturnType<string> {
  const { data: myGroupMember, loading } = useReadQuery(
    !!loggedInUserId ? "group_member" : null,
    groupMemberConverter,
    undefined,
    undefined,
    [[`member.${loggedInUserId}.id`, "==", loggedInUserId]],
    {
      listen: true,
    },
  );

  // sequoia user will not have access to normal groups
  const myGroupIds: string[] | undefined = useDeepCompareMemoize(
    myGroupMember?.map((group) => group.id).sort(),
  );

  if (loading) {
    return { loading: true };
  }

  return {
    loading: false,
    data: myGroupIds,
  };
}

export function useAPIGetOtherGroups(): ReadQueryReturnType<Group> {
  const { userId: loggedInUserId } = useLightUserInfo();

  const {
    data: myGroupMember,
    error: groupMemberError,
    loading: groupMemberLoading,
  } = useReadQuery("group_member", groupMemberConverter, undefined, undefined, [
    [`member.${loggedInUserId}.id`, "==", loggedInUserId],
  ]);

  const {
    data: otherGroups,
    error: otherGroupsError,
    loading: otherGroupsLoading,
  } = useReadQuery("group", groupConverter, undefined, undefined, [
    ["privacy", "in", [GroupPrivacy.searchable, GroupPrivacy.opened]],
  ]);

  if (groupMemberLoading || otherGroupsLoading) {
    return {
      loading: true,
    };
  }

  if (groupMemberError || otherGroupsError) {
    return {
      loading: false,
      error: groupMemberError ?? otherGroupsError,
    };
  }

  const myGroupIds = keyBy(myGroupMember, "id");

  return {
    loading: false,
    data: otherGroups?.filter((group) => !myGroupIds[group.id]),
  };
}

export function useAPIGetGroupRequest(
  groupId: string | null | undefined,
): ReadDocumentReturnType<GroupRequest> {
  const { userId: loggedInUserId } = useLightUserInfo();
  const isReady = groupId && loggedInUserId;

  return useReadDocument(
    isReady ? `group_request/${groupId}-${loggedInUserId}` : null,
    groupRequestConverter,
    {},
  );
}

export function useAPIGetGroup(
  groupId: string | null | undefined,
): ReadDocumentReturnType<Group> {
  const { specialGroupIds: visibleSpecialGroupIds, loading } = useUserInfo();
  const isReady = !!groupId && !loading;
  const isSpecialGroup = !!groupId && visibleSpecialGroupIds?.includes(groupId);

  const regularGroupResult = useReadDocument(
    isReady && !isSpecialGroup ? `group/${groupId}` : null,
    groupConverter,
    {
      // refresh: false,
      returnNullOnError: true,
    },
  );
  const specialGroupResult = useReadDocument(
    isReady && !regularGroupResult.loading && !regularGroupResult.data
      ? `special_group/${groupId}`
      : null,
    groupConverter,
    {
      refresh: false,
      returnNullOnError: true,
    },
  );

  if (!isReady) {
    return { loading: !!groupId, error: undefined };
  }

  if (specialGroupResult.data || regularGroupResult.data) {
    return {
      data: specialGroupResult.data ?? regularGroupResult.data,
      loading: false,
    };
  }
  if (!specialGroupResult.loading && !regularGroupResult.loading) {
    return {
      loading: false,
      error: new Error("Group not found"),
    };
  }
  return {
    loading: true,
  };
}

export function useAPIGetPromotedGroups(): ReadQueryReturnType<Group> {
  const [promotedGroups, setPromotedGroups] = useState<Group[] | undefined>();
  const { data: promotedDoc, error: promotedDocError } = useReadQuery(
    "promoted_group",
    defaultFirestoreDataConverter,
    1,
  );
  const groupIds: string[] | undefined = promotedDoc && promotedDoc[0].groups;
  const { specialGroupIds: visibleSpecialGroupIds } = useUserInfo();

  useDeepCompareEffectNoCheck(() => {
    if (groupIds !== undefined && visibleSpecialGroupIds !== undefined) {
      void (async function readEachGroup() {
        const groups = await Promise.all(
          groupIds.map(async (id: string) => {
            const result =
              visibleSpecialGroupIds.indexOf(id) === -1
                ? mutateDocument(`group/${id}`, groupConverter)
                : mutateDocument(`special_group/${id}`, groupConverter);
            return result.catch(() => {
              console.log("useAPIGetPromotedGroups/error getting group", id);
              return undefined;
            });
          }),
        );
        setPromotedGroups(groups.filter(exists));
      })();
    }
  }, [groupIds, visibleSpecialGroupIds]);

  if (promotedGroups) {
    return {
      loading: false,
      data: sortGroup(promotedGroups),
    };
  }
  return {
    loading: true,
    error: promotedDocError,
  };
}

export function useAPIGetGroupCoverImageUrl(
  groupId: string | null | undefined,
): {
  url?: string;
  loading: boolean;
  error?: Error;
} {
  const path = groupId ? `group/${groupId}/coverImage.jpg` : null;
  return useStorageDownloadURL(path);
}

function useGetSpecialGroupMember(
  groupId: string | null | undefined,
): ReadDocumentReturnType<GroupMember> {
  const { data: allUsers, loading, error } = useAPIGetAllUsers();
  const [groupMember, setGroupMember] = useState<GroupMember | undefined>();

  useDeepCompareEffectNoCheck(() => {
    const members: Record<string, GroupMemberMember> = {};
    if (groupId && allUsers) {
      allUsers.forEach((user) => {
        if (user.specialGroup?.includes(groupId)) {
          members[user.id] = new GroupMemberMember(
            user.id,
            user.name,
            "normal",
            user.title ?? "",
            user.createdAt,
            user.updatedAt,
          );
        }
      });
      setGroupMember(new GroupMember(groupId, "opened", members, new Date()));
    } else {
      setGroupMember(undefined);
    }
  }, [groupId, allUsers]);

  return {
    data: groupMember,
    loading,
    error,
  };
}

/**
 * For most uses, prefer `useAPIGetGroupMember` instead.
 * Note: This uses the user collection scan mechanism, without checking if the current user is a member of the special group.
 * Used for arc-alumni because we don't want it listed nor allow people to request to join but want to make the membership public for current arc attendees.
 */
// export function useAPIGetSpecialGroupMember(
//   groupId: string
// ): ReadDocumentReturnType<GroupMember> {
//   return useGetSpecialGroupMember(groupId);
// }

export function useAPIGetGroupMember(
  groupId: string | null | undefined,
): ReadDocumentReturnType<GroupMember> {
  const { specialGroupIds: visibleSpecialGroupIds, loading } = useUserInfo();
  const isReady = groupId && !loading;
  const isSpecialGroup = groupId && visibleSpecialGroupIds?.includes(groupId);
  const regularGroupResult = useReadDocument(
    isReady && !isSpecialGroup ? `group_member/${groupId}` : null,
    groupMemberConverter,
    {},
  );

  const specialGroupResult = useGetSpecialGroupMember(
    isReady && isSpecialGroup ? groupId : null,
  );
  if (!groupId) {
    return { loading: false };
  }
  if (!isReady) {
    return { loading: true, error: undefined };
  }
  return isSpecialGroup ? specialGroupResult : regularGroupResult;
}

export function useAPIIsGroupMember(
  groupId: string | null | undefined,
): boolean {
  const {
    userId: loggedInUserId,
    specialGroupIds: visibleSpecialGroupIds,
    loading,
  } = useUserInfo();
  const inSpecialGroup = !!groupId && visibleSpecialGroupIds?.includes(groupId);
  const regularGroupResult = useReadDocument(
    groupId && !loading && !inSpecialGroup ? `group_member/${groupId}` : null,
    groupMemberConverter,
    {},
  );

  return (
    inSpecialGroup || !!regularGroupResult.data?.members[loggedInUserId ?? ""]
  );
}

export async function joinGroup(group: Group, userId: string) {
  const result = await callCloudFunction<any>(
    CloudFunctionName.requestJoinGroup,
    { group_id: group.id },
  );
  await mutateDocument(
    `group_request/${group.id}-${userId}`,
    groupRequestConverter,
    true,
  );

  return result;
}

export async function cancelJoinGroup(group: Group, userId: string) {
  const result = await callCloudFunction<any>(
    CloudFunctionName.cancelJoinGroup,
    {
      group_id: group.id,
      user_id: userId,
    },
  );
  await mutateDocument(
    `group_request/${group.id}-${userId}`,
    groupRequestConverter,
    true,
  );

  return result;
}

export async function leaveGroup(group: Group) {
  const result = await callCloudFunction<any>(CloudFunctionName.leaveGroup, {
    group_id: group.id,
  });
  return result;
}

export function sortGroup(
  groups: Group[],
  property: "lastPostedAt" | "name" = "lastPostedAt",
): Group[] {
  switch (property) {
    case "lastPostedAt":
      return groups.sort((a, b) => {
        const aTime = a.lastPostedAt?.getTime() ?? 0;
        const bTime = b.lastPostedAt?.getTime() ?? 0;
        if (aTime === bTime) {
          return 0;
        }
        return aTime < bTime ? 1 : -1;
      });
    default:
      return groups.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
  }
}
