/* eslint-disable @typescript-eslint/no-explicit-any */
import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum InboundEmailState {
  received = "received",
  processing = "processing",
  processed = "processed",
  invalid = "invalid",
  unknown = "unknown",
}

export type InboundEmailAttachment = {
  Content: string;
  Name: string;
  ContentType: string;
};

export class InboundEmail {
  readonly id: string;
  readonly createdAt: StorageTimestamp;
  body: Record<string, unknown>;
  headers: Record<string, unknown>;
  state: InboundEmailState;
  updatedAt?: StorageTimestamp;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    body: Record<string, unknown>,
    headers: Record<string, unknown>,
    state: InboundEmailState,
    updatedAt?: StorageTimestamp,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.body = body;
    this.headers = headers;
    this.state = state;
    this.updatedAt = updatedAt;
  }
}

export const inboundEmailDataConverter: FirestoreDataConverter<InboundEmail> = {
  toFirestoreModel: function (message: InboundEmail) {
    return {
      id: message.id,
      created_at: message.createdAt,
      body: message.body,
      headers: message.headers,
      state: message.state,
      updated_at: message.updatedAt,
    };
  },
  fromFirestoreModel: function (data): InboundEmail {
    return new InboundEmail(
      data.id,
      data.created_at,
      data.body,
      data.headers,
      InboundEmailState[
        getEnumKeyByEnumValue(InboundEmailState, data.state) ??
          InboundEmailState.unknown
      ],
      data.updated_at,
    );
  },
};
