import { Fuego } from "../classes/Fuego";
import { ReactNode, createContext, useContext } from "react";

type Context = {
  fuego?: Fuego;
};

export const FuegoContext = createContext<Context>({});

type Props = {
  children: ReactNode;
  fuego: Fuego;
};

export let fuego: Fuego;

export const setFuego = (f: Fuego): Fuego => (fuego = f);

export const useFuegoContext = (): Context => {
  const context = useContext(FuegoContext);
  return context;
};

export const FuegoProvider = ({
  children,
  fuego: propfuego,
}: Props): JSX.Element => {
  setFuego(propfuego);
  return (
    <FuegoContext.Provider value={{ fuego }}>{children}</FuegoContext.Provider>
  );
};
