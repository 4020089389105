import * as snippet from "@segment/snippet";
import { ErrorBoundary } from "react-error-boundary";
import Head from "next/head";
import Router from "next/router";
import dynamic from "next/dynamic";
import getConfig from "next/config";

/* eslint-disable import/no-unassigned-import */
import "@sequoiacap/client-ui/styles.css";
import "~/global-styles/globals.scss";
import "~/utils/wdyr";

import { CheckMinWebVersion } from "~/components/app-version/CheckMinWebVersion";
import {
  FullPageLoader,
  UIProvider,
  useRemoveDocumentLoader,
} from "@sequoiacap/client-ui";
import { NetworkInfoProvider } from "~/hooks/useNetworkInfo";
import { NotificationPermissionToast } from "~/components/notification-permission/NotificationPermissionToast";
import { RedirectIOSStandalone } from "~/components/open-on-ios/RedirectIOSStandalone";
import { getBrowserDetails } from "~/utils/getBrowserDetails";
import { isAndroidStandalone } from "~/utils/isStandalone";
import {
  isFromAmpersandWebVC,
  messageAmpersandWebVCLoading,
  useSetupAmpersandWebVCBridge,
} from "~/network/ampersand-web-vc-bridge";
import { useCallback, useEffect, useMemo } from "react";
import { useRecordSourceEvent } from "~/utils/analytics";
import ErrorComponent from "~/components/error/ErrorComponent";
import useSetupRouterToHandleInternalLink from "~/hooks/useSetupRouterToHandleInternalLink";
import useTrackExternalLinkClick from "~/hooks/useTrackExternalLinkClick";
import type { AppProps } from "next/app";
import type { Page } from "~/utils/page";

const { publicRuntimeConfig } = getConfig();

const SEGMENT_WEB_KEY = publicRuntimeConfig.SEGMENT_WEB_KEY;
const SEGMENT_WEB_HOST = publicRuntimeConfig.SEGMENT_WEB_HOST;
const SPRIG_WEB_KEY = publicRuntimeConfig.SPRIG_WEB_KEY;
const NODE_ENV = process.env.NODE_ENV;

const FullBackends = dynamic(() => import("~/components/FullBackends"), {
  ssr: false,
  loading: () => <FullPageLoader />,
});

const AuthOnlyBackends = dynamic(
  () => import("~/components/AuthOnlyBackends"),
  {
    ssr: false,
    loading: () => <FullPageLoader />,
  },
);

function getPage(Component: Page, pageProps: AppProps["pageProps"]) {
  if (Component.staticNoBackends) {
    return <Component {...pageProps} />;
  }

  if (Component.authOnlyBackend) {
    return (
      <NetworkInfoProvider>
        <AuthOnlyBackends {...pageProps} authProps={Component.authProps}>
          <RecordSourceEvent />
          <RedirectIOSStandalone />
          <CheckMinWebVersion />
          <Component {...pageProps} />
        </AuthOnlyBackends>
      </NetworkInfoProvider>
    );
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,user-scalable=no,viewport-fit=cover,maximum-scale=1"
        />
      </Head>
      <NetworkInfoProvider>
        <FullBackends
          {...pageProps}
          authProps={Component.authProps}
          noHeader={Component.noHeader}
        >
          <RecordSourceEvent />
          <RedirectIOSStandalone />
          <CheckMinWebVersion />
          <NotificationPermissionToastAndroidStandalone />
          <Component {...pageProps} />
        </FullBackends>
      </NetworkInfoProvider>
    </>
  );
}

function renderSegmentSnippet(): string {
  const opts = {
    apiKey: SEGMENT_WEB_KEY,
    host: `${SEGMENT_WEB_HOST}/sajs`,
    ajsPath: `/${SEGMENT_WEB_KEY}/sajs.js`,
    useHostForBundles: true,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: false,
  };

  if (NODE_ENV === "development") {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

function renderSprigSnippet(): string {
  return `(function(l,e,a,p) {
    if (window.Sprig) return;
    window.Sprig = function(){S._queue.push(arguments)}
    var S = window.Sprig;S.appId = a;S._queue = [];window.UserLeap=S;
    a=l.createElement('script');
    a.async=1;a.src=e+'?id='+S.appId;
    p=l.getElementsByTagName('script')[0];
    p.parentNode.insertBefore(a, p);
  })(document, 'https://cdn.sprig.com/shim.js', '${SPRIG_WEB_KEY}');
  `;
}

type Props = AppProps & {
  Component: Page;
};

export default function App({ Component, pageProps }: Props): JSX.Element {
  useSetupRouterToHandleInternalLink();
  useSetupAmpersandWebVCBridge();
  useRemoveDocumentLoader();
  useTrackExternalLinkClick();

  useEffect(() => {
    const details = getBrowserDetails();
    const classNames = Object.keys(details).filter(
      (key) => details[key as keyof typeof details],
    );

    document.documentElement.classList.add(...classNames);
  }, []);

  const fallbackRender = useCallback(
    ({ error }: { error: Error }) => (
      <ErrorComponent statusCode={500} error={error} />
    ),
    [],
  );

  const fullPageLoaderOptions = useMemo(() => {
    return {
      onShow: () => messageAmpersandWebVCLoading(true),
      onHide: () => messageAmpersandWebVCLoading(false),
      shouldShowSpinner:
        !isFromAmpersandWebVC() && !Component.shouldHideSpinner,
    };
  }, [Component.shouldHideSpinner]);

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <Head>
        <script
          id="segment"
          dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
          async
        />
        <script
          id="sprig"
          dangerouslySetInnerHTML={{ __html: renderSprigSnippet() }}
          async
        />
      </Head>
      <UIProvider router={Router} fullPageLoaderOptions={fullPageLoaderOptions}>
        {getPage(Component, pageProps)}
      </UIProvider>
    </ErrorBoundary>
  );
}

/** Convenience around NotificationPermissionToast, which waits until the component is mounted before trying to determine if we're in Android Standalone mode.
 *
 * NOTE: The toast needs to be mounted in _app.tsx so the toast doesn't animate out/in (due to mounting) when the user navigates to a new site.
 */
function NotificationPermissionToastAndroidStandalone() {
  const show = useMemo(isAndroidStandalone, []);
  return show ? <NotificationPermissionToast /> : null;
}

function RecordSourceEvent() {
  useRecordSourceEvent();

  return null;
}
