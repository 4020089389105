import { ParsedToken } from "firebase/auth";
import { createContext, useContext } from "react";

export type AuthUser = {
  id: string;
  displayName?: string;
  email?: string;
  claims: ParsedToken;
};

export enum AuthAction {
  RENDER = "render",
  REDIRECT_TO_LOGIN = "redirectToLogin",
  REDIRECT_TO = "redirectTo",
}

export enum AuthErrorType {
  UNKNOWN = "unknown",
  AUTHENTICATION = "authentication",
  AUTHORIZATION = "authorization",
  UNVERIFIED = "unverified",
}

export type AuthContextType = {
  authUser?: AuthUser;
  loading: boolean;
  error?: Error;
  errorType?: AuthErrorType;
  setAuthorizationError: (error?: Error) => void;
  loggedOut?: boolean;
};

export const AuthContext = createContext<AuthContextType | null>(null);

/**
 * Checks Firebase auth only. If you want to know User info, use `useUserInfo` or `useLightUserInfo` instead.
 *
 * Signed out users will have `loading=false` and `authUser=undefined`.
 * Ghosts & full users will have `loading=false` and `authUser=[object]`.
 */
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const isWritableClaim = (claims?: ParsedToken): boolean => {
  return (claims && (!claims.readonly || !!claims.admin)) || false;
};

export const landingPageClaim = (claims?: ParsedToken): string | undefined => {
  return claims?.lp as string | undefined;
};

export const isAdminClaim = (claims?: ParsedToken): boolean => {
  return !!claims?.admin;
};

export const isUnverifiedClaim = (claims?: ParsedToken): boolean => {
  return !claims?.v;
};

export function isSequoiaAuth(
  authUser: AuthUser | undefined,
): boolean | undefined {
  return (
    authUser?.email?.endsWith("@sequoiacap.com") ||
    authUser?.email?.endsWith("@schf.com")
  );
}
