import { StorageTimestamp } from "./base/data-type";
import { asEnum } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

// These are the values we seem to get messaged to use as data.
// For now we only require a few of them like the person_id.
export type SMSUser = {
  avatar?: string;
  company_name?: string;
  email?: string;
  id?: string;
  initials?: string;
  name: string;
  person_id: string;
  text?: string;
  type: "User";
  value?: string;
};

export type SMSPeople = {
  avatar?: string;
  company_name?: string;
  email?: string;
  id?: string;
  linkedin_url?: string;
  name: string;
  person_id?: string;
  text?: string;
  type: "Person";
  value?: string;
};

// The companies array can contain single "id" only companies or
// full "Company" objects.
export type SMSCompany = {
  description?: string;
  display?: string;
  domain?: string;
  id: string;
  last_interacted_at?: string;
  logo?: string;
  name: string;
  text?: string;
  type: "Company";
  url?: string;
  value?: string;
};

export type SMSHashTag = {
  id: string;
  name: string;
  text?: string;
  type: "Tag";
  value?: string;
};

export enum InvestmentRating {
  one = "1",
  two = "2",
  three = "3",
  four = "4",
  five = "5",
  six = "6",
  seven = "7",
  eight = "8",
  nine = "9",
  ten = "10",
  unknown = "unknown",
}

export class SMSMemoRating {
  id: string;
  createdById: string;
  createdAt: StorageTimestamp;
  updatedAt: StorageTimestamp;
  rating?: InvestmentRating;
  smsComment?: string;
  smsCompanies?: SMSCompany[];
  smsMentionedUsers?: SMSUser[];
  smsHashTags?: SMSHashTag[];
  smsPeople?: SMSPeople[];
  smsNoteId?: string;
  // sms rich text editor changed data format
  richText?: string;
  hashtags?: string[];
  mentionedCompanyIds?: string[];
  mentionedPersonIds?: string[];
  mentionedUserIds?: string[];

  constructor(
    id: string,
    createdById: string,
    createdAt: StorageTimestamp,
    updatedAt: StorageTimestamp,
    rating?: InvestmentRating,
    smsComment?: string,
    smsCompanies?: SMSCompany[],
    smsMentionedUsers?: SMSUser[],
    smsHashTags?: SMSHashTag[],
    smsPeople?: SMSPeople[],
    smsNoteId?: string,
    richText?: string,
    hashtags?: string[],
    mentionedCompanyIds?: string[],
    mentionedPersonIds?: string[],
    mentionedUserIds?: string[],
  ) {
    this.id = id;
    this.createdById = createdById;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.rating = rating;
    this.smsComment = smsComment;
    this.smsCompanies = smsCompanies;
    this.smsMentionedUsers = smsMentionedUsers;
    this.smsHashTags = smsHashTags;
    this.smsPeople = smsPeople;
    this.smsNoteId = smsNoteId;
    this.richText = richText;
    this.hashtags = hashtags;
    this.mentionedCompanyIds = mentionedCompanyIds;
    this.mentionedPersonIds = mentionedPersonIds;
    this.mentionedUserIds = mentionedUserIds;
  }
}

export const smsMemoRatingConverter: FirestoreDataConverter<SMSMemoRating> = {
  toFirestoreModel: function (rating: SMSMemoRating) {
    return {
      id: rating.id,
      created_by_id: rating.createdById,
      created_at: rating.createdAt,
      updated_at: rating.updatedAt,
      rating: rating.rating,
      sms_comment: rating.smsComment,
      sms_companies: rating.smsCompanies,
      sms_mentioned_users: rating.smsMentionedUsers,
      sms_hash_tags: rating.smsHashTags,
      sms_people: rating.smsPeople,
      sms_note_id: rating.smsNoteId,
      rich_text: rating.richText,
      hashtags: rating.hashtags,
      mentioned_company_ids: rating.mentionedCompanyIds,
      mentioned_person_ids: rating.mentionedPersonIds,
      mentioned_user_ids: rating.mentionedUserIds,
    };
  },
  fromFirestoreModel: function (data): SMSMemoRating {
    return new SMSMemoRating(
      data.id,
      data.created_by_id,
      data.created_at,
      data.updated_at,
      asEnum(InvestmentRating, data.rating, InvestmentRating.unknown),
      data.sms_comment,
      data.sms_companies,
      data.sms_mentioned_users,
      data.sms_hash_tags,
      data.sms_people,
      data.sms_note_id,
      data.rich_text,
      data.hashtags,
      data.mentioned_company_ids,
      data.mentioned_person_ids,
      data.mentioned_user_ids,
    );
  },
};
