import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

// Hook
function useMemoCompare<T>(next: T, compare = isEqual): T {
  // Ref for storing previous value
  const previousRef = useRef<T | undefined>(undefined);
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const equal = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!equal) {
      previousRef.current = next;
    }
  }, [next, equal]);

  // Finally, if equal then return the previous value
  return previous && equal ? previous : next;
}

export default useMemoCompare;
