import { StorageTimestamp } from "./base/data-type";
import { SurveyForm, SurveyResponse } from "./survey";
import { asEnum } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

/**
 */
export class ScoutPortfolioReview {
  id: string;
  title: string;
  startAt: StorageTimestamp;
  endAt: StorageTimestamp;

  /** Current iteration of the survey questions.
   * NOTE: ScoutPortfolioReviewInvestment keeps a copy so we know what the questions were at the time of submission.
   * Frontend will merge by `name`.
   */
  questions: SurveyForm;

  constructor(
    id: string,
    title: string,
    startAt: StorageTimestamp,
    endAt: StorageTimestamp,
    questions: SurveyForm,
  ) {
    this.id = id;
    this.title = title;
    this.startAt = startAt;
    this.endAt = endAt;
    this.questions = questions;
  }
}

export class ScoutPortfolioReviewScout {
  id: string; // Auth uid
  name: string;
  email: string;
  collaboratorEmails: string[];

  /** Location in Firebase Storage of previous answers.
   *
   * NOTE: This is not a URL, it is a path.
   * We named this field before deciding to use Firebase Storage.
   */
  previousReviewUrl?: string;

  airtableId: string /** Matches airtable id */;
  smsId: string /** Matches airtable id */;

  constructor(
    id: string,
    name: string,
    email: string,
    collaboratorEmails: string[],
    previousReviewUrl: string,
    airtableId: string,
    smsId: string,
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.collaboratorEmails = collaboratorEmails;
    this.previousReviewUrl = previousReviewUrl;
    this.airtableId = airtableId;
    this.smsId = smsId;
  }
}

export class ScoutPortfolioReviewInvestment {
  id: string;

  companySMSId: string;
  companyName?: string;
  companyDescription?: string;

  investmentAmount?: string;
  investmentDate?: string;
  investmentFund: ScoutFund;

  questions?: SurveyForm;
  responses?: SurveyResponse;
  responseStatus: ScoutPortfolioReviewResponseStatus;
  responseStatusUpdatedAt?: StorageTimestamp;

  scoutId: string;
  scoutPortfolioReviewId: string;
  writeableScoutIds: string[];

  airtableId: string;
  order: number; /** Keep the order from Airtable so it appears in order on the UI */

  smsNoteId?: string;

  constructor(
    id: string,
    companySMSId: string,
    companyName: string,
    companyDescription: string,
    investmentAmount: string,
    investmentDate: string,
    investmentFund: ScoutFund,
    questions: SurveyForm,
    responses: SurveyResponse,
    responseStatus: ScoutPortfolioReviewResponseStatus,
    responseStatusUpdatedAt: StorageTimestamp,
    scoutId: string,
    scoutPortfolioReviewId: string,
    writeableScoutIds: string[],
    airtableId: string,
    order: number,
    smsNoteId?: string,
  ) {
    this.id = id;
    this.companySMSId = companySMSId;
    this.companyName = companyName;
    this.companyDescription = companyDescription;
    this.investmentAmount = investmentAmount;
    this.investmentDate = investmentDate;
    this.investmentFund = investmentFund;
    this.questions = questions;
    this.responses = responses;
    this.responseStatus = responseStatus;
    this.responseStatusUpdatedAt = responseStatusUpdatedAt;
    this.scoutId = scoutId;
    this.scoutPortfolioReviewId = scoutPortfolioReviewId;
    this.writeableScoutIds = writeableScoutIds;
    this.airtableId = airtableId;
    this.order = order;
    this.smsNoteId = smsNoteId;
  }
}

export class ScoutPortfolioReviewOther {
  id: string;

  note?: string;

  scoutId: string;
  scoutPortfolioReviewId: string;
  constructor(
    id: string,
    note: string,
    scoutId: string,
    scoutPortfolioReviewId: string,
  ) {
    this.id = id;
    this.note = note;
    this.scoutId = scoutId;
    this.scoutPortfolioReviewId = scoutPortfolioReviewId;
  }
}

export enum ScoutFund {
  fund1 = "Scout I",
  fund2 = "Scout II",
  fund3 = "Scout III",
  fund4 = "Scout IV",
  fund5 = "Scout V",
  fund6 = "Scout VI",
  fund6a = "Scout VI-A",
  fund7 = "Scout VII",
  unknown = "unknown",
}

export enum ScoutPortfolioReviewResponseStatus {
  notStarted = "notStarted",
  started = "started",
  completed = "completed",
  unknown = "unknown",
}

export const scoutPortfolioReviewConverter: FirestoreDataConverter<ScoutPortfolioReview> =
  {
    toFirestoreModel: function (review: ScoutPortfolioReview) {
      return {
        id: review.id,
        title: review.title,
        start_at: review.startAt,
        end_at: review.endAt,
        questions: review.questions,
      };
    },
    fromFirestoreModel: function (data): ScoutPortfolioReview {
      return new ScoutPortfolioReview(
        data.id,
        data.title,
        data.start_at,
        data.end_at,
        data.questions,
      );
    },
  };

export const scoutPortfolioReviewScoutConverter: FirestoreDataConverter<ScoutPortfolioReviewScout> =
  {
    toFirestoreModel: function (scout: ScoutPortfolioReviewScout) {
      return {
        id: scout.id,
        name: scout.name,
        email: scout.email,
        collaborator_email: scout.collaboratorEmails,
        previous_review_url: scout.previousReviewUrl,
        airtable_id: scout.airtableId,
        sms_id: scout.smsId,
      };
    },
    fromFirestoreModel: function (data): ScoutPortfolioReviewScout {
      return new ScoutPortfolioReviewScout(
        data.id,
        data.name,
        data.email,
        data.collaborator_email,
        data.previous_review_url,
        data.airtable_id,
        data.sms_id,
      );
    },
  };

export const scoutPortfolioReviewInvestmentConverter: FirestoreDataConverter<ScoutPortfolioReviewInvestment> =
  {
    toFirestoreModel: function (investment: ScoutPortfolioReviewInvestment) {
      return {
        id: investment.id,
        company_sms_id: investment.companySMSId,
        company_name: investment.companyName,
        company_description: investment.companyDescription,
        investment_amount: investment.investmentAmount,
        investment_date: investment.investmentDate,
        investment_fund: investment.investmentFund,
        question: investment.questions,
        response: investment.responses,
        response_status: investment.responseStatus,
        response_status_updated_at: investment.responseStatusUpdatedAt,
        scout_id: investment.scoutId,
        scout_portfolio_review_id: investment.scoutPortfolioReviewId,
        writeable: investment.writeableScoutIds,
        airtable_id: investment.airtableId,
        order: investment.order,
        sms_note_id: investment.smsNoteId,
      };
    },
    fromFirestoreModel: function (data): ScoutPortfolioReviewInvestment {
      return new ScoutPortfolioReviewInvestment(
        data.id,
        data.company_sms_id,
        data.company_name,
        data.company_description,
        data.investment_amount,
        data.investment_date,
        data.investment_fund,
        data.question,
        data.response,
        asEnum(
          ScoutPortfolioReviewResponseStatus,
          data.response_status,
          ScoutPortfolioReviewResponseStatus.unknown,
        ),
        data.response_status_updated_at,
        data.scout_id,
        data.scout_portfolio_review_id,
        data.writeable,
        data.airtable_id,
        data.order,
        data.sms_note_id,
      );
    },
  };

export const scoutPortfolioReviewOtherConverter: FirestoreDataConverter<ScoutPortfolioReviewOther> =
  {
    toFirestoreModel: function (other: ScoutPortfolioReviewOther) {
      return {
        id: other.id,
        note: other.note,
        scout_id: other.scoutId,
        scout_portfolio_review_id: other.scoutPortfolioReviewId,
      };
    },
    fromFirestoreModel: function (data): ScoutPortfolioReviewOther {
      return new ScoutPortfolioReviewOther(
        data.id,
        data.note,
        data.scout_id,
        data.scout_portfolio_review_id,
      );
    },
  };
