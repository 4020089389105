import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export class ServiceRequest {
  id: string;
  createdAt: StorageTimestamp;
  createdById: string;
  serviceId: string;
  smsNoteId?: string;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    createdById: string,
    serviceId: string,
    smsNoteId?: string,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.createdById = createdById;
    this.serviceId = serviceId;
    this.smsNoteId = smsNoteId;
  }
}

export const serviceRequestDataConverter: FirestoreDataConverter<ServiceRequest> =
  {
    toFirestoreModel: function (serviceRequest: ServiceRequest) {
      return {
        id: serviceRequest.id,
        created_at: serviceRequest.createdAt,
        created_by_id: serviceRequest.createdById,
        service_id: serviceRequest.serviceId,
        sms_note_id: serviceRequest.smsNoteId,
      };
    },
    fromFirestoreModel: function (data): ServiceRequest {
      return new ServiceRequest(
        data.id,
        data.created_at,
        data.created_by_id,
        data.service_id,
        data.sms_note_id,
      );
    },
  };
