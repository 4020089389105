import * as Sentry from "@sentry/browser";
import {
  SOURCE_TRACKING_PARAM,
  unpackTrackingSlug,
} from "@sequoiacap/shared/utils/tracking";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { UserInfoContextType } from "~/network/useUserInfo";
import {
  isBuilderAudience,
  isFounderUser,
  isScoutUser,
  isSequoiaUser,
  isTalentUser,
} from "@sequoiacap/shared/utils/user";
import { isFromAmpersandWebVC } from "~/network/ampersand-web-vc-bridge";
import { useAuth } from "~/components/auth/useAuth";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const { IS_PROD_ENV, BUILD_NUMBER } = publicRuntimeConfig;

let _sessionId: string | undefined;
let _timer: ReturnType<typeof setTimeout>;
const SESSION_DURATION = 1000 * 60 * 30; // 30 minutes

function getSessionId(): string {
  if (!_sessionId) {
    _sessionId = Math.floor(new Date().getTime() / 1000).toString();
  }
  if (_timer) {
    clearTimeout(_timer);
  }
  _timer = setTimeout(() => {
    _sessionId = undefined;
  }, SESSION_DURATION);
  return _sessionId;
}

function shouldReportAnaytics(): boolean {
  if (typeof window === "undefined") return false;
  if (!!window.frameElement) return false;
  if (!!new URL(window.location.href).searchParams.get("precache"))
    return false;
  return true;
}

let currentTab: string | undefined = undefined;
/** `Tab` in this context means the sub-navigation section like /latest, /resources, etc. */
export function recordPageView(url: string, tab?: string): void {
  if (!shouldReportAnaytics()) return;
  window.analytics?.page(
    {
      tab: tab,
      build_number: BUILD_NUMBER,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    },
    {
      integrations: {
        Amplitude: {
          session_id: getSessionId(),
        },
      },
    },
  );
  if (currentTab && currentTab !== tab) {
    window.Sprig?.("track", `change_tab_${tab}`);
  }
  currentTab = tab;
}

export enum TrackEvent {
  sourceEvent = "source", // A page view that contains a source tracking indicator will have this as another event
  search = "search",
  searchTiming = "searchTiming",

  landingPage = "landing_page",
  peopleSearch = "people_search",
  appOpenWithToken = "appOpenWithToken",
  libraryClick = "library_click",
  libraryPage = "library_page",
  loginWithGmail = "login_with_gmail",
  loginWithMicrosoft = "login_with_microsoft",
  loginWithMagicLink = "login_with_magic_link",
  loginWithinWebView = "login_within_webview",
  linkWithMicrosoft = "link_with_microsoft",
  linkWithGoogle = "link_with_google",
  cloudFunctionError = "cloud_function_error",
  libraryError = "library_error",
  profileEntryInvalid = "profile_entry_invalid",
  profileEdited = "profile_edited", // Helpful to know if a user changed their company name, etc.
  chatCreated = "chat_created",
  chatMemberAdded = "chat_member_added",
  chatMemberRemoved = "chat_member_removed",
  chatLeft = "chat_left",
  chatMessageSending = "chat_message_sending",
  chatMessageSent = "chat_message_sent",
  chatMessageSendError = "chat_message_send_error",
  chatMessageEditing = "chat_message_editing",
  chatMessageEdited = "chat_message_edited",
  chatMessageDeleting = "chat_message_deleting",
  chatMessageDeleted = "chat_message_deleted",
  chatOpened = "chat_opened",
  signupEvent = "signup_event",
  unsignupEvent = "unsignup_event",

  postCreating = "post_creating",
  postCreated = "post_created",
  postUpdated = "post_updated",
  postDeleted = "post_deleted",

  promptBoxClicked = "prompt_box_clicked",

  edgeStoryObjectChat = "edge_story_object_chat",
  edgeStoryHeaderProfile = "edge_story_header_profile",
  edgeStoryObjectProfile = "edge_story_object_profile",
  edgeStoryAction = "edge_story_action",
  edgeStoryHeaderAction = "edge_story_header_action",
  edgeStoryCTAAction = "edge_story_cta_action",
  edgeStoryObjectAction = "edge_story_object_action",

  commentCreated = "comment_created",
  commentUpdated = "comment_updated",
  commentDeleted = "comment_deleted",

  lightboxOpen = "lightbox_open",

  bookmarked = "bookmarked",
  unbookmarked = "unbookmarked",

  sharingOpportunity = "sharing_opportunity",
  sharedOpportunity = "shared_opportunity",
  submittingMemo = "submitting_memo",
  submittedMemo = "submitted_memo",
  updatingMemo = "updating_memo",
  updatedMemo = "updated_memo",
  approvingMemo = "approving_memo",
  approvedMemo = "approved_memo",
  duplicateDealWarn = "duplicate_deal_warn",

  callCloudFunction = "call_cloud_function",

  notificationClick = "notification_click",
  pageFullyLoaded = "page_fully_loaded",

  visitingExternalSite = "visiting_external_site",
  redirectingExternalSite = "redirecting_external_site",

  builderRequest = "builder_request",
  agencyRequest = "agency_request",

  webVitals = "web_vitals",
  getDocumentRetry = "get_document_retry",
  getDocumentSlow = "get_document_slow",
  error = "web_error",

  shareEntity = "share_entity",

  contactInfoClick = "contact_info_click",

  playlistNavClick = "playlist_nav_click",

  forceRefreshShown = "force_refresh_shown",
  forceRefreshClicked = "force_refresh_clicked",
  forceRefreshSkipped = "force_refresh_skipped",

  newSWVersionDetected = "new_sw_version_detected",
  newSWRefreshClicked = "new_sw_refresh_clicked",
  newSWRefrechShown = "new_sw_refresh_shown",

  baseCampPrint = "base_camp_print",

  linkedInExtensionStatus = "li_ext_status",

  lhsNavClick = "lhs_nav_click",
}

export enum TrackErrorEvent {
  cloudFunctionError = "cloud_function_error",
  writePost = "error_writing_post",
  writeComment = "error_writing_comment",
  sendMessage = "error_sending_message",
  editMessage = "error_editing_message",
  deleteMessage = "error_deleting_message",
  createDirectChat = "error_creating_direct_chat",
  shareOpportunity = "error_sharing_opportunity",
  submitMemo = "error_submitting_memo",
  updateMemo = "error_updating_memo",
  approveMemo = "error_approving_memo",
  errorComponent = "error_component",
  errorSWR = "error_swr",
  oauthLogin = "error_oauth_login",
  linkWithMicrosoft = "error_linking_with_microsoft",
  errorIndexedDbPersistence = "error_indexed_db_persistence",
  profileImageError = "error_profile_image",
  storageImageError = "error_storage_image",
  linkOAuthError = "error_link_oauth",
  NetworkCacheDbError = "error_network_cache_db",
  extensionNotInstalled = "error_extension_not_installed",
}

export function track(
  eventName: TrackEvent,
  properties?: { [key: string]: unknown },
): void {
  if (!shouldReportAnaytics()) return;
  window.analytics?.track(
    eventName,
    {
      ...properties,
      loc: window.location.href,
    },
    {
      integrations: {
        Amplitude: {
          session_id: getSessionId(),
        },
      },
    },
  );
  window.Sprig?.("track", eventName);
}

export function trackSearchTiming(properties: {
  ms: number;
  [key: string]: unknown;
}) {
  if (properties.ms >= 2000) {
    console.log("[SearchTiming] Took long time", properties);
    track(TrackEvent.searchTiming, properties);
  }
  return;
}

export function trackError(
  eventName: TrackErrorEvent,
  error: unknown,
  properties?: { [key: string]: string },
): void {
  if (!!window.frameElement) return;
  Sentry.captureException(error, {
    tags: {
      type: eventName,
      url: window.location.href,
      ...properties,
    },
  });
  track(TrackEvent.error, {
    ...properties,
    url: window.location.href,
    errorType: eventName,
  });
}

export function useTrackUserInfo(userInfo: UserInfoContextType): void {
  const {
    userId,
    displayName,
    email,
    isWritableUser,
    loading,
    specialGroupIds,
    isAdmin,
    isGeneratedAccount,
    userRecord,
    audiences = [],
  } = userInfo;

  const isScout = isScoutUser(userRecord);
  const isFounder = isFounderUser(userRecord);
  const isBuilder = isBuilderAudience(userRecord);
  const isTalent = isTalentUser(userRecord);
  const isSequoia = isSequoiaUser(userRecord);

  useEffect(() => {
    if (!shouldReportAnaytics()) return;
    if (loading || !userId) return;

    window.analytics?.identify(
      userId,
      {
        name: displayName,
        email: email,
        readonly: !isWritableUser,
        sg: specialGroupIds,
        admin: isAdmin,
        scout: isScout,
        founder: isFounder,
        leader: isBuilder,
        talent: isTalent,
        autocreated: isGeneratedAccount,
        jsVersion: BUILD_NUMBER,
      },
      {
        integrations: {
          Amplitude: {
            session_id: getSessionId(),
          },
        },
      },
    );

    Sentry.setUser({
      id: userId,
      email: email ?? undefined,
      username: displayName ?? undefined,
    });

    if (!isFromAmpersandWebVC() && IS_PROD_ENV && isSequoia) {
      try {
        Sentry.getCurrentHub()
          .getClient()
          ?.getIntegration(Sentry.Replay)
          ?.start();
      } catch (e) {
        console.error("Error starting Sentry Replay", e);
      }
    }

    window.Sprig?.("setUserId", userId);
    window.Sprig?.("setEmail", email);
    const attributes: Record<string, string | number | boolean> = {};
    for (const audience of audiences) {
      attributes[audience] = true;
    }
    if (Object.keys(attributes).length > 0) {
      window.Sprig?.("setAttributes", attributes);
    }
  }, [
    displayName,
    email,
    isAdmin,
    isFounder,
    isBuilder,
    isSequoia,
    isGeneratedAccount,
    isScout,
    isWritableUser,
    loading,
    specialGroupIds,
    userId,
    audiences,
  ]);
}

export function trackIdentity(authUser: User | null): void {
  if (!shouldReportAnaytics()) return;
  if (authUser) {
    window.analytics?.identify(authUser.uid, {
      name: authUser.displayName,
      email: authUser.email,
    });
    Sentry.setUser({
      id: authUser.uid,
      email: authUser.email ?? undefined,
      username: authUser.displayName ?? undefined,
    });
    window.Sprig?.("setUserId", authUser.uid);
    window.Sprig?.("setEmail", authUser.email);
  } else {
    window.analytics?.reset();
    Sentry.configureScope((scope) => scope.setUser(null));
    window.Sprig?.("logoutUser");
  }
}

/** Used by AuthOnly backends only. FullBackend does a stronger check. */
export function useTrackIdentify(): void {
  useEffect(() => {
    return onAuthStateChanged(getAuth(), (userAuth) => {
      trackIdentity(userAuth);
    });
  }, []);
}

export function useRecordSourceEvent(): void {
  const location = window.location;
  const router = useRouter();
  const authInfo = useAuth();
  const [didRecord, setDidRecord] = useState(false);

  // NOTE: Need to use a ref because something in the page (e.g. /resources redirect) might change the URL, removing the tracking slug. So we need to get the slug as soon as possible. After the slug is recorded, we will remove it from searchParams.current to avoid double counting. Another slug should not come in the same page load.
  const searchParams = useRef(new URLSearchParams(location.search));

  useEffect(() => {
    if (authInfo.loading) {
      return;
    }
    if (!authInfo.authUser?.id) {
      // Clear _actionTime so another user logging in will get the Source event.
      localStorage?.removeItem("_actionTime");
      return;
    }

    if (didRecord || isFromAmpersandWebVC()) {
      // For AWVC, source is tracked by iOS
      // console.log("qqq   alraedy done or awvc", didRecord);
      return;
    }

    // console.log("qqq   looking for slug in", searchParams.current);

    // Figure out the source: Could be a slug, push notif, direct nav, etc.
    // Note that login links (with a redirect+slug) will have the same domain. So if there is a slug, we def want to track it.
    let slug = undefined;
    try {
      slug = unpackTrackingSlug(searchParams.current);
    } catch (e) {
      // Likely a programming error – a wellformatted slug that cannot be used as a slug.
      console.error("Error unpacking slug", e, searchParams.current.toString());
      return;
    }

    if (slug) {
      try {
        track(TrackEvent.sourceEvent, slug);
      } catch (e) {
        // Likely a programming error – a wellformatted slug that cannot be used as a slug.
        console.error(
          "Error recording slug to segment",
          e,
          searchParams.current.toString(),
        );
      }
      // console.log("qqq   ✅ slug tracked");
      setDidRecord(true);
      searchParams.current.delete(SOURCE_TRACKING_PARAM);

      // Remove the _s so we don't re-track the URL, but keep the rest of the params.
      // NOTE: this will cause another run of useRecordSourceEvent, which means we need to know if didRecord is true.
      void router.replace(
        `${location.pathname}${searchParams.current.size > 0 ? `?${searchParams.current.toString()}` : ""}`,
        undefined,
        {
          shallow: true,
        },
      );
    } else {
      // No slug. Consider tracking a "direct" visit

      if (
        window.performance
          .getEntriesByType("navigation")
          .map((entry) => (entry as PerformanceNavigationTiming).type)[0] !==
        "navigate"
      ) {
        // console.log(
        //   "qqq not a navigate: ",
        //   window.performance
        //     .getEntriesByType("navigation")
        //     .map((entry) => (entry as PerformanceNavigationTiming).type)
        //     .join(", "),
        // );
        // Forward/back, reload, etc. Skip since it's a navigation.
        // If someone clicks out and back in, we don't record a source event, since it's not really a direct visit.
        return;
      }

      // "navigate" with self domain as referrer => opened new tab, so ignore those.
      // NOTE: Safari doesn't set the referrer when opening a new tab, even when the page is the same domain. So below, we use the actionTime to determine if it's a new tab.
      if (document.referrer.startsWith(location.origin)) {
        // console.log(
        //   "qqq   skipping bc 'origin' is a prefix of doc.ref",
        //   window.location.origin,
        //   document.referrer,
        // );
        return;
      }

      const actionTime = localStorage?._actionTime ?? 0;
      const actionAge = Math.abs(new Date().getTime() - actionTime);

      // console.log(
      //   "qqq    checking sessh",
      //   JSON.stringify(
      //     {
      //       actionTime,
      //       actionAge,
      //     },
      //     null,
      //     2,
      //   ),
      // );
      if (actionAge < SESSION_DURATION) {
        // Recent action, on this tab or another; session still valid
        // console.log("qqq    too recent; skip", actionAge);
        return;
      }

      // /login will redirect somewhere - let that thing handle the source event.
      if (location.pathname === "/login") {
        // console.log("qqq    ignore /login page skip", actionAge);
        return;
      }

      // console.log("qqq   ✅ doing a trackings");
      track(TrackEvent.sourceEvent, { channel: document.referrer || "direct" });
      setDidRecord(true);
      if (localStorage) {
        localStorage._actionTime = new Date().getTime();
      }
    }
  }, [
    didRecord,
    router,
    searchParams,
    location.origin,
    location.pathname,
    authInfo.authUser,
    authInfo.loading,
  ]);
}

export function maybeTrackNearestLink(
  eventName: TrackEvent,
  e: React.MouseEvent<HTMLElement>,
) {
  for (let cur: any = e.target; cur; cur = cur.parentElement) {
    if (cur?.tagName !== "A" && cur?.tagName !== "BUTTON") {
      continue;
    }
    const href = cur.getAttribute("href") || cur.getAttribute("data-href");
    if (!href) {
      continue;
    }
    track(eventName, {
      href,
    });
    break;
  }
}
