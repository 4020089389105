export type StorageTimestamp = Date | ServerTimestamp;

/**
 * Placeholder type that will convert to/from firebase.firestore.FieldValue.serverTimestamp, which indicates the value should be defined by server.
 * Use StorageTimestamp if your code wants to convert to/from Date as well.
 * See: firebase-data-converter.ts, admin-data-converter.ts
 */
export class ServerTimestamp {
  private constructor() {}

  static create(): ServerTimestamp {
    return new ServerTimestamp();
  }

  // Define some functions from Date that will throw exception when called.
  // This makes Typescript happy for StorageTimestamp but will stop execution if you use a server-defined value before it's defined.
  getTime(): number {
    throw new Error(
      "ServerTimestamp is a placeholder before writing to backend. You should not use this as a Date until it is written to the backend.",
    );
  }
  toLocaleDateString(): string {
    throw new Error(
      "ServerTimestamp is a placeholder before writing to backend. You should not use this as a Date until it is written to the backend.",
    );
  }
}
