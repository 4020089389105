import { HubActivity } from "./exp-hub";

export type ScoutCampCohort = {
  id: string;
  title: string;
  subtitle: string;

  /** Needs to be defined for the Event Cell to work */
  backgroundImageURL: string;

  /** When does this cohort start showing up in the header + prompt? */
  startShowDate: Date;
  /** When does this cohort stop showing up in the header + prompt? */
  endShowDate: Date;

  /** future use */
  startDate: Date;
  /** future use */
  endDate: Date;

  /** Keyed by airtableId */
  activitiesById: Record<string, HubActivity>;
  activities?: HubActivity[];

  curriculumOverview: ScoutCampCurriculum[];
  communityResources: ScoutCampArticle[];
  speakers: ScoutCampSpeaker[];
  speakersBlurb?: string;
};

export type ScoutCampCurriculum = {
  topic: string;
  summary: string;
  articles: ScoutCampArticle[];
  airtableId: string;
};

export type ScoutCampArticle = {
  storyIdOrLink: string;
  name: string;
  shortDescription?: string;
  featureType?: string;
  featureImage?: string;
  featureBackgroundColor?: string;
  cta?: string;
  curriculumTopic?: string;
  airtableId: string;
};

export type ScoutCampSpeaker = {
  name: string;
  bio?: string;
  profileId?: string;
  website?: string;
  image?: string;
  twitter?: string;
  linkedin?: string;
  instagram?: string;
  youtube?: string;
  airtableId: string;
};

/**
 * Data in the listing file
 */
export type ScoutCampCohortInListingFile = Pick<
  ScoutCampCohort,
  | "id"
  | "title"
  | "startShowDate"
  | "endShowDate"
  | "startDate"
  | "endDate"
  | "backgroundImageURL"
> & {
  memberIds: string[];
};

const SCOUT_CAMP_DATA_VERSION = 3;
export const SCOUT_CAMP_HOSTING_BASE = `resource/data/v${SCOUT_CAMP_DATA_VERSION}`;
