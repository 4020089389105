import { DBSchema, IDBPDatabase, deleteDB, openDB } from "idb";

export const DB_NAME = "fb-network-cache-db";
const DB_VERSION = 3;
export const STORAGE_PATH = "storage_path";
export const FUNCTION_RESULT = "function_result";
export const SWR_CACHE = "swr_cache";
export const WORKER_VALUE = "worker_value";

interface NetworkCacheDB extends DBSchema {
  [STORAGE_PATH]: {
    key: string;
    value: {
      key: string;
      path: string;
      timestamp: number;
    };
  };
  [FUNCTION_RESULT]: {
    key: string[];
    value: {
      functionName: string;
      params: string;
      result: string;
      timestamp: number;
    };
  };
  [SWR_CACHE]: {
    key: string;
    value: {
      key: string;
      result: string;
      timestamp: number;
    };
  };
  [WORKER_VALUE]: {
    key: string;
    value: {
      key: string;
      result: string;
      timestamp: number;
    };
  };
}

let dbPromise: Promise<IDBPDatabase<NetworkCacheDB>> | undefined;
let cacheDb: IDBPDatabase<NetworkCacheDB> | undefined;
export async function createNetworkCacheDb(): Promise<
  IDBPDatabase<NetworkCacheDB>
> {
  if (cacheDb) {
    return cacheDb;
  }
  if (!dbPromise) {
    dbPromise = openDB<NetworkCacheDB>(DB_NAME, DB_VERSION, {
      upgrade(upgradeDb, oldVersion) {
        if (oldVersion < 1) {
          upgradeDb.createObjectStore(STORAGE_PATH, {
            keyPath: "key",
          });
          upgradeDb.createObjectStore(FUNCTION_RESULT, {
            keyPath: ["functionName", "params"],
          });
        }
        if (oldVersion < 2) {
          upgradeDb.createObjectStore(SWR_CACHE, {
            keyPath: "key",
          });
        }
        if (oldVersion < 3) {
          upgradeDb.createObjectStore(WORKER_VALUE, {
            keyPath: "key",
          });
        }
      },
    });
  }
  return dbPromise;
}

export async function destroyNetworkCacheDb(): Promise<void> {
  const db = await createNetworkCacheDb();
  db.close();
  dbPromise = undefined;
  cacheDb = undefined;
  await deleteDB(DB_NAME);
}

export async function setWorkerValue(
  key: string[],
  value: string,
): Promise<void> {
  const db = await createNetworkCacheDb();
  await db.put(WORKER_VALUE, {
    key: JSON.stringify(key),
    result: value,
    timestamp: Date.now(),
  });
}

export async function getWorkerValue(
  key: string[],
): Promise<string | undefined> {
  const db = await createNetworkCacheDb();
  const value = await db.get(WORKER_VALUE, JSON.stringify(key));
  return value?.result;
}
