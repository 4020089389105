export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): keyof T | undefined {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : undefined;
}

// https://stackoverflow.com/a/52373525
// type Not<T> = [T] extends [never] ? unknown : never;
// type Extractable<T, U> = Not<
//   U extends any ? Not<T extends U ? unknown : never> : never
// >;
/**
 * Convert a string value into an enum value.
 *
 * e.g: `asEnum(GroupPrivacy, privacyString, GroupPrivacy.unknown)`
 *
 * @returns Enum value in `myEnum` that matches `value`. If `value` doesn't match anything in `myEnum`, returns `defaultValue` (if provided) or throws an exception.
 */
export function asEnum<
  E extends Record<keyof E, string | number>,
  K extends string | number,
>(
  myEnum: E,
  value: K | undefined,
  defaultValue?: Extract<E[keyof E], K>,
): Extract<E[keyof E], K> {
  const foundEnumValue = Object.values(myEnum).find(
    (enumValue) =>
      (enumValue as string | number).toString() === value?.toString(),
  );
  if (value === undefined || !foundEnumValue) {
    if (defaultValue === undefined) {
      throw new Error(
        `Found (${value}) but expected one of (${Object.values(myEnum).join(
          ", ",
        )})`,
      );
    } else {
      return defaultValue;
    }
  }
  return foundEnumValue as Extract<E[keyof E], K>;
}
