import { StorageTimestamp } from "./base/data-type";
import { getEnumKeyByEnumValue } from "../utils/enum";
import FirestoreDataConverter from "./base/data-converter";

export enum MessageType {
  text = "text",
  image = "image",
  system = "system",
  emoji = "emoji",
  unknown = "unknown",
}

export enum SystemMessageType {
  userLeft = "user_left",
  userJoined = "user_joined",
  userRemoved = "user_removed",
  created = "created",
  createdDirectMessage = "created_direct_message",
  formattedText = "formatted_text",
  unknown = "unknown",
}

export class MessageReaction {
  createdById: string;
  reaction: string;
  icon?: string;
  updatedAt?: StorageTimestamp;

  constructor(
    createdById: string,
    reaction: string,
    icon?: string,
    updatedAt?: StorageTimestamp,
  ) {
    this.createdById = createdById;
    this.reaction = reaction;
    this.icon = icon;
    this.updatedAt = updatedAt;
  }
}

export class MessageImageInfo {
  imagePath: string;
  width: number;
  height: number;

  constructor(imagePath: string, width: number, height: number) {
    this.imagePath = imagePath;
    this.width = width;
    this.height = height;
  }
}

export class MessageSystemMessageInfo {
  systemMessageType: SystemMessageType;
  removedUserIds?: string[];
  addedUserIds?: string[];
  formattedText?: string;
  actionLinkList?: (string | null)[];

  constructor(
    systemMessageType: SystemMessageType,
    removedUserIds?: string[],
    addedUserIds?: string[],
    formattedText?: string,
    actionLinkList?: (string | null)[],
  ) {
    this.systemMessageType = systemMessageType;
    this.removedUserIds = removedUserIds;
    this.addedUserIds = addedUserIds;
    this.formattedText = formattedText;
    this.actionLinkList = actionLinkList;
  }
}

export class Message {
  id: string;
  chatId: string;
  createdAt: StorageTimestamp;
  createdById: string;
  messageType: MessageType;
  updatedAt?: StorageTimestamp;
  text?: string;
  imageInfo?: MessageImageInfo;
  systemMessageInfo?: MessageSystemMessageInfo;
  modified?: boolean;
  reaction?: Record<string, MessageReaction>;
  mention?: { [key: string]: { [key: string]: string } };
  deleted?: boolean;
  deletedAt?: StorageTimestamp;

  constructor(
    id: string,
    chatId: string,
    createdAt: StorageTimestamp,
    createdById: string,
    messageType: MessageType,
    updatedAt?: StorageTimestamp,
    text?: string,
    imageInfo?: MessageImageInfo,
    systemMessageInfo?: MessageSystemMessageInfo,
    modified?: boolean,
    reaction?: Record<string, MessageReaction>,
    mention?: { [key: string]: { [key: string]: string } },
    deleted?: boolean,
    deletedAt?: StorageTimestamp,
  ) {
    this.id = id;
    this.chatId = chatId;
    this.createdAt = createdAt;
    this.createdById = createdById;
    this.messageType = messageType;
    this.updatedAt = updatedAt;
    this.text = text;
    this.imageInfo = imageInfo;
    this.systemMessageInfo = systemMessageInfo;
    this.modified = modified;
    this.reaction = reaction;
    this.mention = mention;
    this.deleted = deleted;
    this.deletedAt = deletedAt;
  }
}

export const messageDataConverter: FirestoreDataConverter<Message> = {
  toFirestoreModel: function (message: Message) {
    let reaction: Record<string, any> = {};
    if (message.reaction) {
      reaction = {};
      Object.values(message.reaction).forEach((r) => {
        reaction[r.createdById] =
          messasgeReactionDataConverter.toFirestoreModel(r);
      });
    }
    let imageInfo: Record<string, any> | undefined = undefined;
    if (message.imageInfo) {
      imageInfo = messasgeImageInfoDataConverter.toFirestoreModel(
        message.imageInfo,
      );
    }
    let systemMessageInfo: Record<string, any> | undefined = undefined;
    if (message.systemMessageInfo) {
      systemMessageInfo =
        messageSystemMessageInfoDataConverter.toFirestoreModel(
          message.systemMessageInfo,
        );
    }

    return {
      id: message.id,
      chat_id: message.chatId,
      created_at: message.createdAt,
      created_by_id: message.createdById,
      type: message.messageType,
      updated_at: message.updatedAt,
      text: message.text,
      image_info: imageInfo,
      system_message_info: systemMessageInfo,
      modified: message.modified,
      reaction: reaction,
      mention: message.mention,
      deleted: message.deleted,
      deleted_at: message.deletedAt,
    };
  },
  fromFirestoreModel: function (data): Message {
    const reaction: Record<string, MessageReaction> = {};
    if (data.reaction) {
      Object.values(data.reaction).forEach((r: any) => {
        reaction[r.created_by_id] =
          messasgeReactionDataConverter.fromFirestoreModel(r);
      });
    }
    let imageInfo: MessageImageInfo | undefined = undefined;
    if (data.image_info) {
      imageInfo = messasgeImageInfoDataConverter.fromFirestoreModel(
        data.image_info,
      );
    }
    let systemMessageInfo: MessageSystemMessageInfo | undefined = undefined;
    if (data.system_message_info) {
      systemMessageInfo =
        messageSystemMessageInfoDataConverter.fromFirestoreModel(
          data.system_message_info,
        );
    }

    return new Message(
      data.id,
      data.chat_id,
      data.created_at,
      data.created_by_id,
      MessageType[
        getEnumKeyByEnumValue(MessageType, data.type) ?? MessageType.unknown
      ],
      data.updated_at,
      data.text,
      imageInfo,
      systemMessageInfo,
      data.modified,
      reaction,
      data.mention,
      data.deleted,
      data.deleted_at,
    );
  },
};

const messasgeReactionDataConverter: FirestoreDataConverter<MessageReaction> = {
  toFirestoreModel: function (reaction: MessageReaction) {
    return {
      created_by_id: reaction.createdById,
      reaction: reaction.reaction,
      icon: reaction.icon,
      updated_at: reaction.updatedAt,
    };
  },
  fromFirestoreModel: function (data): MessageReaction {
    return new MessageReaction(
      data.created_by_id,
      data.reaction,
      data.icon,
      data.updated_at,
    );
  },
};

const messasgeImageInfoDataConverter: FirestoreDataConverter<MessageImageInfo> =
  {
    toFirestoreModel: function (info: MessageImageInfo) {
      return {
        image_path: info.imagePath,
        width: info.width,
        height: info.height,
      };
    },
    fromFirestoreModel: function (data): MessageImageInfo {
      return new MessageImageInfo(data.image_path, data.width, data.height);
    },
  };

const messageSystemMessageInfoDataConverter: FirestoreDataConverter<MessageSystemMessageInfo> =
  {
    toFirestoreModel: function (info: MessageSystemMessageInfo) {
      return {
        system_message_type: info.systemMessageType,
        removed_user_ids: info.removedUserIds,
        added_user_ids: info.addedUserIds,
        formatted_text: info.formattedText,
        action_link_list: info.actionLinkList,
      };
    },
    fromFirestoreModel: function (data): MessageSystemMessageInfo {
      return new MessageSystemMessageInfo(
        SystemMessageType[
          getEnumKeyByEnumValue(SystemMessageType, data.system_message_type) ??
            SystemMessageType.unknown
        ],
        data.removed_user_ids,
        data.added_user_ids,
        data.formatted_text,
        data.action_link_list,
      );
    },
  };
