/** URL parameter used to track the source of a page view.
 *
 * Keep in sync with iOS
 */
export const SOURCE_TRACKING_PARAM = "_s";

/** Attributes we can track
 *
 * Keep in sync with iOS
 */
export enum SourceEventParam {
  emailDigestId = "email_digest_id",
  postId = "post_id",
  createdById = "created_by_id",
  profileId = "profile_id",
  libraryEntryId = "library_entry_id",
  eventId = "event_id",
  customId = "custom_id",
  primaryCTA = "primary_cta",
  channel = "channel",
}

/**
 */
export function maybeAddSourceTracking({
  urlAsString,
  base,
  slug,
  paramForTracking = SOURCE_TRACKING_PARAM,
  sign,
}: {
  /** Relative or full url */
  urlAsString: string | undefined;

  /** Base for URL.
   * If `urlAsString` is relative, will use `base` as the base URL and the tracking will be added.
   * If `urlAsString` is absolute and `base` is the prefix, will go directly to `urlAsString` and tracking will be added.
   * If `urlAsString` is absolute and `base` is not the prefix, will direct throught `${base}/r?d=${url}` and tracking will be added.
   * */
  base: string;

  slug?: { [key in SourceEventParam]?: string };
  paramForTracking?: string;

  /** Required for tracking clicks to external sites.
   *
   * If the destination is not our own domain (i.e. `base` is not a prefix of `urlAsString`), we will redirect through our own site to track the click. This requires a HMAC to be generated for the destination URL, so we know we generated the link and that it is safe.
   */
  sign?: (url: string) => string;
}) {
  if (!slug || !urlAsString) {
    return urlAsString;
  }
  let url: URL | undefined = undefined;
  try {
    url = new URL(urlAsString, base);
  } catch (e) {
    console.error("Error parsing URL for tracking", urlAsString, e);
    // Not a url, cannot track
    return urlAsString;
  }

  if (!url.toString().startsWith(base)) {
    // Different base - it was probably a link to another domain. Don't track it.
    // return urlAsString;

    try {
      // Redirect through /r/ so apple-app-site-association can match and ignore this link. Otherwise, we'd open the app and then show the in-app browser.
      url = new URL("/r/", base);
    } catch (e) {
      console.error("Error parsing /r with `base` for tracking", base, e);
      // base not a url prefix. cannot track
      return urlAsString;
    }
    const signature = sign?.(urlAsString);
    if (!signature) {
      console.error(
        "Error creating signature for tracking. Not adding tracking, and returning untracked string",
        urlAsString,
      );
      return urlAsString;
    }
    url.searchParams.set("sig", signature);
    url.searchParams.set("d", urlAsString);
  }
  url.searchParams.set(paramForTracking, btoa(JSON.stringify(slug)));
  return url.toString();
}

/**
 * Returns whatever was encoded in the source tracking parameter, if any.
 * See `maybeAddSourceTracking` for where we add the slug.
 * Can throw error if there is _s but not a valid encoding.
 *
 * Keep in sync with iOS.
 */
export function unpackTrackingSlug(
  urlSearchParams: URLSearchParams,
  paramForTracking: string = SOURCE_TRACKING_PARAM,
): { [key: string]: unknown } | undefined {
  const source = urlSearchParams.get(paramForTracking);
  if (!source) {
    return undefined;
  }
  return JSON.parse(atob(source));
}
